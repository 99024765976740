import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from 'components/CncUI/Typography';
import PageDots from 'components/CncUI/PageDots';

const PageDotsPage = () => {
  const [page, setPage] = useState(1);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">PageDots</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <PageDots
              limit={10}
              total={45}
              position={page}
              onChange={value => setPage(value)}
              style={{ marginLeft: 20 }}
            />
          </Grid>
          <Grid item>
            <Typography>
              Page selected: <strong>{page + 1}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageDotsPage;

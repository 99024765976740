import { gql } from '@apollo/client';

export default gql`
  query getAffidavitDocumentImages($input: String) {
    getAffidavitDocumentImages(input: $input) {
      page
      width
      height
      url
    }
  }
`;

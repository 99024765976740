import { compress, decompress } from 'compress-json';

const replacer = (key, value) => (key === '__typename' ? undefined : value);
const pack = obj => JSON.parse(JSON.stringify(obj, replacer));

const serialize = obj => JSON.stringify(compress(pack(obj)));
const deserialize = text => (text ? decompress(JSON.parse(text)) : null);

const getItem = key => window?.localStorage?.getItem(key) || '';
const setItem = (key, value) => window?.localStorage?.setItem(key, value);
const removeItem = key => window?.localStorage?.removeItem(key);

const FastCache = name => {
  const version = 2;
  const key = `__mycnc__${version}_${name}`;

  const get = () => {
    try {
      return deserialize(getItem(key));
    } catch (err) {
      console.error('Error in FastCache.get:', err);
      removeItem(key);
    }
  };

  const set = value => {
    try {
      setItem(key, serialize(value));
    } catch (err) {
      console.error('Error in FastCache.set:', err);
    }
    return value;
  };

  const remove = () => removeItem(key);

  return { get, set, remove };
};

export default FastCache;

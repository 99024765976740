import { gql } from '@apollo/client';

export default gql`
  mutation fakeVerifyIdentity($input: VerifyIdentityInput!, $verify: Boolean) {
    fakeVerifyIdentity(input: $input, verify: $verify) {
      match
      attempts
    }
  }
`;

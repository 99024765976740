import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from 'components/CncUI/Typography';
import Checkbox from 'components/CncUI/Checkbox';
import ContentBox from 'components/CncUI/ContentBox';

const Containers = () => {
  const [state, setState] = useState({
    dense: false,
    shadow: true,
    transparent: false,
  });
  const modify = data => setState(old => ({ ...old, ...data }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Content Box</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={state.dense}
              onChange={e => modify({ dense: e.target.checked })}
            >
              Dense
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={state.shadow}
              onChange={e => modify({ shadow: e.target.checked })}
            >
              Shadow
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={state.transparent}
              onChange={e => modify({ transparent: e.target.checked })}
            >
              Transparent
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <ContentBox
          dense={state.dense}
          shadow={state.shadow}
          transparent={state.transparent}
        >
          <Typography variant="h4" style={{ marginTop: 0 }}>
            Title
          </Typography>
          <Typography variant="body2" style={{ marginBottom: 0 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat
          </Typography>
        </ContentBox>
      </Grid>
      <Grid item xs={12} md={4}>
        <ContentBox>
          <Typography variant="h4" style={{ marginTop: 0 }}>
            Title
          </Typography>
          <Typography variant="body2" style={{ marginBottom: 0 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat
          </Typography>
        </ContentBox>
      </Grid>
      <Grid item xs={12} md={4}>
        <ContentBox>
          <Typography variant="h4" style={{ marginTop: 0 }}>
            Title
          </Typography>
          <Typography variant="body2" style={{ marginBottom: 0 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat
          </Typography>
        </ContentBox>
      </Grid>
    </Grid>
  );
};

export default Containers;

import { gql } from '@apollo/client';

export default gql`
  mutation testUpload($input: FileUploadInput!) {
    testUpload(input: $input) {
      id
      output
    }
  }
`;

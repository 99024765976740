import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiCardActions from '@mui/material/CardActions';

const useStyles = makeStyles(
  () => ({
    root: {
      backgroundColor: 'transparent',
      justifyContent: 'flex-end',
      padding: 16,
    },
  }),
  { name: 'CncUI-CardActions' },
);

const CardActions = props => {
  const classes = useStyles();
  const { children, className, ...other } = props;
  const options = {
    className: clsx(classes.root, className),
  };
  return (
    <MuiCardActions {...options} {...other}>
      {children}
    </MuiCardActions>
  );
};

export default CardActions;

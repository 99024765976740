import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiTable from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import ContentBox from 'components/CncUI/ContentBox';
import Row from './Row';
import Cell from './Cell';
import HeadCell from './HeadCell';
import Pagination from './Pagination';

const useStyles = makeStyles(
  ({ palette }) => ({
    container: {
      padding: 0,
      boxShadow: 'none',
    },
    root: {
      '& .MuiTableRow-root .MuiTableCell-root': {
        height: 47,
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiTableRow-root .MuiTableCell-head': {
        height: 48,
      },
      '& .MuiTableRow-head': {
        background: palette.cncUI.default['+8'],
        '& .MuiTableSortLabel-root:hover': {
          color: palette.cncUI.default.primary,
        },
        '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
          color: palette.cncUI.default.primary,
        },
        '& .MuiTableCell-head': {
          color: palette.cncUI.default.primary,
          borderBottomColor: palette.cncUI.primary.gray['+8'],
          '& .MuiTableSortLabel-icon': {
            color: `${palette.cncUI.default.primary} !important`,
          },
        },
      },
      '& .MuiTableCell-root': {
        color: palette.cncUI.primary.gray['+0'],
        borderBottomColor: palette.cncUI.primary.gray['+8'],
      },
      '& .MuiTableRow-root.MuiTableRow-hover:hover': {
        background: palette.cncUI.default['+9'],
      },
    },
    zebra: {
      '& .MuiTableRow-root:nth-child(even)': {
        background: palette.cncUI.default.table,
      },
    },
    dark: {
      '& .MuiTableRow-root': {
        background: palette.cncUI.default.table,
      },
      '& .MuiTableRow-head': {
        background: palette.cncUI.default['+8'],
      },
    },
    hover: {
      '& .MuiTableBody-root .MuiTableRow-root:hover': {
        background: palette.cncUI.primary.gray['+9'],
      },
    },
    light: {
      '& .MuiTableRow-head': {
        background: palette.cncUI.default['+9'],
      },
    },
    lighter: {
      '& .MuiTableRow-head': {
        background: palette.cncUI.misc.tableHeader,
      },
    },
    short: {
      '& .MuiTableRow-root .MuiTableCell-root': {
        height: 31,
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiTableRow-root .MuiTableCell-head': {
        height: 32,
      },
    },
    compact: {
      '& .MuiTableRow-root .MuiTableCell-root': {
        height: 23,
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiTableRow-root .MuiTableCell-head': {
        height: 24,
      },
    },
    tall: {
      '& .MuiTableRow-root .MuiTableCell-root': {
        height: 63,
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiTableRow-root .MuiTableCell-head': {
        height: 64,
      },
    },
  }),
  { name: 'CncUI-Table' },
);

const Table = props => {
  const classes = useStyles();
  const {
    children,
    className,
    dark = false,
    zebra = false,
    hover = false,
    light = false,
    lighter = false,
    size = 'normal',
    ...other
  } = props;
  const options = {
    className: clsx(
      'cncui-table',
      classes.root,
      dark && classes.dark,
      zebra && classes.zebra,
      hover && classes.hover,
      light && classes.light,
      lighter && classes.lighter,
      classes[size],
      className,
    ),
  };
  return (
    <TableContainer className={classes.container} component={ContentBox}>
      <MuiTable {...options} {...other}>
        {children}
      </MuiTable>
    </TableContainer>
  );
};

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = Row;
Table.Cell = Cell;
Table.HeadCell = HeadCell;
Table.Pagination = Pagination;

export default Table;

/* eslint global-require: 0 */
if (process.env.NODE_ENV === 'production') {
  module.exports = require('./serverLink');
} else {
  if (window.Cypress) {
    module.exports = require('./testLink');
  } else {
    module.exports = require('./serverLink');
  }
}

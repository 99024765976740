import { gql } from '@apollo/client';

export default gql`
  query adminGetActiveUser($loginId: String, $email: String) {
    getActiveUser(loginId: $loginId, email: $email) {
      firstName
      lastName
      title
      oktaStatus
      loginId
      email
      isCrew
      isClient
      clientSetup
      groups {
        id
        name
        alternativeName
        description
        alternativeDesc
      }
    }
  }
`;

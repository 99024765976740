import { gql } from '@apollo/client';

export default gql`
  query getUserBanks {
    getCurrentUser {
      banks {
        id
        name
        logo
        token
        status
        currency
      }
    }
  }
`;

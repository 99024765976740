import { gql } from '@apollo/client';

export default gql`
  mutation overrideProjectData(
    $projectId: String!
    $data: String!
    $reason: String!
  ) {
    overrideProjectData(projectId: $projectId, data: $data, reason: $reason)
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getInvitationSender($projectId: String!) {
    getInvitationSender(projectId: $projectId) {
      firstName
      lastName
      title
      oktaStatus
      loginId
      email
      clientSetup
    }
  }
`;

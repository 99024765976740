import { gql } from '@apollo/client';

export default gql`
  query getW4States($country: String) {
    getW4States(country: $country) {
      id
      name
    }
  }
`;

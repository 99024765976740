export default {
  fontFamily: 'Inter,Roboto,"Helvetica Neue",Arial,sans-serif',
  pageHeader: {
    fontSize: '46px',
    fontWeight: 'regular',
    fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    color: '#616161',
  },
  tableHeader: {
    fontSize: '24px',
    fontWeight: 'regular',
    fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    color: '#616161',
  },
  bodyText: {
    fontSize: '14px',
    fontWeight: 'regular',
    fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    color: '#616161',
  },
  sortableTable: {
    fontWeight: 'regular',
    th: {
      fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
      fontWeight: 'regular',
      fontColor: '#616161',
    },
  },
  appName: {
    fontSize: '1.1125rem',
    fontWeight: 300,
    fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    lineHeight: '1.5667em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  body: {
    fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
  },
};

import { gql } from '@apollo/client';

export default gql`
  query getEmployeeRegistrations(
    $lastFourSsn: String
    $email: String
    $ssn: String
  ) {
    getEmployeeRegistrations(
      lastFourSsn: $lastFourSsn
      email: $email
      ssn: $ssn
    ) {
      time
      path
      category
      reason
      data
    }
  }
`;

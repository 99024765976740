import Grid from '@mui/material/Grid';
import Typography from 'components/CncUI/Typography';

const TypographyShowcase = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <h2 className="header">Typography</h2>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="header">Headlines, body and captions</h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h1">Headline 1</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">Headline 2</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">Headline 3</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Headline 4</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Headline 5</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Headline 6</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" size="short">
                BODY SHORT 01 - This is for short paragraphs with no more than
                four lines and is commonly used in components.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                BODY LONG 01 - This is commonly used in both the expressive and
                the productive type theme layouts for long paragraphs with more
                than four lines. It is a good size for comfortable, long-form
                reading. Use this for longer body copy in components such as
                accordion or structured list. Always left-align this type; never
                center it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" size="short">
                BODY SHORT 02 - This is for short paragraphs with no more than
                four lines and is commonly used in components.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                BODY LONG 02 - This is commonly used in both the expressive and
                the productive type theme layouts for long paragraphs with more
                than four lines. It is a good size for comfortable, long-form
                reading. Use this for longer body copy in components such as
                accordion or structured list. Always left-align this type; never
                center it.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="caption">
                CAPTION - This is for field labels in components, error
                messages; explanatory helper text that appears below a field
                title within a component.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="microcaption">
                MICROCAPTION - This is used for non-important statuses and
                captions that don't require complete sentences. This should be
                used sparingly since it is difficult for users to read due to
                its small size.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <h3 className="header">Italic, medium and bold</h3>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" size="short" italic>
                BODY SHORT 01 - This is for short paragraphs with no more than
                four lines and is commonly used in components.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" italic>
                BODY LONG 01 - This is commonly used in both the expressive and
                the productive type theme layouts for long paragraphs with more
                than four lines. It is a good size for comfortable, long-form
                reading. Use this for longer body copy in components such as
                accordion or structured list. Always left-align this type; never
                center it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" size="short" italic>
                BODY SHORT 02 - This is for short paragraphs with no more than
                four lines and is commonly used in components.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" italic>
                BODY LONG 02 - This is commonly used in both the expressive and
                the productive type theme layouts for long paragraphs with more
                than four lines. It is a good size for comfortable, long-form
                reading. Use this for longer body copy in components such as
                accordion or structured list. Always left-align this type; never
                center it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" italic>
                CAPTION - This is for field labels in components, error
                messages; explanatory helper text that appears below a field
                title within a component.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="microcaption" italic>
                MICROCAPTION - This is used for non-important statuses and
                captions that don't require complete sentences. This should be
                used sparingly since it is difficult for users to read due to
                its small size.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" size="short" medium>
                BODY SHORT 01 - This is for short paragraphs with no more than
                four lines and is commonly used in components.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" medium>
                BODY LONG 01 - This is commonly used in both the expressive and
                the productive type theme layouts for long paragraphs with more
                than four lines. It is a good size for comfortable, long-form
                reading. Use this for longer body copy in components such as
                accordion or structured list. Always left-align this type; never
                center it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" size="short" medium>
                BODY SHORT 02 - This is for short paragraphs with no more than
                four lines and is commonly used in components.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" medium>
                BODY LONG 02 - This is commonly used in both the expressive and
                the productive type theme layouts for long paragraphs with more
                than four lines. It is a good size for comfortable, long-form
                reading. Use this for longer body copy in components such as
                accordion or structured list. Always left-align this type; never
                center it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" medium>
                CAPTION - This is for field labels in components, error
                messages; explanatory helper text that appears below a field
                title within a component.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="microcaption" medium>
                MICROCAPTION - This is used for non-important statuses and
                captions that don't require complete sentences. This should be
                used sparingly since it is difficult for users to read due to
                its small size.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" size="short" bold>
                BODY SHORT 01 - This is for short paragraphs with no more than
                four lines and is commonly used in components.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" bold>
                BODY LONG 01 - This is commonly used in both the expressive and
                the productive type theme layouts for long paragraphs with more
                than four lines. It is a good size for comfortable, long-form
                reading. Use this for longer body copy in components such as
                accordion or structured list. Always left-align this type; never
                center it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" size="short" bold>
                BODY SHORT 02 - This is for short paragraphs with no more than
                four lines and is commonly used in components.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" bold>
                BODY LONG 02 - This is commonly used in both the expressive and
                the productive type theme layouts for long paragraphs with more
                than four lines. It is a good size for comfortable, long-form
                reading. Use this for longer body copy in components such as
                accordion or structured list. Always left-align this type; never
                center it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" bold>
                CAPTION - This is for field labels in components, error
                messages; explanatory helper text that appears below a field
                title within a component.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="microcaption" bold>
                MICROCAPTION - This is used for non-important statuses and
                captions that don't require complete sentences. This should be
                used sparingly since it is difficult for users to read due to
                its small size.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default TypographyShowcase;

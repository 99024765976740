import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiPaper from '@mui/material/Paper';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      backgroundColor: palette.cncUI.default['+10'],
      padding: 16,
      borderRadius: 2,
      boxShadow: 'none',
    },
    dense: {
      padding: 0,
    },
    shadow: {
      boxShadow: palette.cncUI.shadow.container.default,
    },
    transparent: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  }),
  { name: 'CncUI-ContentBox' },
);

const ContentBox = props => {
  const classes = useStyles();
  const {
    children,
    className,
    dense = false,
    shadow = true,
    transparent = false,
    ...other
  } = props;
  const options = {
    className: clsx(
      classes.root,
      dense && classes.dense,
      shadow && classes.shadow,
      transparent && classes.transparent,
      className,
    ),
  };

  return (
    <MuiPaper {...options} {...other}>
      {children}
    </MuiPaper>
  );
};

export default ContentBox;

import { gql } from '@apollo/client';

export default gql`
  query getPlaidToken(
    $userId: String!
    $webhook: String!
    $country: String
    $filter: Boolean
  ) {
    getPlaidToken(
      userId: $userId
      webhook: $webhook
      country: $country
      filter: $filter
    )
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation setNotificationAsRead(
    $id: String!
    $expireAt: String!
    $settings: String!
  ) {
    updateNotification(
      id: $id
      expireAt: $expireAt
      settings: $settings
    )
  }
`;

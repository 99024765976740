import { useState, useEffect } from 'react';

/**
 *
 * @param {ref} ref React Ref to the element to listen for hover events
 * @returns {Boolean} Indicator if the element is currently hovered
 */
const useHover = ref => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (!ref.current) return;
    ref.current.addEventListener('mouseenter', handleMouseEnter);
    ref.current.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      if (!ref.current) return;
      ref.current.removeEventListener('mouseenter', handleMouseEnter);
      ref.current.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return isHovered;
};

export default useHover;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      display: 'inline-flex',
      position: 'relative',
    },
    holders: {
      display: 'inline-flex',
    },
    holder: {
      borderRadius: '50%',
      background: palette.cncUI.default['+10'],
      border: `1px solid ${palette.cncUI.default['+8']}`,
      cursor: 'pointer',
      '&:last-child': {
        marginRight: '0 !important',
      },
    },
    dot: {
      borderRadius: '50%',
      background: palette.cncUI.default.primary,
      border: `1px solid ${palette.cncUI.default.primary}`,
      position: 'absolute',
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
  }),
  { name: 'CncUI-PageDots' },
);

const PageDots = props => {
  const classes = useStyles();
  const { className, style } = props;
  const { separation = 16, size = 16, speed = 200 } = props;
  const { position = 0, limit = 10, total } = props;
  const { onChange = () => {} } = props;

  const options = {
    style,
    className: clsx('cncui-pagedots', classes.root, className),
  };

  if (total === null || total === undefined) {
    console.warn('PageDots missing total property');
    return null;
  }

  if (!total) {
    return null;
  }

  const holders = [];
  const length = Math.floor(total / limit) + (total % limit > 0);
  for (let i = 0; i < length; i++) {
    holders.push(
      <div
        key={i}
        className={clsx('cncui-pagedots-holder', classes.holder)}
        onClick={() => onChange(i)}
        style={{ marginRight: separation, width: size, height: size }}
      />,
    );
  }

  const dotPosition = position * (size * 2 + 2);

  return (
    <div {...options}>
      <div className={clsx('cncui-pagedots-holders', classes.holders)}>
        {holders}
      </div>
      <div
        className={clsx('cncui-pagedots-dot', classes.dot)}
        style={{
          left: dotPosition,
          width: size,
          height: size,
          transition: `left ${speed}ms ease-in`,
        }}
      />
    </div>
  );
};

export default PageDots;

import { useCallback, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { Queries } from 'apollo';
import useAuthContext from './useAuthContext';

const useFileDownload = (query, props = {}) => {
  const {
    scopes = 'openid',
    request = () => ({}),
    response = res => res,
    ...other
  } = props;
  const file = useRef('');
  const context = useAuthContext(scopes);
  const client = useApolloClient();
  const getQuery = useCallback(
    ({ id = null, input = '{}', part = 0 }) =>
      client.query({
        fetchPolicy: 'network-only',
        ...other,
        query: Queries[query],
        variables: { input: { id, input, part } },
        context,
      }),
    [client, context],
  );

  const fetchQuery = async (id, input, part) => {
    const response = await getQuery({ id, input, part });
    if (!response.data[query]) {
      throw new Error('Download File Query failed');
    }
    const data = response.data[query];
    file.current += data.body;
    if (!data.done) {
      return fetchQuery(data.id, input, data.part + 1);
    }
    return { data: { [query]: file.current } };
  };

  return async (...args) => {
    file.current = '';
    const input = JSON.stringify(request(...args));
    const res = await fetchQuery(null, input, 0);
    return response(res);
  };
};

export default useFileDownload;

import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from 'components/CncUI/Checkbox';
import Progress from 'components/CncUI/Progress';

const ProgressPage = () => {
  const [progress, setProgress] = useState(0);
  const [light, setLight] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Progress</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Checkbox
              checked={light}
              onChange={e => setLight(e.target.checked)}
            >
              Light progress bar
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Progress light={light} value={progress} />
      </Grid>
    </Grid>
  );
};

export default ProgressPage;

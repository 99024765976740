import { makeStyles } from '@mui/styles';
import { Grid, Badge } from '@mui/material';
import { format as dateFormat } from 'date-fns';
import Button from 'components/CncUI/Button';
import Typography from 'components/CncUI/Typography';
import Icon from './IconSelector';
import PRODUCTS from './Products';

const useStyles = makeStyles(({ palette, spacing }) => ({
  notificationBox: {
    border: `solid 1px ${palette.cncUI.primary.gray['+8']}`,
    borderRadius: 2,
    margin: `4px ${spacing(2)}`,
    '& .MuiBadge-dot': {
      border: '1px white solid',
    },
  },
  icon: {
    color: palette.cncUI.primary.gray.primary,
    marginLeft: 3,
    marginRight: 3,
  },
  appName: {
    color: palette.cncUI.primary.gray['+6'],
    marginBottom: 0,
    marginTop: 0,
  },
  darker: {
    color: palette.cncUI.primary.gray['+2'],
  },
  title: {
    color: palette.cncUI.primary.gray.primary,
    marginBottom: 0,
  },
  content: {
    marginBottom: spacing(2),
    paddingRight: spacing(1),
    color: palette.cncUI.primary.gray.primary,
  },
  button: {
    marginBottom: spacing(2),
  },
  button2: {
    marginBottom: spacing(2),
    marginLeft: spacing(1),
  },
}));

const Notification = ({ notification }) => {
  const classes = useStyles();
  const startAt = new Date(parseInt(notification.startAt, 10));

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      className={classes.notificationBox}
    >
      <Grid item style={{ width: '11%' }}>
        <Typography variant="caption" align="center">
          <Badge
            variant="dot"
            color="error"
            overlap="circular"
            invisible={!notification.settings?.unread || false}
            data-test-id="notification-badge"
          >
            <Icon
              type={notification.settings?.icon || 'Warning'}
              className={classes.icon}
            />
          </Badge>
        </Typography>
      </Grid>
      <Grid item style={{ width: '89%' }} container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            {notification.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.appName}>
            <b className={classes.darker}>
              {PRODUCTS[notification.applicationId]}
            </b>{' '}
            | {dateFormat(startAt, 'MMM dd, yyyy hh:mm aa')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.content}>
            {notification.content}
          </Typography>
        </Grid>
        {notification.settings?.urlAction1 && (
          <Grid item xs={12} container>
            <Button
              short
              href={notification.settings.urlAction1.url}
              className={classes.button}
            >
              {notification.settings.urlAction1.label}
            </Button>

            {notification.settings?.urlAction2 && (
              <Button
                short
                href={notification.settings.urlAction2.url}
                className={classes.button2}
                secondary
              >
                {notification.settings.urlAction2.label}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Notification;

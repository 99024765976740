import Okta from 'providers/Okta';
import Logger from 'utilities/logger';

export default () => {
  const logger = Logger('Logout utility');
  const auth = Okta();
  auth
    .logout()
    .then(() => logger.info('Logged out'))
    .catch(err => logger.error(err))
    .finally(() => location.reload(true));
};

import { gql } from '@apollo/client';

export default gql`
  query getDraftProfiles($country: String, $type: Int) {
    getProfileMappingOptions {
      contactType {
        label
        value
      }
      ethnicity {
        label
        value
      }
      gender {
        label
        value
      }
      relationship {
        label
        value
      }
      bankAccountTypes {
        label
        value
      }
    }
    getDraftProfiles(country: $country, type: $type) {
      dataSource
      screenName
      basicInfo {
        idType
        socialSecurityMask
        firstName
        middleInitial
        lastName
        birthDate
        gender
        isGenderNonBinary
        ethnicity
        taxId
        isComplexDDSetup
        dbCode
        countryFlag
        notificationOptIn
      }
      addresses {
        typeofContact
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
      phoneNumbers {
        typeofContact
        cell
        extension
        countryCode
      }
      emailAddresses {
        typeofContact
        emailAddress
      }
      alternativeContacts {
        addresses {
          typeofContact
          addressLine1
          addressLine2
          city
          state
          zipCode
          country
        }
        typeofContact
        basicInfo {
          name
        }
        phoneNumbers {
          typeofContact
          cell
          extension
          countryCode
        }
        emailAddresses {
          typeofContact
          emailAddress
        }
        relationship
      }
      directDeposits {
        id
        currencyType
        routingNumber
        bankAccountNumber
        isDirectDeposit
        bankName
        flatAmount
        percentRate
        splitPercentage
        bankAccountType
        isPaperLess
        isDeliveryRequested
        ba
        rn
        forCorps
        sequence
      }
    }
  }
`;

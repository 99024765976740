import { gql } from '@apollo/client';

export default gql`
  query checkControlProcess($id: String!) {
    checkControlProcess(id: $id) {
      id
      name
      status
      data
    }
  }
`;

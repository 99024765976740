const graphQLURLs = {
  local: 'https://qa.common-dashboard.dev.aws.castandcrew.com',
  qa: 'https://qa.common-dashboard.dev.aws.castandcrew.com',
  uat: 'https://stg.common-dashboard.dev.aws.castandcrew.com',
  aut: 'https://stg.common-dashboard.dev.aws.castandcrew.com',
  demo: 'https://demo.unified-dashboard.castandcrew.com',
  extuat: 'https://extuat.unified-dashboard.castandcrew.com',
  prod: 'https://unified-dashboard.castandcrew.com',
};

const dashboardApiUrl = graphQLURLs[process.env.REACT_APP_ENVIRONMENT || 'qa'];

export default dashboardApiUrl;

import { gql } from '@apollo/client';

export default gql`
  query getEnrolledFactors($loginId: String!) {
    getEnrolledFactors(loginId: $loginId) {
      id
      type
      status
      provider
      vendorName
      embedded
    }
  }
`;

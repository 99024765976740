import { createSvgIcon } from '@mui/material/utils';

const CaretsIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3L7 8H17L12 3ZM12 21L7 16H17L12 21Z"
      fill="#525252"
    />
  </>,
  'Carets',
);

export default CaretsIcon;

import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';

export default value => (
  <Grid
    container
    spacing={1}
    justifyContent="space-between"
    alignItems="center"
    style={{ marginRight: 8 }}
  >
    <Grid item style={{ display: 'flex' }}>
      {value}
    </Grid>
    <Grid item style={{ display: 'flex', textAlign: 'right' }}>
      <ErrorIcon className={`cncui-dropdown-error-icon`} />
    </Grid>
  </Grid>
);

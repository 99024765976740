import { gql } from '@apollo/client';

export default gql`
  mutation profileCompleted(
    $type: String
    $dbCode: String
    $isOfferLoanout: Boolean
  ) {
    profileCompleted(
      type: $type
      dbCode: $dbCode
      isOfferLoanout: $isOfferLoanout
    )
  }
`;

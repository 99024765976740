import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionTitle from './AccordionTitle';
import AccordionContent from './AccordionContent';

const useStyles = makeStyles(
  ({ palette }) => ({
    '@global': {
      '.MuiAccordion-rounded:last-child': {
        borderRadius: '0 0 2px 2px',
      },
      '.MuiAccordion-rounded:first-child': {
        borderRadius: '2px 2px 0 0',
      },
    },
    root: {
      boxShadow: palette.cncUI.shadow.interactive.default,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    short: {
      '& .cncui-accordion-title': {
        minHeight: 40,
        '&.Mui-expanded': {
          minHeight: 40,
        },
        '& .cncui-accordion-title-icon': {
          marginRight: 8,
          '& .MuiSvgIcon-root': {
            marginBottom: -3,
          },
        },
        '& .cncui-typography': {
          fontSize: 14,
          lineHeight: '18px',
          letterSpacing: '0.16px',
        },
        '& .MuiIconButton-edgeEnd': {
          padding: 8,
        },
      },
    },
  }),
  { name: 'CncUI-Accordion' },
);

const Accordion = props => {
  const classes = useStyles();
  const { children, className, short = false, ...other } = props;
  const options = {
    className: clsx(
      'cncui-accordion',
      classes.root,
      short && classes.short,
      className,
    ),
  };
  return (
    <MuiAccordion {...options} {...other}>
      {children}
    </MuiAccordion>
  );
};

Accordion.Title = AccordionTitle;
Accordion.Content = AccordionContent;

export default Accordion;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      color: palette.cncUI.primary.gray['-5'],
      padding: '16px 16px 8px',
      fontWeight: 500,
      '& h2': {
        fontSize: 20,
        lineHeight: '26px',
      },
      '& svg': {
        marginBottom: -5,
      },
    },
    withClose: {
      paddingRight: 44,
    },
    close: {
      position: 'absolute',
      padding: 8,
      color: palette.cncUI.primary.gray['-5'],
      right: 8,
      top: 8,
      '& svg': {
        marginBottom: 0,
        color: `${palette.cncUI.primary.gray['-5']} !important`,
      },
    },
    warning: {
      '& svg': {
        color: palette.cncUI.alert.yellow.primary,
      },
    },
    success: {
      '& svg': {
        color: palette.cncUI.alert.green.primary,
      },
    },
    error: {
      '& svg': {
        color: palette.cncUI.alert.red.primary,
      },
    },
  }),
  { name: 'CncUI-ModalTitle' },
);

const ModalTitle = props => {
  const classes = useStyles();
  const {
    children,
    onClose,
    className,
    warning,
    error,
    success,
    ...other
  } = props;

  const options = {
    className: clsx(
      'cncui-modal-title',
      classes.root,
      error && classes.error,
      warning && classes.warning,
      success && classes.success,
      onClose && classes.withClose,
      className,
    ),
  };

  return (
    <DialogTitle {...options} {...other}>
      {children}
      {onClose ? (
        <IconButton
          disableRipple
          disableFocusRipple
          aria-label="close"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default ModalTitle;

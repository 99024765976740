import { gql } from '@apollo/client';

export default gql`
  query getProductionCompany($productionCompany: Int!, $dbCode: String!) {
    getProductionCompany(
      productionCompany: $productionCompany
      dbCode: $dbCode
    ) {
      productionCompanyName
      addressLine1
      addressLine2
      city
      state
      zipCode
      phoneNumber
      contactEmail
      federalTaxId
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getPayrollOptionItem($type: String, $params: String) {
    getPayrollOptionItem(type: $type, params: $params) {
      value
      description
    }
  }
`;

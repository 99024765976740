import { useState, useEffect } from 'react';
import MinorsContext from './Context';
import useAuthentication from 'hooks/useAuthentication';
import useQuery from 'hooks/useQuery';
import Loader from 'components/Loader';
import Logger from 'utilities/logger';
import FastCache from 'utilities/fastCache';

const Provider = props => {
  const { children } = props;
  const { login, user } = useAuthentication();
  const cache = FastCache(`Minors_${user?.uid}`);
  const cached = cache.get();
  const [loading, setLoading] = useState(login && !cached);
  const [minors, setMinors] = useState(cached || []);
  const [minor, setMinor] = useState(null);

  const getMinors = useQuery('getMyMinors', {
    fetchPolicy: 'network-only',
    response: res => res.data.getCurrentUser.minors || [],
  });

  const logger = Logger('minors provider');

  const refresh = () => {
    return getMinors().then(result => {
      const mapped = result.map(minor => ({
        ...minor,
        data: JSON.parse(minor.data),
      }));
      setMinors(mapped);
      cache.set(mapped);
      logger.debug(result);
    });
  };

  useEffect(() => {
    logger.debug('Cached Data', { cached });
    if (login) {
      refresh()
        .then(() => setLoading(false))
        .catch(err => {
          logger.error(err);
          setLoading(false);
        });
    }
  }, [login]);

  const hasMinors = Array.isArray(minors) && minors.length;
  const value = { loading, minor, minors, hasMinors, refresh, setMinor };

  if (loading) {
    return <Loader fullScreen withLogo />;
  }

  return (
    <MinorsContext.Provider value={value}>{children}</MinorsContext.Provider>
  );
};

export default Provider;

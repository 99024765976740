import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Radio from 'components/CncUI/Radio';
import Stepper from 'components/CncUI/Stepper';
import Step from 'components/CncUI/Stepper/Step';

const useStyles = makeStyles(({ palette }) => ({
  dark: {
    background: palette.cncUI.default.primary,
  },
}));

const Steppers = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState('1');
  const steps = ['Completed Step', 'Current Step', 'Incomplete Step'];
  const random = () => Math.random() * 9999 + 1;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Steppers</h2>
      </Grid>
      <Grid item xs={12} align="center">
        <Radio.Group
          row
          label="Active step value"
          labelAlign="center"
          labelProps={{ variant: 'body2', italic: true }}
          name="activeStep"
          value={activeStep}
          onChange={e => setActiveStep(e.target.value)}
        >
          {['-1', '0', '1', '2', '3'].map(i => (
            <Radio key={`RadioButton-${i}`} value={i} labelPosition="bottom">
              Step {i}
            </Radio>
          ))}
        </Radio.Group>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stepper activeStep={activeStep} short={isMobile}>
          {steps.map(step => (
            <Step key={random()} label={step} />
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12} md={6} className={classes.dark}>
        <Stepper activeStep={activeStep} light short={isMobile}>
          {steps.map(step => (
            <Step key={random()} label={step} />
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default Steppers;

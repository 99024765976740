import { gql } from '@apollo/client';

export default gql`
  query getReissueDirectDeposits($country: String, $type: String) {
    getCurrentUser {
      reissueDirectDeposits(country: $country, type: $type) {
        id
        currencyType
        routingNumber
        bankAccountNumber
        isDirectDeposit
        bankName
        flatAmount
        percentRate
        splitPercentage
        bankAccountType
        isPaperLess
        isDeliveryRequested
        forCorps
        sequence
        ba
        rn
      }
    }
    getProfileMappingOptions {
      bankAccountTypes {
        label
        value
      }
    }
  }
`;

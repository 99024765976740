const blue = {
  '+10': '#FFFFFF',
  '+9': '#EBF1F7',
  '+8': '#D6E3EF',
  '+7': '#C2D5E6',
  '+6': '#9EBFDC',
  '+3': '#6BA3D8',
  '+2': '#4A91D4',
  '+0': '#3372AD',
  primary: '#3372AD',
  '-3': '#105D98',
  '-5': '#00477D',
  '-7': '#0F2234',
  '-9': '#000000',
  background: '#F9FBFD',
  table: '#F9FDFF',
};

const royalBlue = {
  '+10': '#FFFFFF',
  '+9': '#D9E3FE',
  '+8': '#BECFFE',
  '+7': '#86A6FD',
  '+6': '#4D7BFD',
  '+3': '#1A55FD',
  '+2': '#2E1EFF',
  '+0': '#0000FF',
  primary: '#0000FF',
  '-3': '#0000B3',
  '-5': '#000080',
  '-7': '#00004C',
  '-9': '#000000',
  background: '#F2F5F7',
  table: '#E6EDFF',
};

const green = {
  '+10': '#FFFFFF',
  '+9': '#E7F6F4',
  '+8': '#D6EEED',
  '+7': '#AEE2DF',
  '+6': '#81DBD4',
  '+3': '#53CFC6',
  '+2': '#37C5BB',
  '+0': '#30ABA3',
  primary: '#30ABA3',
  '-3': '#268982',
  '-5': '#1D6762',
  '-7': '#0D3734',
  '-9': '#000000',
  background: '#F2F5F7',
  table: '#F5FCFB',
};

const gray = {
  '+10': '#FFFFFF',
  '+9': '#F4F4F4',
  '+8': '#E0E0E0',
  '+7': '#C6C6C6',
  '+6': '#A8A8A8',
  '+3': '#8D8D8D',
  '+2': '#6F6F6F',
  '+0': '#525252',
  primary: '#525252',
  '-3': '#393939',
  '-5': '#262626',
  '-7': '#161616',
  '-9': '#000000',
  background: '#F2F5F7',
  table: '#E2F1FF',
};

const gradients = {
  header: 'linear-gradient(45deg, #0000FF 0%, #0000B3 100%)',
};

const shadow = {
  modal: '0px 12px 32px 4px rgba(82, 82, 82, 0.56)',
  interactive: {
    default:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    hover:
      '0px 0px 1px rgba(82, 82, 82, 0.1), 0px 4px 8px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
    focus:
      '0px 0px 1px rgba(82, 82, 82, 0.1), 0px 3px 4px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
    active:
      '0px 0px 4px rgba(82, 82, 82, 0.1), 0px 2px 6px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
    disabled: '0px 0px 1px rgba(82, 82, 82, 0.4)',
  },
  container: {
    default:
      '0px 1px 4px rgba(82, 82, 82, 0.1), 0px 2px 8px 2px rgba(82, 82, 82, 0.1)',
    hover:
      '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 8px 2px rgba(82, 82, 82, 0.15)',
  },
};

const misc = {
  link: '#0000FF',
  tableHeader: '#F5F5F0', // Weird color that Bill suggested that does not fit into the palette
  transparent: 'rgb(0, 0, 0, 0)',
  template: '#F8EFE8',
};

const accent = {
  orange: {
    '+5': '#FFFFFF',
    '+4': '#FEF3E1',
    '+3': '#FBCB86',
    '+2': '#FFB262',
    '+1': '#FF9526',
    '+0': '#FF7623',
    primary: '#FF7623',
    '-1': '#DB5619',
    '-2': '#B73C11',
    '-3': '#000000',
  },
  rose: {
    '+5': '#FFFFFF',
    '+4': '#FCE6F3',
    '+3': '#F8C1E2',
    '+2': '#F86DBB',
    '+1': '#ED3292',
    '+0': '#D22487',
    primary: '#D22487',
    '-1': '#BB2280',
    '-2': '#931F75',
    '-3': '#000000',
  },
  lavender: {
    '+5': '#FFFFFF',
    '+4': '#F5E5F9',
    '+3': '#E5BDF0',
    '+2': '#C363DD',
    '+1': '#B53CD5',
    '+0': '#A700CD',
    primary: '#A700CD',
    '-1': '#6D01BA',
    '-2': '#47008F',
    '-3': '#000000',
  },
};

const alert = {
  green: {
    '+3': '#E5FBEA',
    powder: '#E5FBEA',
    '+2': '#C1F4CC',
    light: '#C1F4CC',
    '+1': '#95EDAB',
    hover: '#95EDAB',
    '+0': '#00DF68',
    primary: '#00DF68',
    '-1': '#008006',
    dark: '#008006',
  },
  yellow: {
    '+3': '#FFF8E1',
    powder: '#FFF8E1',
    '+2': '#FFEBB2',
    light: '#FFEBB2',
    '+1': '#FFDF81',
    hover: '#FFDF81',
    '+0': '#FFBF00',
    primary: '#FFBF00',
    '-1': '#FF6D00',
    dark: '#FF6D00',
  },
  red: {
    '+3': '#FFEAED',
    powder: '#FFEAED',
    '+2': '#FFCACF',
    light: '#FFCACF',
    '+1': '#F69493',
    hover: '#F69493',
    '+0': '#ED1C23',
    primary: '#ED1C23',
    '-1': '#C10004',
    dark: '#C10004',
  },
  gray: {
    primary: '#475467',
    border: '#D0D5DD',
  },
};

const button = {
  green: {
    '+2': '#41CBBE',
    hover: '#41CBBE',
    '+0': '#30ABA3',
    primary: '#30ABA3',
    '-3': '#2C8982',
    focus: '#2C8982',
    '-5': '#216762',
    active: '#216762',
    '-7': '#96A7A6',
    disabled: '#96A7A6',
  },
  blue: {
    '+2': '#3D79B1',
    hover: '#3D79B1',
    '+0': '#3372AD',
    primary: '#3372AD',
    '-3': '#285B8A',
    focus: '#285B8A',
    '-5': '#1E4467',
    active: '#1E4467',
    '-7': '#959DA6',
    disabled: '#959DA6',
  },
  royalBlue: {
    '+2': '#1A55FD',
    hover: '#1A55FD',
    '+0': '#0000FF',
    primary: '#0000FF',
    '-3': '#0000B3',
    focus: '#0000B3',
    '-5': '#000080',
    active: '#000080',
    '-7': '#9A9FAF',
    disabled: '#9A9FAF',
  },
  red: {
    '+2': '#F69493',
    hover: '#F69493',
    '+0': '#ED1C23',
    primary: '#ED1C23',
    '-3': '#DB071E',
    focus: '#DB071E',
    '-5': '#C10004',
    active: '#C10004',
    '-7': '#B78083',
    disabled: '#B78083',
  },
};

export default {
  white: '#FFFFFF',
  black: '#000000',
  primary: {
    blue,
    green,
    gray,
    royalBlue,
  },
  shadow,
  gradients,
  misc,
  accent,
  alert,
  button,
};

import { gql } from '@apollo/client';

export default gql`
  query completedOffers {
    completedOffers {
      filename
      id
      jobTitle
      approvedAt
      project {
        name
      }
      status
    }
    myFiles(status: "uploaded") {
      id
      createdAt
      category
      active
      name
    }
  }
`;

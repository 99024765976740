import { useState } from 'react';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Checkbox from 'components/CncUI/Checkbox';
import DatePicker from 'components/CncUI/DatePicker';
import Typography from 'components/CncUI/Typography';

const DatePickers = () => {
  const [date, setDate] = useState(new Date());
  const [withLabel, setWithLabel] = useState(false);
  const [light, setLight] = useState(true);
  const [short, setShort] = useState(false);
  const [clearable, setClearable] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">DatePickers</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={short}
              onChange={e => setShort(e.target.checked)}
            >
              Short size
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={light}
              onChange={e => setLight(e.target.checked)}
            >
              Light fields
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={withLabel}
              onChange={e => setWithLabel(e.target.checked)}
            >
              With label
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={clearable}
              onChange={e => setClearable(e.target.checked)}
            >
              Clearable
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Regular DatePicker</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <DatePicker
              light={light}
              short={short}
              value={date}
              defaultValue={date}
              clearable={clearable}
              onChange={setDate}
              label={withLabel ? 'With Label' : undefined}
            />
            <Typography>
              Day selected: {dayjs(date).format('MM / DD / YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DatePickers;

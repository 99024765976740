import { gql } from '@apollo/client';

export default gql`
  query getMinorPaystubImage(
    $id: String
    $country: String
    $checkSequence: String
  ) {
    getCurrentUser {
      minor(id: $id) {
        paystubs(country: $country, checkSequence: $checkSequence) {
          minorCheckImage(id: $id)
        }
      }
    }
  }
`;

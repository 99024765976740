import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from 'components/CncUI/Checkbox';
import RadioCard from 'components/CncUI/RadioCard';

const RadioCards = () => {
  const space = [1, 2, 3, 4, 5, 6].map(i => <br key={i} />);
  const [white, setWhite] = useState(false);
  const [value, setValue] = useState('1');

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <h2 className="header">Radio Cards</h2>
      </Grid>
      <Grid item xs={12}>
        <Checkbox value={white} onChange={e => setWhite(e.target.checked)}>
          White Radio Cards
        </Checkbox>
      </Grid>
      <Grid item xs={12}>
        <RadioCard.Group value={value} onChange={e => setValue(e.target.value)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <RadioCard value="1" label="CardTitle" white={white}>
                {space}
              </RadioCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RadioCard value="2" label="CardTitle" white={white}>
                {space}
              </RadioCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RadioCard value="3" label="CardTitle" white={white}>
                {space}
              </RadioCard>
            </Grid>
          </Grid>
        </RadioCard.Group>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Disabled RadioCards</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <RadioCard label="CardTitle" disabled checked white={white}>
              {space}
            </RadioCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <RadioCard label="CardTitle" disabled white={white}>
              {space}
            </RadioCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RadioCards;

import clsx from 'clsx';
import TableCell from '@mui/material/TableCell';

const Cell = props => {
  const { children, className, ...other } = props;
  const options = {
    className: clsx('cncui-table-cell', className),
  };

  return (
    <TableCell {...options} {...other}>
      {children}
    </TableCell>
  );
};

export default Cell;

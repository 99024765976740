import InputMask from 'react-text-mask';
import TextField from 'components/CncUI/TextField';
import { phoneMask, phoneMaskUSA } from 'constants/masks';

const TextFieldPhone = props => {
  const {
    includeCountryCode,
    onChange,
    onBlur = () => {},
    value,
    ...other
  } = props;

  let mask = phoneMask;
  if (includeCountryCode) {
    mask = phoneMaskUSA;
  }

  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      render={(ref, props) => (
        <TextField inputRef={ref} {...other} {...props} />
      )}
    />
  );
};

export default TextFieldPhone;

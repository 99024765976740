import { GraphQLClient, gql } from 'graphql-request';
import sysend from 'sysend';
import getApiServer from 'constants/config/apiServer';
import client from './client';

export default (type = 'mycc') => {
  const okta = client(type);
  okta.authStateManager.subscribe(() => {});

  const checkSession = async () => await okta.session.exists();

  const isIDP = async email => {
    const resource = `acct:${email}`;
    const data = await okta.webfinger({ resource });
    return data.links.some(l => l.rel === 'okta:idp');
  };

  const idToken = async () => {
    const token = await okta.tokenManager.get('idToken');
    if (token) return token;
    let response = null;

    if (okta.token.isLoginRedirect()) {
      // this would be an ugly hack until parseFromUrl() works properly
      // response = await okta.token.parseFromUrl();
      window.location.replace('/');
      return;
    } else {
      response = await okta.token.getWithoutPrompt({
        responseType: 'id_token',
        scopes: ['profile', 'openid', 'email'],
      });
    }

    okta.tokenManager.add('idToken', response.tokens.idToken);
    return response.tokens.idToken;
  };

  const accessToken = async scopes => {
    if (!Array.isArray(scopes)) {
      scopes = scopes.split(',');
    }

    const scopesKey = `access_${scopes.sort().join(',')}`;
    const token = await okta.tokenManager.get(scopesKey);
    if (token && !okta.tokenManager.hasExpired(token)) return token;

    const response = await okta.token.getWithoutPrompt({
      responseType: 'token',
      scopes: scopes.includes('openid') ? scopes : ['openid', ...scopes],
    });

    okta.tokenManager.add(scopesKey, response.tokens.accessToken);
    return response.tokens.accessToken;
  };

  const logout = async () => {
    const exists = await checkSession();
    if (exists && !window.Cypress) {
      const mutation = gql`
        mutation logout {
          logout
        }
      `;
      const token = await accessToken('openid');
      const graphqlClient = new GraphQLClient(getApiServer() + '/graphql', {
        headers: {
          authorization: `Bearer ${token.accessToken}`,
        },
      });
      const response = await graphqlClient.request(mutation);
      okta.tokenManager.clear();
      sysend.broadcast('logout', {});
      return response.logout;
    }
  };

  const clearToken = () => {
    okta.tokenManager.clear();
  };

  return {
    checkSession,
    accessToken,
    idToken,
    logout,
    isIDP,
    client: okta,
    clearToken,
  };
};

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import FolderIcon from '@mui/icons-material/Folder';
import Typography from 'components/CncUI/Typography';
import Checkbox from 'components/CncUI/Checkbox';
import Accordion from 'components/CncUI/Accordion';

const Accordions = () => {
  const [short, setShort] = useState(false);
  const [left, setLeft] = useState(false);
  const [icon, setIcon] = useState(false);
  const [expanded, setExpanded] = useState('');
  const expand = panel => (_, isExpanded) =>
    setExpanded(isExpanded ? panel : '');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Accordions</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={short}
              onChange={e => setShort(e.target.checked)}
            >
              Short size
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox checked={left} onChange={e => setLeft(e.target.checked)}>
              Expanded icon to the left
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox checked={icon} onChange={e => setIcon(e.target.checked)}>
              Icon in title
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        {['1', '2', '3', '4', '5'].map(key => (
          <Accordion
            key={key}
            short={short}
            expanded={expanded === key}
            onChange={expand(key)}
          >
            <Accordion.Title
              Icon={icon ? <FolderIcon /> : null}
              expandedAlign={left ? 'left' : 'right'}
            >
              Accordion {key}
            </Accordion.Title>
            <Accordion.Content>
              <Typography size="long">
                Panel #{key}: Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat
              </Typography>
            </Accordion.Content>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default Accordions;

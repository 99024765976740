import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      background: palette.cncUI.primary.gray['+8'],
      boxShadow: palette.cncUI.shadow.container.default,
      borderLeft: `4px solid ${palette.cncUI.primary.gray['+0']}`,
    },
    info: {
      background: palette.cncUI.default['+10'],
      borderLeft: `4px solid ${palette.cncUI.default.primary}`,
      '& .MuiAlert-icon': {
        color: palette.cncUI.default.primary,
      },
    },
    success: {
      background: palette.cncUI.alert.green.powder,
      borderLeft: `4px solid ${palette.cncUI.alert.green.primary}`,
      '& .MuiAlert-icon': {
        color: palette.cncUI.alert.green.primary,
      },
    },
    warning: {
      background: palette.cncUI.alert.yellow.powder,
      borderLeft: `4px solid ${palette.cncUI.alert.yellow.primary}`,
      '& .MuiAlert-icon': {
        color: palette.cncUI.alert.yellow.primary,
      },
    },
    error: {
      background: palette.cncUI.alert.red.powder,
      borderLeft: `4px solid ${palette.cncUI.alert.red.primary}`,
      '& .MuiAlert-icon': {
        color: palette.cncUI.alert.red.primary,
      },
    },
    plain: {
      background: palette.dashboard.background,
      borderLeft: 0,
      borderBottom: '1px solid var(--Gray-300, #D0D5DD)', // @Ven Virtusio this border needs to be in the palette
      boxShadow:
        '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      '& .MuiAlert-icon': {
        display: 'none',
      },
    },
    toast: {
      background: palette.cncUI.alert.gray.primary,
      border: `1px solid ${palette.cncUI.alert.gray.border}`,
      borderRadius: 12,
      color: palette.cncUI.white,
      '& .MuiAlert-icon': {
        display: 'none',
      },
    },
    noBorder: {
      borderLeft: 'none',
    },
    inline: {
      '& .MuiAlert-message': {
        display: 'flex',
        alignItems: 'flex-start',
        '& .MuiAlertTitle-root': {
          marginRight: 8,
          marginBottom: 0,
        },
      },
    },
  }),
  { name: 'CncUI-Alert' },
);

const Alert = props => {
  const classes = useStyles();
  const {
    children,
    className,
    info,
    success,
    warning,
    error,
    fullWidth,
    plain = false,
    inline = false,
    noBorder = false,
    title = null,
    ...other
  } = props;

  const severity = other.severity
    ? other.severity
    : info
    ? 'info'
    : success
    ? 'success'
    : warning
    ? 'warning'
    : error
    ? 'error'
    : 'info';

  const options = {
    severity,
    className: clsx(
      'cncui-alert',
      classes.root,
      severity === 'toast' && classes.toast,
      severity === 'info' && classes.info,
      severity === 'success' && classes.success,
      severity === 'warning' && classes.warning,
      severity === 'error' && classes.error,
      (plain || other.severity === 'plain') && classes.plain,
      noBorder && classes.noBorder,
      inline && classes.inline,
      className,
    ),
  };

  if (other.severity === 'plain') {
    other.severity = 'info';
  }

  return (
    <MuiAlert {...options} {...other}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </MuiAlert>
  );
};

export default Alert;

import { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio from '@mui/material/Radio';
import RadioGroup from './RadioGroup';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      padding: 8,
      color: palette.cncUI.primary.gray['+2'],
      background: palette.cncUI.default['+10'],
      borderRadius: '50%',
      width: 20,
      height: 20,
      margin: 4,
      '& .MuiSvgIcon-root': {
        fontSize: 28,
      },
      '&.Mui-checked': {
        color: palette.cncUI.default.primary,
      },
      '&.Mui-disabled': {
        color: palette.cncUI.primary.gray['+7'],
      },
      '&.Mui-disabled.Mui-checked': {
        color: palette.cncUI.primary.gray['+7'],
      },
    },
    dense: {
      padding: '2px 8px',
    },
    light: {
      color: palette.cncUI.default['+10'],
      '&.Mui-checked': {
        color: palette.cncUI.default['+10'],
        background: 'transparent',
      },
      '&.Mui-disabled.Mui-checked': {
        background: palette.cncUI.default['+10'],
      },
    },
    label: {
      marginLeft: 0,
      marginTop: 4,
      marginBottom: 4,
      '& .MuiButtonBase-root.Mui-checked + .MuiTypography-root': {
        color: palette.cncUI.default.primary,
      },
      '& .MuiButtonBase-root.Mui-checked + .MuiTypography-root.Mui-disabled': {
        color: palette.cncUI.primary.gray['+7'],
      },
      '& .MuiTypography-root': {
        color: palette.cncUI.primary.gray['+0'],
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 400,
        '&.Mui-disabled': {
          color: palette.cncUI.primary.gray['+7'],
        },
      },
    },
    lightLabel: {
      '& .MuiTypography-root': {
        color: palette.cncUI.default['+10'],
      },
      '& .MuiButtonBase-root.Mui-checked + .MuiTypography-root': {
        color: palette.cncUI.default['+10'],
      },
    },
    short: {
      width: 16,
      height: 16,
      margin: 0,
      '& .MuiSvgIcon-root': {
        fontSize: 22,
      },
    },
    compact: {
      width: 12,
      height: 12,
      margin: 0,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
    tall: {
      width: 26,
      height: 26,
      '& .MuiSvgIcon-root': {
        fontSize: 38,
      },
    },
    shortLabel: {
      '& .MuiTypography-root': {
        fontSize: 14,
        lineHeight: '18px',
        letterSpacing: '0.16px',
      },
    },
    compactLabel: {
      '& .MuiTypography-root': {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.32px',
      },
    },
    tallLabel: {
      '& .MuiTypography-root': {
        fontSize: 24,
        lineHeight: '26px',
      },
    },
  }),
  { name: 'CncUI-Radio' },
);

const POSITION = {
  top: 'top',
  right: 'end',
  bottom: 'bottom',
  left: 'start',
};

const Radio = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    children,
    className,
    value,
    size = 'normal',
    light = false,
    labelPosition = 'right',
    labelProps = {},
    dense = false,
    ...other
  } = props;

  const options = {
    className: clsx(
      'cncui-radio',
      classes.root,
      dense && classes.dense,
      light && classes.light,
      classes[size],
      `cncui-radio-${size}`,
      className,
    ),
  };

  if (children) {
    return (
      <FormControlLabel
        labelPlacement={POSITION[labelPosition] || 'end'}
        value={value}
        {...labelProps}
        className={clsx(
          'cncui-radio-label',
          classes.label,
          classes[`${size}Label`],
          light && classes.lightLabel,
          labelProps.className,
        )}
        control={<MuiRadio ref={ref} {...options} {...other} />}
        label={children}
      />
    );
  }

  return <MuiRadio {...options} {...other} />;
});

Radio.Group = RadioGroup;

export default Radio;

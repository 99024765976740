import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TimeIcon from '@mui/icons-material/AccessTime';
import Checkbox from 'components/CncUI/Checkbox';
import { Tabs, Tab } from 'components/CncUI/Tabs';

const TabsSection = () => {
  const [value, setValue] = useState(0);
  const [contained, setContained] = useState(false);
  const [position, setPosition] = useState('start');
  const [icon, setIcon] = useState(false);
  const handleChange = (_, index) => setValue(index);
  const Icon = icon ? <TimeIcon /> : null;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Tabs</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              value={contained}
              onChange={e => setContained(e.target.checked)}
            >
              Contained tabs
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox value={icon} onChange={e => setIcon(e.target.checked)}>
              With icon
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              value={position === 'right'}
              onChange={e => setPosition(e.target.checked ? 'end' : 'start')}
            >
              Icon on the right
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange} contained={contained}>
          <Tab icon={Icon} iconPosition={position}>
            Tab Label 1
          </Tab>
          <Tab icon={Icon} iconPosition={position}>
            Tab Label 2
          </Tab>
          <Tab icon={Icon} iconPosition={position}>
            Tab Label 3
          </Tab>
        </Tabs>
      </Grid>
    </Grid>
  );
};

export default TabsSection;

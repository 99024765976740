import { gql } from '@apollo/client';

export default gql`
  query getMinorW4s($id: String!, $country: String) {
    getCurrentUser {
      id
      minor(id: $id) {
        w4Documents(country: $country) {
          id
          stateId
          documentId
          name
          createdAt
          lastLevelHistoryProcessed
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation updateTaxDocument(
    $id: String!
    $ssn: String!
    $name: String!
    $employer: String!
    $year: Int!
  ) {
    updateTaxDocument(
      id: $id
      ssn: $ssn
      name: $name
      employer: $employer
      year: $year
    )
  }
`;

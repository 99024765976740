const sortFunction = (value = item => item) => ({
  asc: (a, b) => (value(a) > value(b) ? 1 : value(a) < value(b) ? -1 : 0),
  desc: (a, b) => (value(a) < value(b) ? 1 : value(a) > value(b) ? -1 : 0),
});

export default (sortBy, direction, data) => {
  if (!sortBy || !data) return data;
  const value = item => item[sortBy].sortValue;
  const order = ['asc', 'desc'].includes(direction) ? direction : 'asc';
  return data.sort(sortFunction(value)[order]);
};

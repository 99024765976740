import { gql } from '@apollo/client';

export default gql`
  mutation validateActivationToken($token: String!) {
    validateActivationToken(token: $token) {
      stateToken
      status
      user {
        id
        email
        firstName
        lastName
      }
      policy
    }
  }
`;

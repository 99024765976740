import { useState } from 'react';
import ThemeContext from './Context';

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('default');
  const value = { theme, setTheme };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;

import { gql } from '@apollo/client';

export default gql`
  query myFile($id: String!) {
    myFile(id: $id) {
      name
      getUrl
    }
  }
`;

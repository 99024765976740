import BellIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';

const NotificationBadge = ({ notifications = [] }) => {
  // The hook for notifications data must parse
  // the settings object before it goes to the components
  const unread = notifications.filter(n => n.settings.unread);
  return (
    <Badge
      variant="dot"
      color="error"
      overlap="circular"
      invisible={unread.length === 0}
      data-test-id="notification-badge"
    >
      <BellIcon />
    </Badge>
  );
};

export default NotificationBadge;

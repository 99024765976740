import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from 'components/CncUI/Checkbox';
import Dropdown from 'components/CncUI/Dropdown';
import Table from 'components/CncUI/Table';

const Tables = () => {
  const [state, setState] = useState({
    sort: '',
    order: 'asc',
    zebra: false,
    hover: false,
    light: false,
    lighter: false,
    size: 'normal',
  });
  const modify = data => setState(old => ({ ...old, ...data }));

  const sort = column =>
    modify({ sort: column, order: state.order === 'asc' ? 'desc' : 'asc' });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Simple table</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Checkbox
              checked={state.zebra}
              onChange={e => modify({ zebra: e.target.checked })}
            >
              Zebra
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={state.hover}
              onChange={e => modify({ hover: e.target.checked })}
            >
              Hover
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={state.light}
              onChange={e => modify({ light: e.target.checked })}
            >
              Light
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={state.lighter}
              onChange={e => modify({ lighter: e.target.checked })}
            >
              Lighter
            </Checkbox>
          </Grid>
          <Grid item>
            <Dropdown
              light
              value={state.size}
              onChange={e => modify({ size: e.target.value })}
            >
              <Dropdown.Item value="normal">Normal</Dropdown.Item>
              <Dropdown.Item value="short">Short</Dropdown.Item>
              <Dropdown.Item value="compact">Compact</Dropdown.Item>
              <Dropdown.Item value="tall">Tall</Dropdown.Item>
            </Dropdown>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          zebra={state.zebra}
          hover={state.hover}
          light={state.light}
          lighter={state.lighter}
          size={state.size}
        >
          <Table.Head>
            <Table.Row>
              <Table.Cell>Special</Table.Cell>
              {[1, 2, 3].map(id => (
                <Table.HeadCell
                  key={`TableHeadCell${id}`}
                  label={`Header ${id}`}
                  column={`header${id}`}
                  sort={state.sort}
                  order={state.order}
                  onClick={sort}
                />
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {[1, 2, 3, 4].map(value => (
              <Table.Row key={`Row${value}`}>
                <Table.Cell>Value {value}</Table.Cell>
                <Table.Cell>Value {value}</Table.Cell>
                <Table.Cell>Value {value}</Table.Cell>
                <Table.Cell>Value {value}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Table.Pagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={20}
          rowsPerPage={5}
          page={1}
        />
      </Grid>
    </Grid>
  );
};

export default Tables;

import { gql } from '@apollo/client';

export default gql`
  mutation userForgotPassword($email: String!, $type: String!) {
    userForgotPassword(email: $email, type: $type) {
      stateToken
      status
    }
  }
`;

import clsx from 'clsx';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '../Typography';

const SheeContentText = props => {
  const { children, className, contentProps = {}, ...other } = props;

  return (
    <DialogContentText
      component="div"
      className={clsx('cncui-sheet-content-text', className)}
      {...other}
    >
      <Typography size="long" {...contentProps}>
        {children}
      </Typography>
    </DialogContentText>
  );
};

export default SheeContentText;

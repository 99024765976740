// Utilities
import request from 'superagent';
import config from 'constants/config/loqate';

const searchParams = (text, Container, limit = 10, Countries = 'US,CA') => {
  const Text = text.replace(/([\d]{5})-[\d]+/, '$1');
  const Limit = Text ? limit : undefined;
  return {
    ...config.params,
    Countries,
    Limit,
    Text,
    Container,
  };
};

const getParams = Id => {
  return { ...config.params, Id };
};

const req = (url, params) => request.get(url).query(params);

export default {
  get: id => req(config.url.get, getParams(id)),
  search: (text, container, limit = 10, countries = 'US,CA') =>
    req(config.url.search, searchParams(text, container, limit, countries)),
};

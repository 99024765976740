import { useEffect, useState } from 'react';
import useAuthentication from 'hooks/useAuthentication';
import useQuery from 'hooks/useQuery';
import Logger from 'utilities/logger';

const PERMISSIONS = [
  {
    name: 'payroll_employee:edit',
    label: 'Crew Member',
  },
  {
    name: 'project:read',
    label: 'Production Project Onboarding',
  },
  {
    name: 'adjustment:create',
    label: 'Production Adjustment',
  },
];

const usePendo = () => {
  const { user: currentUser, permissions } = useAuthentication();
  const [paidBy, setPaidBy] = useState(null);
  const logger = Logger('Pendo');

  const getPaidBy = useQuery('getPaidBy', {
    response: res => res.data.getCurrentUser.paidBy,
  });

  useEffect(() => {
    const fetchPaidBy = async () => {
      const paidBy = await getPaidBy();
      setPaidBy(paidBy);
    };
    fetchPaidBy();
  }, []);

  useEffect(() => {
    if (!(currentUser?.uid && window.pendo && paidBy !== null)) {
      return;
    }

    // Sending Okta ID as visitorId and accountId
    const roles = PERMISSIONS.reduce((acc, permission) => {
      if (permissions.includes(permission.name)) {
        acc.push(permission.label);
      }
      return acc;
    }, []);

    logger.info('Pendo Identify Logged User');
    logger.debug(`Okta ID => ${currentUser.uid}`);
    logger.debug(`Email => ${currentUser.email}`);
    logger.debug('Permissions =>', permissions);
    logger.debug('Roles =>', roles);

    const identify = {
      visitor: {
        id: currentUser.uid,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        roles,
      },
      account: {
        id: currentUser.uid,
        current_account_ID: paidBy,
      },
    };

    logger.debug('Identify =>', identify);
    window.pendo.identify(identify);
  }, [currentUser, paidBy]);
};

export default usePendo;

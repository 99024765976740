import { gql } from '@apollo/client';

export default gql`
  query adminPayrollUser($ssn: String!) {
    getPayrollUser(ssn: $ssn) {
      firstName
      middleInitial
      lastName
      birthDate
      addressLine1
      city
      state
      zipCode
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getProducer($producerId: Int!, $dbCode: String!) {
    getProducer(producerId: $producerId, dbCode: $dbCode) {
      name
      addressLine1
      addressLine2
      city
      state
      zipCode
      phoneNumber
    }
  }
`;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiRadioGroup from '@mui/material/RadioGroup';
import Typography from '../Typography';
import Tooltip from '../Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { FormHelperText } from '@mui/material';

const useStyles = makeStyles(
  ({ palette, spacing }) => ({
    leftLabel: {
      textAlign: 'left',
    },
    centerLabel: {
      textAlign: 'center',
    },
    rightLabel: {
      textAlign: 'right',
    },
    error: {
      '& .MuiFormHelperText-root, p': {
        marginTop: spacing(1),
        color: palette.cncUI.alert.red.primary,
      },
    },
    fullWidth: {
      width: '100%',
    },
    box: {
      overflow: 'auto',
      background: palette.cncUI.primary.gray['+9'],
      padding: '8px 16px',
    },
    infoIcon: {
      fontSize: 16,
      color: palette.cncUI.default.primary,
      verticalAlign: 'middle',
    },
  }),
  { name: 'CncUI-RadioGroup' },
);

const RadioGroup = props => {
  const classes = useStyles();
  const {
    children,
    className,
    style = {},
    label = '',
    labelAlign = 'left',
    labelProps = {},
    error = false,
    helperText = '',
    fullWidth = false,
    box = false,
    boxItems = 4,
    toolTip = {},
    ...other
  } = props;

  const options = {
    className: clsx('cncui-radio-group', classes.root, className),
  };

  const control = box ? (
    <div
      className={clsx('CncUI-RadioGroup-Box', classes.box)}
      style={{ height: 44 * boxItems }}
    >
      <MuiRadioGroup {...options} {...other}>
        {children}
      </MuiRadioGroup>
    </div>
  ) : (
    <MuiRadioGroup {...options} {...other}>
      {children}
    </MuiRadioGroup>
  );

  if (label) {
    return (
      <FormControl
        variant="standard"
        component="fieldset"
        className={clsx(error && classes.error, fullWidth && classes.fullWidth)}
      >
        <FormLabel
          component="legend"
          className={clsx(
            'cncui-radio-group-label',
            classes[`${labelAlign}Label`],
          )}
        >
          <Typography variant="caption" {...labelProps}>
            {label}{' '}
            {toolTip?.show && (
              <Tooltip
                size="small"
                position="bottom-left"
                enterTouchDelay={100}
                content={toolTip.content}
              >
                <span style={{ marginLeft: 4 }}>
                  <InfoIcon className={classes.infoIcon} />
                </span>
              </Tooltip>
            )}
          </Typography>
        </FormLabel>
        {control}
        {error ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }

  return (
    <div
      className={clsx(error && classes.error, fullWidth && classes.fullWidth)}
    >
      {control}
      {error ? <FormHelperText>{helperText}</FormHelperText> : null}
    </div>
  );
};

export default RadioGroup;

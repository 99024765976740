import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import TablePagination from '@mui/material/TablePagination';
import PaginationActions from './PaginationActions';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      '& .cncui-table-pagination-actions': {
        color: palette.cncUI.default.primary,
        '& .MuiIconButton-root.Mui-disabled': {
          color: palette.cncUI.primary.gray['+7'],
        },
      },
      '& .MuiTablePagination-actions': {
        color: palette.cncUI.default.primary,
        '& .MuiIconButton-root.Mui-disabled': {
          color: palette.cncUI.primary.gray['+7'],
        },
      },
      '& .MuiSelect-root': {
        boxShadow: `inset 0px -1px 0px ${palette.cncUI.primary.gray['+3']}`,
        background: palette.cncUI.default['+10'],
      },
    },
    noPerPageOptions: {
      '& .MuiTablePagination-selectRoot': {
        display: 'none',
      },
    },
    menu: {
      backgroundColor: palette.cncUI.default['+10'],
      boxShadow: '0px 0px 5px rgba(38, 38, 38, 0.7)',
      padding: 0,
    },
    menuItem: {
      color: palette.cncUI.primary.gray['+0'],
      '&:hover': {
        backgroundColor: palette.cncUI.primary.gray['+8'],
        color: palette.cncUI.primary.gray['+0'],
      },
      '&.Mui-selected': {
        backgroundColor: palette.cncUI.default['+9'],
        color: palette.cncUI.default.primary,
      },
      '&.Mui-selected:hover': {
        backgroundColor: palette.cncUI.default['+9'],
        color: palette.cncUI.default.primary,
      },
    },
  }),
  { name: 'CncUI-TablePagination' },
);

const Pagination = props => {
  const classes = useStyles();

  const {
    className,
    ActionsComponent = PaginationActions,
    noPerPageOptions = false,
    ...other
  } = props;

  if (noPerPageOptions) {
    other.labelRowsPerPage = '';
  }

  const options = {
    ActionsComponent,
    className: clsx(
      'cncui-table-pagination',
      classes.root,
      noPerPageOptions && classes.noPerPageOptions,
      className,
    ),
    onPageChange: () => {},
    classes: { menuItem: classes.menuItem },
    SelectProps: {
      MenuProps: { classes: { paper: classes.menu } },
    },
  };

  return <TablePagination {...options} {...other} />;
};

export default Pagination;

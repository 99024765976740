import { gql } from '@apollo/client';

export default gql`
  mutation verifyEmployee(
    $ssn: String!
    $firstName: String!
    $lastName: String!
    $screenName: String
    $dob: String!
    $email: String!
    $address: String!
    $countryFlag: String
    $idType: String
  ) {
    verifyEmployee(
      ssn: $ssn
      firstName: $firstName
      lastName: $lastName
      screenName: $screenName
      dob: $dob
      email: $email
      address: $address
      countryFlag: $countryFlag
      idType: $idType
    )
  }
`;

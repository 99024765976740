import { gql } from '@apollo/client';

export default gql`
  query getRelatedAdmins {
    getRelatedAdmins {
      id
      loginId
      relatedLoginId
      data
      createdAt
      updatedAt
    }
  }
`;

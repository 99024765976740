import { gql } from '@apollo/client';

export default gql`
  query getMinorPaystubs($id: String, $country: String) {
    getCurrentUser {
      minor(id: $id) {
        paystubs(country: $country) {
          checkSequence
          checkNumber
          effectiveDate
          weekEndingDate
          checkGross
          checkNet
          prodcoName
          projectTitle
          workState
          workCountry
          dataSource
        }
      }
    }
  }
`;

import clsx from 'clsx';
import { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MuiStepper from '@mui/material/Stepper';
import MuiStepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Step from './Step';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      backgroundColor: 'transparent',
      '& + .Cnc-MobileLabel': {
        color: palette.cncUI.default.primary,
      },
      '& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line': {
        borderColor: palette.cncUI.default['+7'],
      },
    },
    light: {
      '& .MuiStep-root .MuiStepLabel-iconContainer': {
        borderColor: palette.cncUI.default['+10'],
        background: palette.cncUI.default['+10'],
      },
      '& .MuiStep-root.Mui-completed .MuiStepLabel-iconContainer': {
        background: palette.cncUI.default.primary,
        '& .MuiStepIcon-root': {
          color: palette.cncUI.default['+10'],
        },
      },
      '& .MuiStepLabel-labelContainer .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        color: palette.cncUI.default['+10'],
      },
      '& + .Cnc-MobileLabel': {
        color: palette.cncUI.default['+10'],
      },
      '& .MuiStepLabel-root.Mui-disabled': {
        '& .MuiStepLabel-iconContainer': {
          background: 'transparent',
          border: `2px solid ${palette.cncUI.default['+7']} !important`,
        },
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          color: `${palette.cncUI.default['+7']} !important`,
        },
      },
    },
    mobileShort: {
      '& .MuiStep-alternativeLabel': {
        flex: '1 0 50%',
      },
    },
    short: {
      '& .MuiStep-root .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        fontSize: 12,
      },
      '& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line': {
        borderColor: palette.cncUI.default['+6'],
      },
      '& .MuiStepConnector-root': {
        top: 5,
        left: 'calc(-50% + 6px)',
        right: 'calc(50% + 6px)',
      },
      '& .MuiStepConnector-line': {
        borderTopWidth: 2,
      },
      '& .MuiStep-root .MuiStepLabel-iconContainer': {
        background: palette.cncUI.default.primary,
        borderWidth: 1.5,
        width: 9,
        height: 9,
      },
      '& .MuiStepLabel-root.Mui-disabled .MuiStepLabel-iconContainer': {
        borderWidth: `1.5px !important`,
        borderColor: palette.cncUI.default['+6'],
        background: 'transparent',
      },
      '& .MuiStep-root.Mui-completed .MuiStepLabel-iconContainer': {
        width: 12,
        height: 12,
        background: palette.cncUI.default['+10'],
        '& .MuiSvgIcon-root': {
          width: 12,
          height: 12,
        },
      },
      '& .MuiStepLabel-labelContainer .MuiStepLabel-alternativeLabel': {
        display: 'none',
      },
      '& + .Cnc-MobileLabel': {
        fontSize: 12,
        display: 'block',
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 16,
      },
    },
    shortLight: {
      '& .MuiStep-root .MuiStepLabel-iconContainer': {
        background: palette.cncUI.default['+10'],
      },
      '& .MuiStep-root.Mui-completed .MuiStepLabel-iconContainer': {
        background: 'transparent',
      },
    },
  }),
  { name: 'CncUI-Stepper' },
);

const Connector = styled(MuiStepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: 'calc(-50% + 14px)',
    right: 'calc(50% + 14px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.cncUI.default.primary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.cncUI.default.primary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.cncUI.default['+7'],
    borderTopWidth: 4,
    borderRadius: 1,
  },
}));

const LightConnector = styled(MuiStepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: 'calc(-50% + 14px)',
    right: 'calc(50% + 14px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.cncUI.default['+10'],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.cncUI.default['+10'],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.cncUI.default['+7'],
    borderTopWidth: 4,
    borderRadius: 1,
  },
}));

const activeLabel = (node, activeStep) => {
  const steps = Array.from(node.querySelectorAll('.MuiStepLabel-label')).map(
    step => step.innerText || '',
  );
  return steps[
    (activeStep || 0) <= 0
      ? 0
      : activeStep >= steps.length
      ? steps.length - 1
      : activeStep
  ];
};

const Stepper = props => {
  const classes = useStyles();
  const [ref, setRef] = useState(null);
  const [label, setLabel] = useState('');
  const {
    children,
    light,
    short,
    mobileShort,
    className,
    activeStep,
    ...other
  } = props;

  useEffect(() => {
    if (ref) {
      setLabel(activeLabel(ref, activeStep));
    }
  }, [ref, activeStep]);

  const mobileLabel = useCallback(
    node => {
      if (node !== null && short) {
        setRef(node);
      }
    },
    [short],
  );

  const options = {
    alternativeLabel: true,
    connector: light ? <LightConnector /> : <Connector />,
    activeStep: parseInt(activeStep, 10) || 0,
    className: clsx(
      classes.root,
      light && classes.light,
      short && classes.short,
      short && light && classes.shortLight,
      mobileShort && classes.mobileShort,
      className,
    ),
  };

  return (
    <div
      ref={mobileLabel}
      className="CncStepper-wrapper"
      style={{ overflow: 'auto' }}
    >
      <MuiStepper {...options} {...other}>
        {children}
      </MuiStepper>
      {short && <span className="Cnc-MobileLabel">{label}</span>}
    </div>
  );
};

Stepper.Step = Step;

export default Stepper;

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from 'components/CncUI/Button';

import useFileUpload from 'hooks/useFileUpload';
import useFileDownload from 'hooks/useFileDownload';

const FileUploadDownload = () => {
  const [file, setFile] = useState(null);
  const [mime, setMime] = useState('');
  const [status, setStatus] = useState('waiting');
  const [loading, setLoading] = useState(false);
  const fileUpload = useFileUpload('testUpload', {
    scopes: null,
    response: res => res.data.testUpload,
  });
  const fileDownload = useFileDownload('testDownload', {
    scopes: null,
    request: name => ({ name }),
    response: res => res.data.testDownload,
  });

  const upload = e => {
    setFile(null);
    setLoading(true);
    setStatus('uploading...');
    e.preventDefault();
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    reader.readAsDataURL(selectedFile);
    reader.onload = async () => {
      setMime(selectedFile.type);
      const file = {
        name: selectedFile.name,
        type: selectedFile.type,
        body: reader.result.split(',')[1],
      };
      fileUpload({ file })
        .then(() => {
          setStatus('downloading...');
          fileDownload(file.name)
            .then(setFile)
            .catch(err => setStatus(err.message))
            .finally(() => {
              setLoading(false);
              setStatus('done');
            });
        })
        .catch(err => setStatus(err.message))
        .finally(() => setLoading(false));
    };
  };

  const clear = () => {
    setFile(null);
    setStatus('waiting');
    setLoading(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Big Files Upload/Download</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <input
              style={{ display: 'none' }}
              id="FileUpload"
              type="file"
              onChange={upload}
            />
            <label htmlFor="FileUpload">
              <Button component="span" loading={loading}>
                Upload
              </Button>
            </label>
          </Grid>
          <Grid item>
            <Button loading={loading} onClick={clear}>
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        Status: {status.toString()}
      </Grid>
      <Grid item xs={12}>
        {file && mime.includes('image') && (
          <img src={`data:${mime};base64,${file}`} alt="showcase upload" />
        )}
      </Grid>
    </Grid>
  );
};

export default FileUploadDownload;

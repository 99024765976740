import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Dropdown from 'components/CncUI/Dropdown';
import Checkbox from 'components/CncUI/Checkbox';
import Switch from 'components/CncUI/Switch';

const Switches = () => {
  const [size, setSize] = useState('normal');
  const [light, setLight] = useState(false);
  const [state, setState] = useState({
    one: false,
    two: true,
    three: false,
  });

  const handleChange = e =>
    setState({ ...state, [e.target.name]: e.target.checked });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Switches</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Dropdown
              light
              label="Size"
              value={size}
              onChange={e => setSize(e.target.value)}
            >
              <Dropdown.Item value="normal">Normal</Dropdown.Item>
              <Dropdown.Item value="short">Short</Dropdown.Item>
              <Dropdown.Item value="compact">Compact</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <Checkbox
              checked={light}
              onChange={e => setLight(e.target.checked)}
            >
              Light Switch
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">Regular</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Switch
              light={light}
              size={size}
              checked={state.one}
              onChange={handleChange}
              name="one"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              checked={state.two}
              onChange={handleChange}
              name="two"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              disabled
              inputProps={{ 'aria-label': 'disabled checkbox' }}
            />
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              disabled
              checked
              inputProps={{ 'aria-label': 'disabled checked checkbox' }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">With label</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Switch
              light={light}
              size={size}
              checked={state.one}
              onChange={handleChange}
              name="one"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            >
              Unchecked
            </Switch>
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              checked={state.two}
              onChange={handleChange}
              name="two"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            >
              Checked
            </Switch>
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              disabled
              inputProps={{ 'aria-label': 'disabled checkbox' }}
            >
              Disabled
            </Switch>
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              disabled
              checked
              inputProps={{ 'aria-label': 'disabled checked checkbox' }}
            >
              Checked Disabled
            </Switch>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">Label position</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Switch
              light={light}
              size={size}
              labelPosition="left"
              name="three"
              checked={state.three}
              onChange={handleChange}
            >
              Left position
            </Switch>
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              labelPosition="top"
              name="three"
              checked={state.three}
              onChange={handleChange}
            >
              Top position
            </Switch>
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              labelPosition="right"
              name="three"
              checked={state.three}
              onChange={handleChange}
            >
              Right position
            </Switch>
          </Grid>
          <Grid item>
            <Switch
              light={light}
              size={size}
              labelPosition="bottom"
              name="three"
              checked={state.three}
              onChange={handleChange}
            >
              Bottom position
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Switches;

import { gql } from '@apollo/client';

export default gql`
  mutation userForgotPasswordVerify(
    $token: String!
    $type: String!
    $code: String!
  ) {
    userForgotPasswordVerify(token: $token, type: $type, code: $code) {
      stateToken
      status
      user {
        firstName
        lastName
        email
      }
      policy
    }
  }
`;

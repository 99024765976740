import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { NotificationTray, NotificationBadge } from 'components/Notifications';
import Button from 'components/CncUI/Button';
import Typography from 'components/CncUI/Typography';
import notifications from './data/notifications.json';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  root: {
    zIndex: 10,
    boxShadow: palette.cncUI.shadow.container.default,
  },
  mainToolbar: {
    background: palette.cncUI.gradients.header,
    '& .mainToolbarLeft': {
      flexGrow: 1,
    },
  },
  title: {
    color: palette.cncUI.default['+10'],
  },
  active: {
    background: palette.cncUI.default['+10'],
    color: palette.cncUI.default.primary,
  },
  cncButton: {
    color: palette.cncUI.default['+10'],
    '&:hover, &:focus, &:active': {
      color: palette.cncUI.default['+10'],
    },
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const AppBar = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [version, setVersion] = useState([]);

  const handleClick = event => {
    setVersion(event.currentTarget.id === 'empty-list' ? [] : notifications);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? 'simple-popover' : undefined;

  return (
    <MuiAppBar classes={{ root: classes.root }} position="sticky">
      <Toolbar className={classes.mainToolbar}>
        <Grid container spacing={2} alignItems="center" className="mainToolbar">
          <Grid item className="mainToolbarLeft">
            <Typography variant="h4" className={classes.title}>
              Component Showcase
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item className="notificationMenu">
                <Button
                  id="empty-list"
                  className={classes.cncButton}
                  aria-describedby={id}
                  tertiary
                  icon
                  onClick={handleClick}
                >
                  <NotificationBadge notifications={[]} />
                </Button>
              </Grid>
              <Grid item className="notificationMenuUnread">
                <Button
                  id="loaded-list"
                  className={classes.cncButton}
                  aria-describedby={id}
                  tertiary
                  icon
                  onClick={handleClick}
                >
                  <NotificationBadge notifications={notifications} />
                </Button>
                <NotificationTray
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  notifications={version}
                />
              </Grid>
              <Grid item>
                <Button
                  tertiary
                  className={classes.cncButton}
                  href={'/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Back to app
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;

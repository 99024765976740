import { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from 'components/CncUI/Typography';
import Checkbox from 'components/CncUI/Checkbox';
import Button from 'components/CncUI/Button';
import TextField from 'components/CncUI/TextField';
import Dropdown from 'components/CncUI/Dropdown';
import DataTable from 'components/CncUI/DataTable';

const useStyles = makeStyles(({ palette }) => ({
  danger: {
    color: palette.cncUI.alert.red.primary,
  },
}));

const DataTables = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [size, setSize] = useState('normal');
  const [collapsed, setCollapsed] = useState(false);
  const [transparent, setTransparent] = useState(true);

  const columns = [
    {
      field: 'id',
      headerProps: { width: 100, align: 'center' },
      cellProps: { align: 'center' },
    },
    {
      field: 'date',
      label: 'Created At',
      collapsed,
      headerProps: { style: { minWidth: 100 } },
      value: ({ date }) => moment(date).format('MM/DD/YYYY'),
    },
    { field: 'month', collapsed, sortValue: ({ date }) => date.getMonth() },
    {
      field: 'control',
      sortable: false,
      headerProps: { style: { minWidth: 200 } },
      value: () => (
        <TextField short fullWidth placeholder="This is a form control" />
      ),
    },
    {
      field: 'value',
      headerProps: { align: 'right' },
      cellProps: { align: 'right' },
    },
    {
      field: 'actions',
      label: ' ', // hide header
      sortable: false,
      headerProps: { width: 50 },
      cellProps: { align: 'right' },
      value: row => {
        return (
          <Button
            short
            danger
            circle
            onClick={() =>
              setData(old => {
                const arr = old.slice();
                const index = old.findIndex(d => d.id === row.id);
                arr.splice(index, 1);
                return arr;
              })
            }
          >
            <DeleteIcon className={classes.danger} />
          </Button>
        );
      },
    },
  ];

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const random = number => Math.floor(Math.random() * number);
  const addData = number => {
    const results = [...Array(number).fill()].map((_, i) => {
      const date = new Date(2020, random(12), random(28));
      return {
        id: data.length + i + 1,
        date,
        month: months[date.getMonth()],
        value: random(100000),
      };
    });
    setData(data.concat(results));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Data table</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button onClick={() => addData(5)}>Add more records</Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setData([])}>Remove all record</Button>
          </Grid>
          <Grid item>
            <Dropdown
              light
              value={size}
              onChange={e => setSize(e.target.value)}
            >
              <Dropdown.Item value="normal">Normal</Dropdown.Item>
              <Dropdown.Item value="short">Short</Dropdown.Item>
              <Dropdown.Item value="compact">Compact</Dropdown.Item>
              <Dropdown.Item value="tall">Tall</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <Checkbox
              checked={transparent}
              onChange={e => setTransparent(e.target.checked)}
            >
              Transparent
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={collapsed}
              onChange={e => setCollapsed(e.target.checked)}
            >
              Collapsed
            </Checkbox>
          </Grid>
          <Grid item style={{ marginLeft: 'auto', marginRight: 8 }}>
            <Typography italic>Total of data {data.length}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          transparent={transparent}
          size={size}
          columns={columns}
          data={data}
        />
      </Grid>
    </Grid>
  );
};

export default DataTables;

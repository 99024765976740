import { withStyles } from '@mui/styles';
import MuiTooltip from '@mui/material/Tooltip';

const smallTooltip = withStyles(({ palette }) => ({
  tooltip: {
    padding: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    color: palette.cncUI.default['+10'],
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.60)',
  },
}))(MuiTooltip);

const mediumTooltip = withStyles(({ palette }) => ({
  tooltip: {
    fontSize: 14,
    padding: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    color: palette.cncUI.default['+10'],
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.60)',
  },
}))(MuiTooltip);

const largeTooltip = withStyles(({ palette }) => ({
  tooltip: {
    fontSize: 16,
    padding: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    color: palette.cncUI.default['+10'],
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.60)',
  },
}))(MuiTooltip);

const parse = position => {
  const mapping = {
    'top-left': 'top-start',
    'top-center': 'top',
    'top-right': 'top-end',
    'right-top': 'right-start',
    'right-center': 'right',
    'right-bottom': 'right-end',
    'bottom-right': 'bottom-end',
    'bottom-center': 'bottom',
    'bottom-left': 'bottom-start',
    'left-bottom': 'left-end',
    'left-center': 'left',
    'left-top': 'left-start',
  };

  return mapping[position.toLowerCase()] || position.toLowerCase();
};

const Tooltip = props => {
  const {
    children,
    position = 'top',
    width,
    content,
    disabled,
    size = 'small',
    ...other
  } = props;
  const options = {
    placement: parse(position),
    title: content,
    arrow: true,
  };

  if (disabled) {
    return <>{children}</>;
  }

  const Component =
    size === 'large'
      ? largeTooltip
      : size === 'medium'
      ? mediumTooltip
      : smallTooltip;

  if (width) {
    options.styles = { maxWidth: width };
  }

  return (
    <Component {...options} {...other}>
      {children}
    </Component>
  );
};

export default Tooltip;

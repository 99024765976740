import { gql } from '@apollo/client';

export default gql`
  query getProjectAttachment($input: FileDownloadInput!) {
    getProjectAttachment(input: $input) {
      id
      part
      body
      done
    }
  }
`;

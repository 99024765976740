import { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Card from '../Card';
import Radio from '../Radio';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    boxShadow: palette.cncUI.shadow.container.default,
    '&:hover': {
      boxShadow: palette.cncUI.shadow.container.hover,
    },
  },
  title: {
    padding: '7px 20px 8px',
    '& label': {
      width: '100%',
      '& .MuiFormControlLabel-label': {
        marginLeft: 8,
      },
    },
  },
  active: {
    '& .cncui-card-title': {
      background: palette.cncUI.default['-5'],
    },
  },
  disabled: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '& .cncui-card-title': {
      background: palette.cncUI.default['+7'],
    },
    '& .MuiTypography-root.Mui-disabled': {
      color: palette.cncUI.default['+10'],
    },
    '& .MuiButtonBase-root.Mui-disabled .MuiIconButton-label': {
      color: palette.cncUI.default['+10'],
    },
    '& .MuiButtonBase-root.Mui-checked + .MuiTypography-root.Mui-disabled': {
      color: palette.cncUI.default['+10'],
    },
    '& .MuiButtonBase-root.Mui-disabled.Mui-checked .MuiIconButton-label': {
      background: palette.cncUI.default['+7'],
    },
  },
}));

const RadioCard = props => {
  const { onClick = () => {}, action = null } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const radio = useRef();

  const {
    children,
    className,
    label,
    value,
    checked: valueChecked = undefined,
    disabled = false,
    labelProps = {},
    contentProps = {},
    ...other
  } = props;

  useEffect(() => {
    if (radio.current) {
      setChecked(radio.current.checked || false);
    }
  });

  const radioProps = { light: true, size: 'tall' };

  if (valueChecked !== undefined) {
    radioProps.checked = valueChecked;
  }

  if (value) {
    radioProps.value = value;
  }

  if (disabled) {
    radioProps.disabled = disabled;
  }

  const options = {
    className: clsx(
      'cncui-radioCard',
      classes.root,
      checked && classes.active,
      disabled && classes.disabled,
      className,
    ),
  };

  return (
    <Card {...options} {...other} onClick={onClick}>
      <Card.Title
        action={action}
        {...labelProps}
        className={clsx(
          'cncui-radioCard-title',
          classes.title,
          labelProps.className,
        )}
      >
        <Radio {...radioProps} inputRef={radio}>
          {label}
        </Radio>
      </Card.Title>
      <Card.Content {...contentProps}>{children}</Card.Content>
    </Card>
  );
};

RadioCard.Group = Radio.Group;

export default RadioCard;

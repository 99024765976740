export default {
  url: {
    search:
      'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3.ws',
    get:
      'https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws',
  },
  params: {
    KEY: process.env.REACT_APP_LOQATE_KEY,
    Language: 'en',
  },
};

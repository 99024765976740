import { gql } from '@apollo/client';

export default gql`
  query getUserLoanoutAttempts($ssn: String, $email: String) {
    getUserLoanoutAttempts(ssn: $ssn, email: $email) {
      taxId
      valid
      active
      input
      error
      createdAt
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getOffers {
    offers {
      id
      crewStartDate
      currency
      dailyRate
      goToOffer
      hourlyRate
      jobTitle
      projectName
      projectStartDate
      stateW4
      status
      statusDisplay
      statusColor
      type
      viewAllOffers
      weeklyRate
    }
  }
`;

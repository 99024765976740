import url from 'url';

const validHostNames = [
  'castandcrew.com',
  'firstdraftstudio.com',
  'capspayroll.com',
  'castandcrew.my.site.com',
  'castandcrew--uat.sandbox.my.site.com',
];

const validateFromURI = uri => {
  if (!uri) return false;
  const { hostname, protocol } = url.parse(uri) || {};
  if (protocol === 'https:' && validHostNames.some(h => hostname.endsWith(h))) {
    return true;
  }
  return false;
};

export const validateInnerRoute = uri => {
  const { href = '' } = window.location;
  const parts = href.split('/');
  const domain = `${parts[0]}//${parts[2]}`;
  return (uri || '').startsWith(domain);
};

export default validateFromURI;

import React, { useEffect } from 'react';
import Alert from 'components/CncUI/Alert';
import Logger from 'utilities/logger';
const logger = Logger('Chunk.Fail.Fallback');

const setWithExpiry = (key, value, ttl) => {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  logger.info('Setting item: ', item);
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = key => {
  const itemString = window.localStorage.getItem(key);
  logger.info('Got item: ', itemString);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    logger.info('Item Expired');
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

const ChunkFailFallback = ({ error }) => {
  // Handles failed lazy loading of a JS/CSS chunk.
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  const failedToLoadChunk =
    error?.message && chunkFailedMessage.test(error.message);
  useEffect(() => {
    if (failedToLoadChunk) {
      logger.info('Failed to load Chunk, attempting refresh...');
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
      logger.info('Found previous attempt, skipping refresh...');
    }
  }, [error]);

  return (
    <Alert error id="ErrorBoundary-ErrorAlert">
      {failedToLoadChunk &&
        'We found the application was updated but were unable to auto-refresh your browser to correctly get the newest version. Please refresh the page!'}
      {!failedToLoadChunk &&
        'Oops, something went wrong. Please refresh or try again later.'}
    </Alert>
  );
};

export default ChunkFailFallback;

import { gql } from '@apollo/client';

export default gql`
  query getProducers($clientId: Int!, $name: String!, $dbCode: String!) {
    getProducers(clientId: $clientId, name: $name, dbCode: $dbCode) {
      producerId
      producerName
    }
  }
`;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import ModalActions from './ModalActions';
import ModalContent from './ModalContent';
import ModalContentText from './ModalContentText';
import ModalTitle from './ModalTitle';

const useStyles = makeStyles(
  ({ palette, breakpoints }) => ({
    root: {
      '& .MuiDialog-paper': {
        boxShadow: palette.cncUI.shadow.modal,
        borderRadius: 2,
      },
    },
    paper: {
      margin: 20,
      [breakpoints.only('xs')]: {
        margin: 18,
        minWidth: 'auto !important',
      },
    },
  }),
  { name: 'CncUI-Modal' },
);

const Modal = props => {
  const classes = useStyles();
  const {
    children,
    className,
    maxWidth,
    minWidth,
    PaperProps = { style: {} },
    // these props have been deprecated, but used this "middleware"
    // to handle them as in previous spec
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    onClose = () => {},
    ...other
  } = props;
  let newOnClose;

  if (disableBackdropClick || disableEscapeKeyDown) {
    newOnClose = (event, reason) => {
      if (disableBackdropClick && reason === 'backdropClick') return;
      if (disableEscapeKeyDown && reason === 'escapeKeyDown') return;
      onClose(event, reason);
    };
  } else {
    newOnClose = onClose;
  }

  const options = {
    className: clsx('cncui-modal', classes.root, className),
    PaperProps: {
      ...PaperProps,
      className: clsx(classes.paper, PaperProps.className),
    },
    onClose: newOnClose,
  };

  if (maxWidth) {
    options.PaperProps.style.maxWidth = maxWidth;
  }

  if (minWidth) {
    options.PaperProps.style.minWidth = minWidth;
  }

  return (
    <Dialog {...options} {...other}>
      {children}
    </Dialog>
  );
};

Modal.Title = ModalTitle;
Modal.Content = ModalContent;
Modal.ContentText = ModalContentText;
Modal.Actions = ModalActions;

export default Modal;

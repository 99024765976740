import { gql } from '@apollo/client';

export default gql`
  query taxDocumentById($id: String!) {
    getCurrentUser {
      taxDocumentById(id: $id) {
        name
        url: fileUrl
      }
    }
  }
`;

import { useState, useEffect } from "react";
import LanguageContext from "./Context.jsx";
import languages from "language/languages";

const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState("en");
  const [language, setLanguage] = useState(languages[lang]);
  const customString = (str, rStr) => {
    let copied = str.slice();
    if (Array.isArray(rStr)) {
      rStr.forEach((v, i) => {
        copied = copied.replace(`<<var${i + 1}>>`, v);
      });
    } else copied.replace("<<var1>>", rStr);
    return copied;
  };
  const value = { language, setLang, customString };

  useEffect(() => {
    setLanguage(languages[lang]);
  }, [lang]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;

import { createTheme } from '@mui/material';

// Custom Theme
import palette from './palette';
import typography from './typography';

// Setup the default color scheme
palette.cncUI.default = palette.cncUI.primary.royalBlue;
palette.cncUI.button.default = palette.cncUI.button.royalBlue;

const theme = createTheme({
  palette,
  typography,
});

export default theme;

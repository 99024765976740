import { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import SideSheetContent from './SheetContent';
import SideSheetContentText from './SheetContentText';
import SideSheetTitle from './SheetTitle';

const useStyles = makeStyles(
  ({ palette, breakpoints }) => ({
    root: {
      '& .MuiDialog-paper': {
        boxShadow: palette.cncUI.shadow.modal,
        borderRadius: 2,
      },
      '& .MuiDialog-scrollPaper': {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      },
      '& .MuiDialog-paperScrollPaper': {
        maxHeight: 'initial',
      },
    },
    paper: {
      margin: '4px 4px 4px 20px',
      height: 'calc(100vh - 8px)',
      [breakpoints.down('sm')]: {
        margin: '4px 4px 4px 16px',
        minWidth: 'auto !important',
      },
      [breakpoints.up('md')]: {
        width: '50vw',
        maxWidth: '50vw',
      },
      [breakpoints.only('xl')]: {
        width: '40vw',
        maxWidth: '40vw',
      },
    },
  }),
  { name: 'CncUI-SideSheet' },
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SideSheet = props => {
  const classes = useStyles();
  const {
    children,
    className,
    maxWidth,
    minWidth,
    PaperProps = { style: {} },
    // these props have been deprecated, but used this "middleware"
    // to handle them as in previous spec
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    onClose = () => {},
    ...other
  } = props;
  let newOnClose;

  if (disableBackdropClick || disableEscapeKeyDown) {
    newOnClose = (event, reason) => {
      if (disableBackdropClick && reason === 'backdropClick') return;
      if (disableEscapeKeyDown && reason === 'escapeKeyDown') return;
      onClose(event, reason);
    };
  } else {
    newOnClose = onClose;
  }

  const options = {
    className: clsx('cncui-SideSheet', classes.root, className),
    PaperProps: {
      ...PaperProps,
      className: clsx(classes.paper, PaperProps.className),
    },
    onClose: newOnClose,
    TransitionComponent: Transition,
  };

  if (maxWidth) {
    options.PaperProps.style.maxWidth = maxWidth;
  }

  if (minWidth) {
    options.PaperProps.style.minWidth = minWidth;
  }

  return (
    <Dialog {...options} {...other}>
      {children}
    </Dialog>
  );
};

SideSheet.Title = SideSheetTitle;
SideSheet.Content = SideSheetContent;
SideSheet.ContentText = SideSheetContentText;

export default SideSheet;

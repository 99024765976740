import { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import ButtonBase from '@mui/material/ButtonBase';
import CarrotIcon from './icons/Carets';
import CarrotUpIcon from './icons/CaretsUp';
import CarrotDownIcon from './icons/CaretsDown';

const capitalize = name => name.charAt(0).toUpperCase() + name.slice(1);

const useStyles = makeStyles(
  theme => ({
    root: {
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexDirection: 'inherit',
      alignItems: 'center',
      '&:focus': {
        color: theme.palette.cncUI.default.primary,
      },
      '&:hover': {
        color: theme.palette.cncUI.default.primary,
        '& $icon': {
          opacity: 0.5,
        },
      },
      '&$active': {
        color: theme.palette.cncUI.default.primary,
        // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
        '&& $icon': {
          opacity: 1,
          color: theme.palette.cncUI.default.primary,
        },
      },
    },
    active: {},
    icon: {
      fontSize: 18,
      marginRight: 4,
      marginLeft: 4,
      opacity: 0.3,
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: theme.transitions.duration.shorter,
      }),
      userSelect: 'none',
    },
    /* Styles applied to the icon component if `direction="desc"`. */
    iconDirectionDesc: {},
    /* Styles applied to the icon component if `direction="asc"`. */
    iconDirectionAsc: {},
  }),
  { name: 'CncUI-TableSortLabel' },
);

const SortLabel = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    active = false,
    children,
    className,
    direction = 'asc',
    hideSortIcon = false,
    IconComponent = CarrotIcon,
    IconUpComponent = CarrotUpIcon,
    IconDownComponent = CarrotDownIcon,
    ...other
  } = props;

  const options = {
    className: clsx(
      'cncui-table-sort-label',
      classes.root,
      active && classes.active,
      className,
    ),
  };

  const Icon = !active
    ? IconComponent
    : direction === 'asc'
    ? IconUpComponent
    : IconDownComponent;

  return (
    <ButtonBase
      {...options}
      component="span"
      disableRipple
      ref={ref}
      {...other}
    >
      {children}
      {hideSortIcon ? null : (
        <Icon
          className={clsx(
            classes.icon,
            'cncui-table-sort-label-icon',
            active && `cncui-table-sort-label-icon-${direction}`,
            active && classes[`iconDirection${capitalize(direction)}`],
          )}
        />
      )}
    </ButtonBase>
  );
});

export default SortLabel;

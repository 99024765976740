import { gql } from '@apollo/client';

export default gql`
  query getIdentityCheckResults($reportId: String!) {
    getIdentityCheckResults(reportId: $reportId) {
      status
      addresses
      attemptsRemaining
    }
  }
`;

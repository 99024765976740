import { gql } from '@apollo/client';

export default gql`
  query getTaxDocuments {
    getCurrentUser {
      taxDocuments {
        id
        year
        name
        employer
      }
    }
  }
`;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '../Typography';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      background: palette.cncUI.default['+10'],
      boxShadow: `inset 0px -1px 0px ${palette.cncUI.default['+7']}`,
      minHeight: 56,
      '&.Mui-expanded': {
        minHeight: 56,
        '& .MuiAccordionSummary-expandIcon': {
          '& .MuiIconButton-label': {
            background: palette.cncUI.default.primary,
            color: palette.cncUI.default['+10'],
          },
        },
        '& .MuiAccordionSummary-content': {
          '& .cncui-typography': {
            color: palette.cncUI.default.primary,
          },
        },
        '& .cncui-accordion-title-icon': {
          color: palette.cncUI.default.primary,
        },
      },
      '&:hover': {
        background: palette.cncUI.default['+9'],
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
      '& .cncui-accordion-title-icon': {
        marginRight: 12,
        '& .MuiSvgIcon-root': {
          fontSize: 24,
          marginBottom: -2,
        },
      },
      '& .MuiAccordionSummary-expandIcon': {
        '& .MuiIconButton-label': {
          width: 24,
          height: 24,
          borderRadius: '50%',
          background: palette.cncUI.default.table,
          color: palette.cncUI.default.primary,
          '& .MuiSvgIcon-root': {
            fontSize: 24,
          },
        },
      },
    },
    leftExpand: {
      flexDirection: 'row-reverse',
      '& .cncui-accordion-title-wrapper': {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
      },
      '& .cncui-accordion-title-icon': {
        marginLeft: 12,
        marginRight: 0,
      },
      '& .MuiIconButton-edgeEnd': {
        marginLeft: -12,
        marginRight: 0,
      },
    },
  }),
  { name: 'CncUI-AccordionTitle' },
);

const AccordionTitle = props => {
  const classes = useStyles();
  const {
    children,
    className,
    expandedAlign = 'right',
    Icon = null,
    ...other
  } = props;

  const options = {
    className: clsx(
      'cncui-accordion-title',
      `cncui-accordion-title-expanded-${expandedAlign}`,
      classes.root,
      classes[`${expandedAlign}Expand`],
      className,
    ),
    expandIcon: <ExpandMoreIcon />,
  };
  return (
    <AccordionSummary {...options} {...other}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        className="cncui-accordion-title-wrapper"
      >
        {Icon && (
          <Grid item className="cncui-accordion-title-icon">
            {Icon}
          </Grid>
        )}
        <Grid item>
          <Typography variant="body2" size="short">
            {children}
          </Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};

export default AccordionTitle;

import { gql } from '@apollo/client';

export default gql`
  query getPlaidBankAccounts($publicToken: String, $metadata: String) {
    getPlaidBankAccounts(publicToken: $publicToken, metadata: $metadata) {
      subtype
      account
      routing
      ba
      rn
    }
  }
`;

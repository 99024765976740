import { gql } from '@apollo/client';

export default gql`
  query getAuthorizedOktaGroups {
    getAuthorizedOktaGroups {
      id
      name
      alternativeName
      description
      alternativeDesc
    }
  }
`;

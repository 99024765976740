import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import MuiTabs from '@mui/material/Tabs';
import Tab from './Tab';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      '& .MuiTabs-flexContainer': {
        borderBottom: `2px solid ${palette.cncUI.primary.gray['+8']}`,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: palette.cncUI.default.primary,
      },
      '& .MuiTabs-scrollButtons.Mui-disabled': {
        opacity: '0.3',
      },
    },
    contained: {
      '& .MuiTabs-flexContainer': {
        borderBottomColor: 'transparent',
      },
      '& .MuiTabs-indicator': {
        top: 0,
      },
      '& .MuiTab-root': {
        background: palette.cncUI.primary.gray['+8'],
      },
      '& .MuiTab-root:hover': {
        background: palette.cncUI.primary.gray['+7'],
        borderBottomColor: 'transparent',
        borderTopColor: palette.cncUI.default.primary,
      },
      '& .MuiTab-root.Mui-selected': {
        background: palette.cncUI.default['+10'],
      },
    },
  }),
  'CncUI-Tabs',
);

const Tabs = props => {
  const classes = useStyles();
  const { children, className, contained = false, ...other } = props;
  const options = {
    variant: 'scrollable',
    scrollButtons: 'auto',
    className: clsx(
      'cncui-tabs',
      classes.root,
      contained && classes.contained,
      className,
    ),
  };

  return (
    <MuiTabs {...options} {...other}>
      {children}
    </MuiTabs>
  );
};

Tabs.Item = Tab;
Tabs.Tab = Tab;

export default Tabs;

import { gql } from '@apollo/client';

export default gql`
  query getLexisLogs($taxId: String, $companyName: String, $email: String) {
    getLexisLogs(taxId: $taxId, companyName: $companyName, email: $email) {
      id
      taxId
      loginId
      lastFourTaxId
      companyName
      source
      input
      data
      createdAt
      updatedAt
    }
  }
`;

import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MuiTable from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import Button from '../Button';
import ContentBox from '../ContentBox';
import Typography from '../Typography';
import Table, { Row, Cell, HeadCell, Pagination } from '../Table';
import normalizeData from './utils/normalizeData';
import sliceData from './utils/sliceData';
import sortData from './utils/sortData';
import { useMediaQuery } from 'react-responsive';

const capitalize = name => name.charAt(0).toUpperCase() + name.slice(1);

const useStyles = makeStyles(
  ({ palette }) => ({
    empty: {
      '& .cncui-table-cell': {
        textAlign: 'center',
        height: '5em',
        fontStyle: 'italic',
        color: palette.cncUI.default.primary,
      },
    },
    collapsedContent: {
      borderLeft: `4px solid ${palette.cncUI.default.primary}`,
    },
    collapsedContentCell: {
      verticalAlign: 'top',
      borderBottom: 'none',
      height: 'auto !important',
    },
    title: {
      color: palette.cncUI.default.primary,
    },
    zebra: {
      background: palette.cncUI.default.table,
    },
    clickable: {
      cursor: 'pointer',
    },
    displayCol: {
      opacity: 1,
    },
    hideCol: {
      opacity: 0,
    },
    fullWidth: {
      width: '100%',
    },
  }),
  { name: 'CncUI-DataTable' },
);

/*
columnData: {
  field: String
  label: String
  collapsed: Boolean
  cellProps: Object,
  headerProps: Object,
  sortable: Boolean
  value: (row) => { ... }
}
*/

const DataTable = props => {
  const classes = useStyles();
  const {
    className,
    shadow = true,
    transparent = false,
    projectOnBoarding = false,
  } = props;
  const { data = [], columns = [], pagination = true } = props;
  const { sort = '', order = 'asc', onClick = null } = props;
  const { page = 0, pageSize = 15, rowsPerPage = [15, 30, 45, 60] } = props;
  const { emptyRows = 'No records to display', size = 'normal' } = props;
  const { short = false, compact = false, tall = false } = props;
  const { paginationProps = {}, fullWidth = false } = props;
  const { hideOnEmpty = false } = props;
  const isDesktop = useMediaQuery({
    query: '(min-width:1025px)',
  });

  const tableSize = tall
    ? 'tall'
    : short
    ? 'short'
    : compact
    ? 'compact'
    : size;

  const [state, setState] = useState({
    data: normalizeData(data || [], columns),
    sortBy: sort || (columns.find(c => c.sortable) || { field: '' }).field,
    collapsedRowOpen: '',
    sortOrder: order,
    page,
    pageSize,
  });

  useEffect(() => {
    setState(old => ({ ...old, data: normalizeData(data || [], columns) }));
  }, [data]);

  const modify = data => setState(old => ({ ...old, ...data }));
  const collapsedCount = columns.filter(c => c.collapsed).length;
  const isCollapsed = collapsedCount > 0;
  const visibleLength = columns.length - collapsedCount;

  const headers = () =>
    columns
      .filter(column => !column.collapsed)
      .map(column => {
        const display = column.label || capitalize(column.field);
        if (column.sortable || column.sortable === undefined) {
          return (
            <HeadCell
              key={`Header-${column.field}`}
              cell-id={`Header-${column.field}`}
              label={display}
              column={column.field}
              sort={state.sortBy}
              order={state.sortOrder}
              onClick={field =>
                modify({
                  page: 0,
                  sortBy: field,
                  sortOrder: state.sortOrder === 'asc' ? 'desc' : 'asc',
                })
              }
              {...(column.headerProps || {})}
            />
          );
        }
        return (
          <Cell
            key={`Header-${column.field}`}
            cell-id={`Header-${column.field}`}
            {...(column.headerProps || {})}
          >
            {display}
          </Cell>
        );
      });

  const collapsedHeader = () => (
    <Cell key={`Header-COLLAPSED-`} cell-id={`Header-COLLAPSED-`} width={32}>
      {' '}
    </Cell>
  );

  const collapsedContent = (row, column) => {
    const display = column.label || capitalize(column.field);
    const isSortable = column.sortable || column.sortable === undefined;
    const onTitleClick = () =>
      modify({
        page: 0,
        sortBy: column.field,
        sortOrder: state.sortOrder === 'asc' ? 'desc' : 'asc',
        collapsedRowOpen: '',
      });

    return (
      <>
        {(row?.[column?.field]?.value?.[0] !== null || !hideOnEmpty) && (
          <Row key={'collapsed-row'} className={classes.collapsedContent}>
            <Cell className={classes.collapsedContentCell}>
              <Typography
                body1
                medium
                className={clsx(classes.title, isSortable && classes.clickable)}
                onClick={isSortable ? onTitleClick : () => {}}
              >
                {`${display}:`}
              </Typography>
            </Cell>
            <Cell className={classes.collapsedContentCell}>
              <Typography body1 short>
                {row[column.field].value}
              </Typography>
            </Cell>
          </Row>
        )}
      </>
    );
  };

  const rows = sliceData(
    state.page,
    state.pageSize,
    sortData(state.sortBy, state.sortOrder, state.data),
  );

  const onRowClick = row => {
    if (onClick) {
      onClick(row);
    }
  };
  const [activeRow, setActiveRow] = useState(-1);

  const openCollapsedRow = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    modify({ collapsedRowOpen: state.collapsedRowOpen === row ? '' : row });
  };

  return (
    <ContentBox
      dense
      shadow={shadow}
      transparent={transparent}
      className={clsx(
        'cncui-data-table',
        fullWidth && classes.fullWidth,
        className,
      )}
    >
      <Table zebra={!isCollapsed} hover size={tableSize}>
        <Table.Head>
          <Row>
            {isCollapsed && collapsedHeader()}
            {headers()}
          </Row>
        </Table.Head>
        <Table.Body>
          {state.data.length === 0 && emptyRows ? (
            <Row className={clsx('cncui-data-table-empty-rows', classes.empty)}>
              <Cell colSpan={columns.length}>{emptyRows}</Cell>
            </Row>
          ) : null}
          {rows.map((row, i) => (
            <>
              <Row
                key={`Row-${i}`}
                id={`Row-${i}`}
                onClick={() => onRowClick(row)}
                className={clsx(
                  onClick && classes.clickable,
                  isCollapsed && i % 2 === 1 && classes.zebra,
                )}
                onMouseLeave={() => setActiveRow(-1)}
                onMouseEnter={() => setActiveRow(i)}
              >
                {isCollapsed && (
                  <Cell
                    key={`Row-${i}-Cell-collapsedControl-`}
                    cell-id={`Cell-collapsedControl-`}
                    align="center"
                  >
                    <Button
                      short
                      circle
                      aria-label="expand row"
                      tertiary={state.collapsedRowOpen !== `Row-${i}`}
                      onClick={e => openCollapsedRow(e, `Row-${i}`)}
                    >
                      {state.collapsedRowOpen === `Row-${i}` ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Button>
                  </Cell>
                )}
                {columns
                  .filter(column => !column.collapsed)
                  .map(column => (
                    <Cell
                      className={
                        !isDesktop ||
                        !projectOnBoarding ||
                        row[column.field].column.field !== 'index' ||
                        (row[column.field].column.field === 'index' &&
                          activeRow === i)
                          ? classes.displayCol
                          : classes.hideCol
                      }
                      key={`Row-${i}-Cell-${column.field}`}
                      cell-id={`Cell-${column.field}`}
                      {...(column.cellProps || {})}
                    >
                      {row[column.field].value}
                    </Cell>
                  ))}
              </Row>
              {isCollapsed && (
                <TableRow>
                  <Cell
                    style={{ padding: 0, height: 'auto' }}
                    colSpan={visibleLength + 1}
                  >
                    <Collapse
                      in={state.collapsedRowOpen === `Row-${i}`}
                      timeout="auto"
                      unmountOnExit
                    >
                      <ContentBox dense transparent>
                        <MuiTable size="small" style={{ width: 'auto' }}>
                          <Table.Body>
                            {columns
                              .filter(column => column.collapsed)
                              .map(column => collapsedContent(row, column))}
                          </Table.Body>
                        </MuiTable>
                      </ContentBox>
                    </Collapse>
                  </Cell>
                </TableRow>
              )}
            </>
          ))}
        </Table.Body>
      </Table>
      {pagination && (
        <Pagination
          rowsPerPageOptions={rowsPerPage}
          rowsPerPage={state.pageSize}
          component="div"
          count={state.data.length}
          page={state.page}
          onRowsPerPageChange={e => modify({ pageSize: e.target.value })}
          onPageChange={(_, page) => modify({ page })}
          {...paginationProps}
        />
      )}
    </ContentBox>
  );
};

export default DataTable;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const capitalize = name =>
  name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

const DEFAULT_MAPPING = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'p',
  body2: 'p',
  caption: 'p',
  microcaption: 'p',
};

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    color: palette.cncUI.primary.gray['+0'],
    fontStyle: 'normal',
    fontWeight: 400,
    margin: '8px 0',
  },
  headline: {
    color: palette.cncUI.primary.gray['-5'],
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 32,
    lineHeight: '40px',
    [breakpoints.between('sm', 'lg')]: {
      fontSize: 48,
      lineHeight: '56px',
    },
    [breakpoints.up('lg')]: {
      fontSize: 56,
      lineHeight: '70px',
    },
  },
  h2: {
    fontWeight: 300,
  },
  h3: {
    fontWeight: 400,
    [breakpoints.between('xs', 'lg')]: {
      fontSize: 28,
      lineHeight: '36px',
    },
    [breakpoints.up('lg')]: {
      fontSize: 32,
      lineHeight: '40px',
    },
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '26px',
    [breakpoints.between('sm', 'lg')]: {
      fontWeight: 500,
    },
    [breakpoints.up('lg')]: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '26px',
    },
  },
  h5: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
  },
  h6: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.16px',
  },
  hWide: {
    fontSize: 56,
    lineHeight: '70px',
  },
  hMedium: {
    fontSize: 48,
    lineHeight: '56px',
  },
  hNarrow: {
    fontSize: 32,
    lineHeight: '40px',
  },
  h3Wide: {
    fontSize: 32,
    lineHeight: '40px',
  },
  h3Medium: {
    fontSize: 28,
    lineHeight: '36px',
  },
  h3Narrow: {
    fontSize: 28,
    lineHeight: '36px',
  },
  h4Wide: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '26px',
  },
  h4Medium: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '26px',
  },
  h4Narrow: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '26px',
  },
  body1: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.16px',
  },
  body1Medium: {
    fontWeight: 500,
  },
  body1Short: {
    lineHeight: '18px',
  },
  body2: {
    fontSize: 16,
    lineHeight: '24px',
  },
  body2Medium: {
    fontWeight: 500,
  },
  body2Short: {
    lineHeight: '22px',
  },
  caption: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.32px',
  },
  microcaption: {
    fontSize: 10,
    lineHeight: '14px',
    letterSpacing: '0.37px',
  },
  italic: {
    fontStyle: 'italic',
  },
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 'bold',
  },
  noMargin: {
    margin: 0,
  },
  noMarginTop: {
    marginTop: 0,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  white: {
    color: palette.cncUI.default['+10'],
  },
  success: {
    color: palette.cncUI.alert.green.primary,
  },
  warning: {
    color: palette.cncUI.alert.yellow.primary,
  },
  error: {
    color: palette.cncUI.alert.red.primary,
  },
}));

const Typography = props => {
  const classes = useStyles();
  const {
    children,
    className,
    variant = '',
    size = '',
    bold = false,
    medium = false,
    italic = false,
    noMargin = false,
    noMarginBottom = false,
    noMarginTop = false,
    mapping = {},
    component = null,
    align = null,
    white = null,
    success = null,
    warning = null,
    error = null,
    body1 = false,
    body2 = false,
    h1 = false,
    h2 = false,
    h3 = false,
    h4 = false,
    h5 = false,
    h6 = false,
    caption = false,
    microcaption = false,
    short = false,
    long = false,
    center = false,
    left = false,
    right = false,
    ...other
  } = props;

  const Variant = variant
    ? variant
    : body1
    ? 'body1'
    : body2
    ? 'body2'
    : h1
    ? 'h1'
    : h2
    ? 'h2'
    : h3
    ? 'h3'
    : h4
    ? 'h4'
    : h5
    ? 'h5'
    : h6
    ? 'h6'
    : caption
    ? 'caption'
    : microcaption
    ? 'microcaption'
    : 'body1';

  const Size = size ? size : short ? 'short' : long ? 'long' : 'short';
  const Align = align
    ? align
    : center
    ? 'center'
    : left
    ? 'left'
    : right
    ? 'right'
    : null;

  const Tag =
    component ||
    { ...DEFAULT_MAPPING, ...mapping }[(Variant || 'body1').toLowerCase()] ||
    'span';

  const el = Variant.toLowerCase();
  const isBody = el.startsWith('b');
  const isHeadline = el.startsWith('h');

  const options = {
    className: clsx(
      'cncui-typography',
      `cncui-typography-${el}`,
      classes.root,
      isHeadline && classes.headline,
      classes[el],
      ['h1', 'h2'].includes(el) && Size && classes[`h${capitalize(Size)}`],
      classes[`${el}${capitalize(Size)}`],
      isBody && Size && classes[`${el}${capitalize(Size)}`],
      italic && classes.italic,
      medium && classes.medium,
      bold && classes.bold,
      noMargin && classes.noMargin,
      noMarginTop && classes.noMarginTop,
      noMarginBottom && classes.noMarginBottom,
      Align && classes[Align],
      white && classes.white,
      success && classes.success,
      warning && classes.warning,
      error && classes.error,
      className,
    ),
  };

  return (
    <Tag {...options} {...other}>
      {children}
    </Tag>
  );
};

export default Typography;

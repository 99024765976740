const cncFlagsURLs = {
  local: 'https://cncflags.dev.aws.castandcrew.com/graphql',
  qa: 'https://cncflags.dev.aws.castandcrew.com/graphql',
  uat: 'https://cncflags.dev.aws.castandcrew.com/graphql',
  aut: 'https://cncflags.dev.aws.castandcrew.com/graphql',
  demo: 'https://cncflags.dev.aws.castandcrew.com/graphql',
  extuat: 'https://cncflags.dev.aws.castandcrew.com/graphql',
  prod: 'https://cncflags.castandcrew.com/graphql',
};

const getServerURL = () => {
  return cncFlagsURLs[process.env.REACT_APP_ENVIRONMENT || 'qa'];
};

module.exports = getServerURL;

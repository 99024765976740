import { gql } from '@apollo/client';

export default gql`
  mutation validateUserForgotPassword($email: String!) {
    validateUserForgotPassword(email: $email) {
      id
      type
      status
      provider
      vendorName
      embedded
    }
  }
`;

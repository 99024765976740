import { gql } from '@apollo/client';

export default gql`
  query getProjectsBy($status: String!, $filters: String) {
    getProjectsBy(status: $status, filters: $filters) {
      id
      name
      loginId
      status
      values
      config
      createdAt
      updatedAt
    }
  }
`;

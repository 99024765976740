import { gql } from '@apollo/client';

export default gql`
  query getUser {
    user {
      myTasksIgnore
      email
      hasDirectDeposits
      loanout {
        createdAt
        dbCode
        from
        isValid
        name
        uid
      }
      directDeposits {
        id
        bankName
        routingNumber
        bankAccountNumber
        bankAccountType
        flatAmount
        percentRate
        splitPercentage
        sequence
        splitType
        forCorps
        from
        dataSource
        isPaperLess
        isDeliveryRequested
        isDirectDeposit
      }
      uid
      w4Documents {
        id
        uid
        documentId
        name
        state
        status
        country
        createdAt
        isValid
      }
      address {
        uid
        from
        addressLine1
        addressLine2
        addressLine3
        city
        state
        zipCode
        country
        date
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getAccountancyFirms($dbCode: String!) {
    getAccountancyFirms(dbCode: $dbCode) {
      value
      description
    }
  }
`;

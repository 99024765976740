import { gql } from '@apollo/client';

export default gql`
  query getRegistrationHistory($email: String, $ssn: String) {
    getRegistrationHistory(email: $email, ssn: $ssn) {
      loginId
      email
      path
      message
      data
      createdAt
    }
  }
`;

import { useState, useEffect } from 'react';
import CountryContext from './Context';
import useAuthentication from 'hooks/useAuthentication';
import useQuery from 'hooks/useQuery';
import Loader from 'components/Loader';
import Logger from 'utilities/logger';
import FastCache from 'utilities/fastCache';

const Provider = props => {
  const { children } = props;
  const { login, user } = useAuthentication();
  const cache = FastCache(`ProfileCountries_${user?.uid}`);
  const cached = cache.get();
  const [loading, setLoading] = useState(login && !cached);
  const [countries, setCountries] = useState(cached?.countries || ['US']);
  const [country, setCountry] = useState(cached?.country || 'US');
  const getCountries = useQuery('getProfileCountries', {
    response: res => res.data.getProfileCountries || ['US'],
  });
  const logger = Logger('country provider');

  useEffect(() => {
    logger.debug('Cached Data', { cached });
    if (login) {
      getCountries()
        .then(result => {
          const toCache = { countries: result, country: 'US' };
          if (
            Array.isArray(result) &&
            result.length === 1 &&
            result[0] === 'CAN'
          ) {
            setCountry('CAN');
            toCache.country = 'CAN';
          }
          logger.debug(result);
          setCountries(result);
          cache.set(toCache);
          setLoading(false);
        })
        .catch(err => {
          logger.error(err);
          setLoading(false);
        });
    }
  }, [login]);

  const value = {
    enabled: true,
    loading: loading,
    country,
    countries,
    setCountry,
  };

  if (loading) {
    return <Loader fullScreen withLogo />;
  }

  return (
    <CountryContext.Provider value={value}>{children}</CountryContext.Provider>
  );
};

export default Provider;

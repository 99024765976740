import { gql } from '@apollo/client';

export default gql`
  query getUserTaxDocument($ssn: String, $email: String, $id: String!) {
    getUser(ssn: $ssn, email: $email) {
      taxDocumentById(id: $id) {
        name
        url: fileUrl
      }
    }
  }
`;

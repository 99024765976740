import { gql } from '@apollo/client';

export default gql`
  query getUserReissuePaystubs {
    getCurrentUser {
      reissuePaystubs {
        id
        dataSource
        checkSequence
        reason
        ticket
        createdAt
      }
    }
  }
`;

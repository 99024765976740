import { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import MuiChip from '@mui/material/Chip';
import ChipGroup from './ChipGroup';
import HoverGroup from './HoverGroup';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      color: palette.cncUI.default['+10'],
      backgroundColor: palette.cncUI.button.default.primary,
      border: `1px solid ${palette.cncUI.button.default.primary}`,
      marginRight: 8,
      marginBottom: 8,
      justifyContent: 'flex-start',
      '& .MuiCircularProgress-root': {
        color: palette.cncUI.default['+10'],
      },
      '&:hover': {
        backgroundColor: palette.cncUI.button.default.hover,
        border: `1px solid ${palette.cncUI.button.default.hover}`,
      },
      '&:focus': {
        backgroundColor: palette.cncUI.button.default.focus,
        border: `1px solid ${palette.cncUI.button.default.focus}`,
      },
      '&:active': {
        backgroundColor: palette.cncUI.button.default.active,
        border: `1px solid ${palette.cncUI.button.default.active}`,
      },
      '& .MuiChip-icon, .MuiChip-deleteIcon': {
        display: 'none',
        color: palette.cncUI.default['+10'],
        fontSize: 18,
      },
    },
    multi: {
      backgroundColor: palette.cncUI.default['+10'],
      color: palette.cncUI.button.default.primary,
      border: `1px solid ${palette.cncUI.button.default.primary}`,
      '& .MuiCircularProgress-root': {
        color: palette.cncUI.button.default.primary,
      },
      '&:focus': {
        backgroundColor: palette.cncUI.primary.royalBlue['+8'],
        border: `1px solid ${palette.cncUI.button.default.focus}`,
        color: palette.cncUI.primary.gray['-5'],
      },
      '&:active': {
        backgroundColor: palette.cncUI.primary.royalBlue['+7'],
        border: `1px solid ${palette.cncUI.button.default.active}`,
        color: palette.cncUI.primary.gray['-5'],
      },
    },
    disabled: {
      color: `${palette.cncUI.primary.gray['+7']} !important`,
      border: `1px solid ${palette.cncUI.primary.gray['+6']}`,
      backgroundColor: palette.cncUI.primary.royalBlue['-7'],
      boxShadow: '0px 0px 1px rgba(82, 82, 82, 0.4)',
      '& .MuiCircularProgress-root': {
        color: `${palette.cncUI.primary.gray['+7']} !important`,
      },
    },
    multiDisabled: {
      color: `${palette.cncUI.primary.royalBlue['-7']} !important`,
      border: `1px solid ${palette.cncUI.primary.royalBlue['-7']}`,
      backgroundColor: palette.cncUI.default['+10'],
      boxShadow: '0px 0px 1px rgba(82, 82, 82, 0.4)',
      '& .MuiCircularProgress-root': {
        color: `${palette.cncUI.primary.gray['+7']} !important`,
      },
    },
    selected: {
      color: palette.cncUI.default['+10'],
      '&:focus': {
        color: palette.cncUI.default['+10'],
      },
      '&:active': {
        color: palette.cncUI.default['+10'],
      },
      backgroundImage: 'linear-gradient(180deg, #1A55FD 0%, #000080 100%)',
      '& .MuiChip-icon': {
        display: 'block',
      },
      '& .MuiChip-deleteIcon': {
        display: 'block',
        height: 18,
        width: 18,
      },
    },
    disableSelectedValue: {
      backgroundImage: 'none',
    },
    dense: {
      padding: 0,
    },
    shadow: {
      boxShadow: palette.cncUI.shadow.container.default,
    },
    transparent: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  }),
  { name: 'CncUI-ContentBox' },
);

const Chip = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    children,
    className,
    selected = false,
    transparent = false,
    startIcon = null,
    endIcon = null,
    disabled = false,
    onClick,
    type = 'single',
    disableSelectedValue = false,
    ...other
  } = props;
  const options = {
    className: clsx(
      classes.root,
      type !== 'single' && classes.multi,
      transparent && classes.transparent,
      disabled &&
        (type === 'single' ? classes.disabled : classes.multiDisabled),
      selected && classes.selected,
      disableSelectedValue && classes.disableSelectedValue,
      className,
    ),
  };

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div>
      <MuiChip
        {...options}
        {...other}
        onClick={handleClick}
        icon={!startIcon && !endIcon ? <CheckIcon /> : startIcon}
        deleteIcon={endIcon}
        onDelete={endIcon ? handleClick : null}
        disabled={disabled}
        ref={ref}
      />
    </div>
  );
});

Chip.Group = ChipGroup;
Chip.HoverGroup = HoverGroup;

export default Chip;

import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import ContentBox from 'components/CncUI/ContentBox';
import Typography from 'components/CncUI/Typography';

const useStyles = makeStyles(() => ({
  block: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Breakpoints = () => {
  const classes = useStyles();
  const theme = useTheme();
  const is = {
    xs: useMediaQuery(theme.breakpoints.only('xs')),
    sm: useMediaQuery(theme.breakpoints.only('sm')),
    md: useMediaQuery(theme.breakpoints.only('md')),
    lg: useMediaQuery(theme.breakpoints.only('lg')),
    xl: useMediaQuery(theme.breakpoints.only('xl')),
  };
  const breakpoint = Object.keys(is).find(bp => is[bp]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Breakpoints</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-around">
          {[1, 2, 3, 4, 5, 6].map(key => (
            <Grid item xs={12} sm={6} md={4} lg={2} xl={1} key={key}>
              <ContentBox className={classes.block}>
                <Typography variant="h1">{breakpoint}</Typography>
              </ContentBox>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Breakpoints;

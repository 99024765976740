import { gql } from '@apollo/client';

export default gql`
  query getTrustAccount($minorId: String) {
    getTrustAccount(minorId: $minorId) {
      id
      minorId
      loginId
      lastFourSsn
      lastFourRouting
      lastFourAccount
      data
    }
  }
`;

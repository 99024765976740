const graphQLURLs = {
  local: 'http://mycnc.castandcrew.com:3001',
  qa:
    'https://jjdstnjwch-vpce-063282340477e592a.execute-api.us-west-2.amazonaws.com/qa',
  uat:
    'https://0mdeqcchvd-vpce-01430eb8586b4e56c.execute-api.us-west-2.amazonaws.com/uat',
  aut:
    'https://tl0ubpow19-vpce-01430eb8586b4e56c.execute-api.us-west-2.amazonaws.com/aut',
  demo: 'https://sso-demo.prod.aws.castandcrew.com',
  extuat: 'https://sso-ext-uat.prod.aws.castandcrew.com',
  prod: 'https://sso-prd.prod.aws.castandcrew.com',
};

const getServerURL = () => {
  if (window.Cypress) {
    return 'https://sso-dev.dev.aws.castandcrew.com';
  }
  return graphQLURLs[process.env.REACT_APP_ENVIRONMENT || 'qa'];
};

module.exports = getServerURL;

import { gql } from '@apollo/client';

export default gql`
  query getProfileCompleted {
    getCurrentUser {
      profileCompleted
      capsProfileCompleted
      canProfileCompleted
    }
  }
`;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import DialogContent from '@mui/material/DialogContent';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '8px 16px',
    },
  }),
  { name: 'CncUI-ModalContent' },
);

const ModalContent = props => {
  const classes = useStyles();
  const { children, className, ...other } = props;
  const options = {
    className: clsx('cncui-modal-content', classes.root, className),
  };

  return (
    <DialogContent {...options} {...other}>
      {children}
    </DialogContent>
  );
};

export default ModalContent;

import { gql } from '@apollo/client';

export default gql`
  query getPayStubs($year: String) {
    paystubs(year: $year) {
      employeeName
      checkSequence
      source
      projectNames
      jobDescriptions
      weekStartingDate
      weekEndingDate
      weekStartDate
      weekEndDate
      payPeriodStart
      payPeriodEnd
      checkPayPeriodOverride
      grossEarnings
      netEarnings
      hoursWorked
      currency
      year
    }
  }
`;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Spinner from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/HighlightOff';
import MuiErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: palette.cncUI.primary.gray['+9'],
      color: palette.cncUI.primary.gray['+0'],
      borderRadius: '2px 2px 0 0',
      padding: '4px 8px',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
      '& .MuiInputBase-input::placeholder': {
        color: palette.cncUI.primary.gray['+6'],
        fontStyle: 'italic',
        opacity: 1,
      },
    },
    '& .MuiInputLabel-formControl': {
      color: palette.cncUI.primary.gray['+0'],
      transform: 'scale(1)',
      marginTop: -6,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: '0.16px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${palette.cncUI.primary.gray['+3']}`,
    },
    '&:hover .MuiInput-underline:before': {
      borderBottom: `2px solid ${palette.cncUI.primary.gray['+3']}`,
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottom: `2px solid ${palette.cncUI.default.primary}`,
    },
    '& .MuiInputLabel-formControl.Mui-focused': {
      color: palette.cncUI.default.primary,
    },
  },
  light: {
    '& .MuiInputBase-root': {
      backgroundColor: palette.cncUI.default['+10'],
    },
    '& .MuiCircularProgress-root': {
      color: `${palette.cncUI.primary.gray['+0']} !important`,
    },
  },
  short: {
    '& .MuiInputBase-root': {
      fontSize: 14,
      lineHeight: '18px',
      height: 25,
      '& .MuiInputAdornment-root .MuiSvgIcon-root': {
        fontSize: 20,
      },
    },
  },
  shortMultiline: {
    '& .MuiInputBase-root': {
      height: 'auto',
    },
  },
  disabled: {
    '& .MuiInputBase-root': {
      color: palette.cncUI.primary.gray['+7'],
      '& .MuiInputBase-input::placeholder': {
        color: palette.cncUI.primary.gray['+7'],
      },
    },
    '& .MuiInputLabel-formControl': {
      color: palette.cncUI.primary.gray['+7'],
    },
    '& .MuiInput-underline:before': {
      borderBottom: `2px solid ${palette.cncUI.primary.gray['+7']}`,
    },
    '&:hover .MuiInput-underline:before': {
      borderBottom: `2px solid ${palette.cncUI.primary.gray['+7']}`,
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottom: `2px solid ${palette.cncUI.primary.gray['+7']}`,
    },
    '& .MuiInputLabel-formControl.Mui-focused': {
      color: palette.cncUI.primary.gray['+7'],
    },
    '& .MuiInput-input.Mui-disabled': {
      '-webkit-text-fill-color': `${palette.cncUI.primary.gray['+7']}`,
      opacity: 1,
    },
  },
  multiline: {
    '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
      width: 16,
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        top: 8,
        right: 8,
      },
    },
  },
  error: {
    '& .MuiInputLabel-formControl': {
      color: palette.cncUI.alert.red.primary,
    },
    '& .MuiInput-underline:before': {
      borderBottom: `2px solid ${palette.cncUI.alert.red.primary}`,
    },
    '&:hover .MuiInput-underline:before': {
      borderBottom: `2px solid ${palette.cncUI.alert.red.primary}`,
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottom: `2px solid ${palette.cncUI.alert.red.primary}`,
    },
    '& .MuiInputLabel-formControl.Mui-focused': {
      color: palette.cncUI.alert.red.primary,
    },
    '& .MuiFormHelperText-root': {
      color: palette.cncUI.alert.red.primary,
    },
  },
  warning: {
    '& .MuiInputLabel-formControl': {
      color: palette.cncUI.alert.yellow.primary,
    },
    '& .MuiInput-underline:before': {
      borderBottom: `2px solid ${palette.cncUI.alert.yellow.primary}`,
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: palette.cncUI.alert.yellow.primary,
    },
    '&:hover .MuiInput-underline:before': {
      borderBottom: `2px solid ${palette.cncUI.alert.yellow.primary}`,
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottom: `2px solid ${palette.cncUI.alert.yellow.primary}`,
    },
    '& .MuiInputLabel-formControl.Mui-focused': {
      color: palette.cncUI.alert.yellow.primary,
    },
    '& .MuiFormHelperText-root': {
      color: palette.cncUI.alert.yellow['-1'],
    },
  },
  errorIcon: {
    color: palette.cncUI.alert.red.primary,
    width: 16,
    height: 16,
    marginBottom: 2,
  },
  warningIcon: {
    color: palette.cncUI.alert.yellow.primary,
    width: 16,
    height: 16,
    marginBottom: 2,
  },
  icon: {
    color: palette.cncUI.primary.gray['+0'],
    '& svg': {
      width: 20,
      height: 20,
      marginBottom: 2,
    },
  },
  spinner: {
    width: '15px !important',
    height: '15px !important',
    '& svg': {
      color: palette.cncUI.default.primary,
    },
  },
  clear: {
    color: palette.cncUI.primary.gray['+2'],
    cursor: 'pointer',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  transparent: {
    '& .MuiInputBase-root': {
      backgroundColor: 'inherit',
    },
  },
}));

const TextField = props => {
  const classes = useStyles();
  const {
    className,
    startIcon = null,
    endIcon = null,
    ErrorIcon = MuiErrorIcon,
    ignoreErrorIcon = false,
    light = false,
    short = false,
    error = false,
    warning = false,
    multiline = false,
    disabled = false,
    loading = false,
    transparent = false,
    onEnter,
    onClear,
    InputProps,
    ...other
  } = props;

  const enterKey = e => {
    const { charCode, keyCode } = e || {};
    // Enter = key #13
    if (charCode === 13 || keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      onEnter(e);
    }
  };

  const options = {
    variant: 'standard',
    className: clsx(
      'cncui-textfield',
      classes.root,
      light && classes.light,
      short && classes.short,
      error && classes.error,
      warning && classes.warning,
      disabled && classes.disabled,
      multiline && classes.multiline,
      transparent && classes.transparent,
      multiline && short && classes.shortMultiline,
      className,
    ),
    InputProps,
    InputLabelProps: { shrink: true },
    error: error || warning,
    multiline,
    disabled,
  };

  if (typeof onEnter === 'function') {
    options.onKeyDown = enterKey;
  }

  if (startIcon) {
    if (!options.InputProps) options.InputProps = {};
    options.InputProps.startAdornment = (
      <InputAdornment position="start" className={classes.icon}>
        {startIcon}
      </InputAdornment>
    );
  }

  if (endIcon && !loading) {
    if (!options.InputProps) options.InputProps = {};
    options.InputProps.endAdornment = (
      <InputAdornment position="end" className={classes.icon}>
        {endIcon}
      </InputAdornment>
    );
  }

  if ((error || warning) && ErrorIcon && !loading && !ignoreErrorIcon) {
    if (!options.InputProps) options.InputProps = {};
    options.InputProps.endAdornment = (
      <InputAdornment position="end">
        <ErrorIcon
          className={error ? classes.errorIcon : classes.warningIcon}
        />
      </InputAdornment>
    );
  }

  if (typeof onClear === 'function') {
    if (!options.InputProps) options.InputProps = {};
    options.InputProps.endAdornment = (
      <InputAdornment position="end" className={classes.clear}>
        <ClearIcon onClick={onClear} />
      </InputAdornment>
    );
  }

  if (loading) {
    if (!options.InputProps) options.InputProps = {};
    options.InputProps.endAdornment = (
      <InputAdornment position="end">
        <Spinner className={classes.spinner} />
      </InputAdornment>
    );
  }

  return <MuiTextField {...options} {...other} />;
};

export default TextField;

import { gql } from '@apollo/client';

export default gql`
  query getUserBankAccounts($id: String) {
    getCurrentUser {
      bankAccounts(id: $id) {
        status
        currency
        accounts {
          subtype
          account
          routing
          ba
          rn
        }
      }
    }
  }
`;

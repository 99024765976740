import { gql } from '@apollo/client';

export default gql`
  query getLoanoutApprovals($filter: String, $country: String) {
    getLoanoutApprovals(filter: $filter, country: $country) {
      id
      type
      taxId
      loginId
      status
      data
      createdAt
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getExistingLoanouts($country: String) {
    getExistingLoanouts(country: $country) {
      dataSource
      dbCode
      taxId
      name
      type
      address
      city
      state
      zipCode
      createdAt
      isValid
      remarks
      gstHst
    }
  }
`;

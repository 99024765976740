import { gql } from '@apollo/client';

export default gql`
  query getMinorTaxDocument($id: String!, $documentId: String!) {
    getCurrentUser {
      minor(id: $id) {
        taxDocument(id: $documentId) {
          name
          file
          url: fileUrl
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getMinorW4Locks($id: String!) {
    getCurrentUser {
      minor(id: $id) {
        w4Locks {
          payroll
          caps
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getProject($id: String!, $lastSubmit: Boolean) {
    getProject(id: $id, lastSubmit: $lastSubmit) {
      id
      name
      loginId
      status
      values
      file
      config
      createdAt
      updatedAt
      updatedControl
      permissions
    }
  }
`;

import moment from 'moment';
import Logger from 'utilities/logger';

const Vault = (function() {
  const VAULT = {};
  const PENDING = {};
  const serialize = value => JSON.stringify(value);

  return {
    get(key) {
      return VAULT[serialize(key)];
    },
    set(key, value) {
      return (VAULT[serialize(key)] = value);
    },
    remove(key) {
      return delete VAULT[serialize(key)];
    },
    clear() {
      Object.keys(VAULT).forEach(key => delete VAULT[key]);
      Object.keys(PENDING).forEach(key => delete PENDING[key]);
    },
    pending(key) {
      PENDING[serialize(key)] = true;
    },
    isPending(key) {
      return PENDING[serialize(key)] || false;
    },
    removePending(key) {
      delete PENDING[serialize(key)];
    },
    waitPending(key) {
      return new Promise(resolve => {
        const interval = setInterval(() => {
          if (!PENDING[serialize(key)]) {
            clearInterval(interval);
            resolve(VAULT[serialize(key)]);
          }
        }, 100);
      });
    },
  };
})();

const get = key => {
  const logger = Logger('Token Manager');
  const token = Vault.get(key);
  if (token) {
    if (moment().isAfter(moment.unix(token.ttl))) {
      logger.debug(`Expired ${key}`, {
        key,
        now: moment().format(),
        expiresAt: moment.unix(token.ttl).format(),
      });
      Vault.remove(key);
      return null;
    }
    return token.value;
  }
  return null;
};

const set = (key, value, ttl) => {
  const expires = moment.unix(ttl).format();
  return Vault.set(key, { ttl, expires, value });
};

const clear = () => {
  Vault.clear();
};

const request = async (auth, scope) => {
  const logger = Logger('Token Manager');

  if (Array.isArray(scope) && scope.length === 0) {
    logger.warn('Cannot request empty');
    return null;
  }

  if (scope === undefined) {
    logger.warn('Cannot request undefined');
    return null;
  }

  if (scope === null) {
    logger.warn('Cannot request null');
    return null;
  }

  logger.debug(`Request ${scope}`);
  if (Vault.isPending(scope)) {
    logger.debug(`Pending ${scope}`);
    const waitedToken = await Vault.waitPending(scope);
    logger.debug(`Resolved ${scope}`, waitedToken);
    return waitedToken?.value || null;
  }

  const fromMemory = get(scope);
  if (fromMemory) {
    logger.debug(`From memory ${scope}`);
    return fromMemory;
  } else {
    logger.debug(`Not in memory ${scope}`);
    Vault.pending(scope);
  }

  try {
    const token = await auth.accessToken(scope);
    logger.debug(`Response ${scope}`, token);
    const { expiresAt } = token;
    set(scope, token.accessToken, expiresAt);
    Vault.removePending(scope);
    logger.debug(`State ${scope}`, {
      scope,
      expires: moment.unix(expiresAt).format(),
    });
    return token.accessToken;
  } catch (err) {
    Vault.removePending(scope);
    logger.debug(`Error on ${scope}`);
    logger.error(err);
    return null;
  }
};

export default { get, clear, request };

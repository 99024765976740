import { gql } from '@apollo/client';

export default gql`
  mutation createMinorW4Document(
    $id: String!
    $stateId: String
    $country: String
  ) {
    createMinorW4Document(id: $id, stateId: $stateId, country: $country) {
      id
    }
  }
`;

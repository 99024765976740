import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from 'components/CncUI/Checkbox';
import DateTimePicker from 'components/CncUI/DateTimePicker';

const TimePickers = () => {
  const [date, setDate] = useState(new Date());
  const [light, setLight] = useState(true);
  const [short, setShort] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">DateTime Pickers</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={short}
              onChange={e => setShort(e.target.checked)}
            >
              Short size
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={light}
              onChange={e => setLight(e.target.checked)}
            >
              Light fields
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <DateTimePicker
              light={light}
              short={short}
              value={date}
              onChange={setDate}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimePickers;

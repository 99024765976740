import { gql } from '@apollo/client';

export default gql`
  query getMyW4s($country: String) {
    getCurrentUser {
      id
      w4Documents(country: $country) {
        id
        documentId
        name
        createdAt
        stateId
        lastLevelHistoryProcessed
      }
    }
  }
`;

import { useRef } from 'react';
import ScrollContext from './Context';

const ScrollProvider = ({ children }) => {
  const containerRef = useRef(null);

  const ScrollToTop = () => {
    if (containerRef?.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };
  const value = { containerRef, ScrollToTop };

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
};

export default ScrollProvider;

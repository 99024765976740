import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiCard from '@mui/material/Card';
import CardTitle from './CardTitle';
import CardContent from './CardContent';
import CardActions from './CardActions';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      boxShadow: palette.cncUI.shadow.container.default,
      backgroundColor: palette.cncUI.default.table,
      borderRadius: 8,
    },
    white: {
      backgroundColor: palette.cncUI.default['+10'],
    },
  }),
  { name: 'CncUI-Card' },
);

const Card = props => {
  const classes = useStyles();
  const { children, className, white, ...other } = props;
  const options = {
    className: clsx(
      'cncui-card',
      classes.root,
      white && classes.white,
      className,
    ),
  };
  return (
    <MuiCard {...options} {...other}>
      {children}
    </MuiCard>
  );
};

Card.Title = CardTitle;
Card.Content = CardContent;
Card.Actions = CardActions;

export default Card;

import { gql } from '@apollo/client';

export default gql`
  query getProjectHistory($projectId: String!) {
    getProjectHistory(projectId: $projectId) {
      id
      projectId
      loginId
      user
      update
      createdAt
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getLoanout($country: String) {
    getLoanout(country: $country) {
      taxId
      name
      type
      address
      city
      state
      zipCode
      gstHst
      w9
      loi
      aoi
      verification
      createdAt
      isValid
      status
      remarks
      isPrimaryAsSecondary
    }
  }
`;

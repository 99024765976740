import { gql } from '@apollo/client';

export default gql`
  query getProfileContactTypes {
    getProfileMappingOptions {
      contactType {
        label
        value
      }
    }
  }
`;

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from 'components/CncUI/Checkbox';
import Button from 'components/CncUI/Button';
import Dropdown from 'components/CncUI/Dropdown';
import Snackbar from 'components/CncUI/Snackbar';

const Snackbars = () => {
  const [state, setState] = useState({
    position: 'top-left',
    action: false,
    title: false,
    type: 'info',
    show: false,
  });
  const update = data => setState(old => ({ ...old, ...data }));
  const close = () => update({ show: false });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Snackbars</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Dropdown
              light
              label="Position"
              value={state.position}
              onChange={e => update({ position: e.target.value })}
            >
              <Dropdown.Item value="top-left">Top Left</Dropdown.Item>
              <Dropdown.Item value="top-center">Top Center</Dropdown.Item>
              <Dropdown.Item value="top-right">Top Right</Dropdown.Item>
              <Dropdown.Item value="bottom-left">Bottom Left</Dropdown.Item>
              <Dropdown.Item value="bottom-center">Bottom Center</Dropdown.Item>
              <Dropdown.Item value="bottom-right">Bottom Right</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <Dropdown
              light
              label="Type"
              value={state.type}
              onChange={e => update({ type: e.target.value })}
            >
              <Dropdown.Item value="info">Informational</Dropdown.Item>
              <Dropdown.Item value="success">Success</Dropdown.Item>
              <Dropdown.Item value="warning">Warning</Dropdown.Item>
              <Dropdown.Item value="error">Error</Dropdown.Item>
              <Dropdown.Item value="toast">Toast</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <Checkbox
              checked={state.title}
              onChange={e => update({ title: e.target.checked })}
            >
              With title
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={state.action}
              onChange={e => update({ action: e.target.checked })}
            >
              With action
            </Checkbox>
          </Grid>
          <Grid item>
            <Button size="large" onClick={() => update({ show: true })}>
              Test Snackbar
            </Button>
            <Snackbar
              open={state.show}
              position={state.position}
              hideDuration={6000}
              variant={state.type}
              onClose={close}
              title={state.title && 'Notification title'}
              action={
                state.action && (
                  <Snackbar.Action onClick={close}>Close</Snackbar.Action>
                )
              }
            >
              Subtitle text goes here.
              {state.type === 'toast' && " Toast type won't display actions"}
            </Snackbar>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Snackbars;

const isProd = () => process.env.REACT_APP_ENVIRONMENT === 'prod';
const devClientId = '0oail3tmV0Phe7Ktr355';
const prodClientId = '0oa1b383d8XvfKYp0356';
export const getClientId = () => (isProd() ? prodClientId : devClientId);
export const getClientIdStartPlus = () =>
  isProd() ? '0oa88y7e9JjCjaHcO356' : '0oa7165rkO5R5zu2F356';
export const getPermissionClientId = () =>
  isProd() ? prodClientId : devClientId;
export const getBaseUrl = () =>
  isProd()
    ? 'https://login.castandcrew.com'
    : 'https://login.dev.aws.castandcrew.com';
export const getStartPlusBaseUrl = () =>
  isProd()
    ? 'https://login.castandcrew.com'
    : 'https://login.dev.aws.castandcrew.com';
export const getIssuerId = () =>
  isProd() ? 'aus77kwrwSbSunX5s356' : 'aus67ubcqBSG33G9h356';
export const getIssuer = () => `${getBaseUrl()}/oauth2/${getIssuerId()}`;
export const getIssuerStartPlus = () =>
  isProd()
    ? `${getStartPlusBaseUrl()}/oauth2/ausetjg5xy8y30npD356`
    : `${getStartPlusBaseUrl()}/oauth2/aus6v5xnfcC2x2Nj1356`;
export const getRedirectUri = () =>
  `${window.location.origin}/implicit/callback`;
export const scopes = ['openid', 'profile', 'email'];
export const passwordRecoveryLink = '/reset-password';

export const config = {
  mycc: {
    dev: {
      baseUrl: 'https://login.dev.aws.castandcrew.com',
      clientId: '0oail3tmV0Phe7Ktr355',
      issuerId: 'aus67ubcqBSG33G9h356',
    },
    prod: {
      baseUrl: 'https://login.castandcrew.com',
      clientId: '0oa1b383d8XvfKYp0356',
      issuerId: 'aus77kwrwSbSunX5s356',
    },
  },
  start: {
    dev: {
      baseUrl: 'https://login.dev.aws.castandcrew.com',
      clientId: '0oa7165rkO5R5zu2F356',
      issuerId: 'aus6v5xnfcC2x2Nj1356',
    },
    uat: {
      baseUrl: 'https://login.dev.aws.castandcrew.com',
      clientId: '0oa717gw1EjtMSJ5J356',
      issuerId: 'aus6v5xnfcC2x2Nj1356',
    },
    aut: {
      baseUrl: 'https://login.dev.aws.castandcrew.com',
      clientId: '0oa717gw1EjtMSJ5J356',
      issuerId: 'aus6v5xnfcC2x2Nj1356',
    },
    prod: {
      baseUrl: 'https://login.castandcrew.com',
      clientId: '0oa88y7e9JjCjaHcO356',
      issuerId: 'ausetjg5xy8y30npD356',
    },
  },
};

export const getOktaConfig = () => ({
  clientId: getClientId(),
  baseUrl: getBaseUrl(),
  redirectUri: getRedirectUri(),
  useClassicEngine: true,
  logo: false,
  i18n: {
    en: {
      'primaryauth.title': 'Log in to MyCast&Crew',
      'primaryauth.username.placeholder': 'Email Address',
      'primaryauth.username.tooltip': 'Email Address',
      'password.forgot.email.or.username.placeholder': 'Email Address',
      'password.forgot.email.or.username.tooltip': 'Email Address',
      'password.forgot.sendText': 'Get a text',
      'password.forgot.call': 'Receive a voice call',
      'password.expired.title': 'Your MyCast&Crew password has expired',
      'password.reset': 'Password Recovery',
      'enroll.choices.title':
        'How would you like to receive your authentication code?',
      'enroll.choices.description':
        'MyCast&Crew requires multifactor authentication to add an additional layer of security when signing in to your account',
      'enroll.choices.description.generic':
        'MyCast&Crew requires multifactor authentication to add an additional layer of security when signing in to your account',
      'enroll.choices.list.setup': ' ',
      'recovery.mobile.hint':
        '{0} can only be used if a phone number has been added to your account as a password recovery method.',
      'recovery.smsOrCall': 'Text or voice verification',
      'errors.E0000004':
        'Incorrect credentials. If you need to reset your password, please click on the "Forgot Password" below',
      'factor.sms': 'Get a text message',
      'factor.call': 'Receive a voice call',
      'mfa.challenge.verify': 'Verify code',
      'mfa.resendCode': 'Resend code',
      'mfa.call': 'Call me',
      'factor.sms.time.warning':
        "Haven't received a text? To try again, click Resend code.",
      signout: 'Back to Log in',
      goback: 'Back to Log in',
      backToSignin: 'Back to log in',
      'errors.E0000109':
        'A text message was recently sent. Please wait 30 seconds before trying again.',
      'recoveryChallenge.sms.title': 'We sent you a text message.',
      'account.unlock.email.or.username.placeholder': 'Email Address',
      'account.unlock.email.or.username.tooltip': 'Email Address',
      'account.unlock.sendText': 'Send text',
      'mfa.factors.dropdown.title': 'Select an optional authentication method',
      'rememberDevice.timebased': 'Remember me for {0} after log in',
    },
  },
  authParams: {
    responseType: ['id_token'],
    issuer: getIssuer(),
    display: 'page',
    scopes,
  },
  helpLinks: {
    forgotPassword: '#',
    custom: [
      {
        text: 'Forgot password?',
        href: passwordRecoveryLink,
      },
      {
        text: 'Customer Support',
        href: 'https://support.castandcrew.com/kb/mycastcrew-u-s',
      },
    ],
  },
  idpDiscovery: {
    requestContext: `/oauth2/${getIssuerId()}/v1/authorize?client_id=${getClientId()}&response_type=id_token&response_mode=fragment&scope=openid+profile+email&redirect_uri=${getRedirectUri()}&state=WM6D&nonce=YsG76j`,
  },
  features: {
    idpDiscovery: true,
    rememberMe: true,
    showPasswordToggleOnSignInPage: true,
    smsRecovery: true,
    callRecovery: true,
    selfServiceUnlock: true,
    multiOptionalFactorEnroll: true,
  },
});

export default {
  oidc: {
    url: getBaseUrl(),
    clientId: getClientId(),
    baseUrl: getBaseUrl(),
    issuer: getIssuer(),
    redirectUri: getRedirectUri(),
    scopes,
    tokenManager: {
      autoRenew: false,
      storage: 'sessionStorage',
    },
    pkce: false,
  },
  oidcStartPlus: {
    url: getStartPlusBaseUrl(),
    clientId: getClientIdStartPlus(),
    baseUrl: getStartPlusBaseUrl(),
    issuer: getIssuerStartPlus(),
    redirectUri: getRedirectUri(),
    scopes,
    tokenManager: {
      autoRenew: false,
      storage: 'sessionStorage',
    },
    pkce: false,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:3000/api/messages',
  },
};

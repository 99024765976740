import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import CampaignIcon from '@mui/icons-material/Campaign';
import StarIcon from '@mui/icons-material/Star';
import CoronaIcon from '@mui/icons-material/Coronavirus';
import ActivityIcon from '@mui/icons-material/LocalActivity';

export const IconList = [
  { value: 'Warning', label: 'Important / Critical' },
  { value: 'Campaign', label: 'Announcement' },
  { value: 'Corona', label: 'Pandemic-related' },
  { value: 'Star', label: 'Product-related' },
  { value: 'Activity', label: 'New Job Offer' },
  // Currently not in use
  // { value: 'CheckCircle', label: 'Approval' },
];

export const Icon = ({ type, ...props }) => {
  const getIcon = () => {
    switch (type) {
      case 'Warning':
        return <WarningIcon {...props} />;
      case 'Campaign':
        return <CampaignIcon {...props} />;
      case 'Corona':
        return <CoronaIcon {...props} />;
      case 'Star':
        return <StarIcon {...props} />;
      case 'Activity':
        return <ActivityIcon {...props} />;
      case 'CheckCircle':
        return <CheckCircleIcon {...props} />;
      default:
        console.warn('NO/WRONG ICON SELECTED');
        return null;
    }
  };

  return getIcon();
};

export default Icon;

import { createSvgIcon } from '@mui/material/utils';

const CaretsDownIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 16L12 21L17 16H7Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 8L12 3L17 8H7Z"
      fill="#8D8D8D"
    />
  </>,
  'CaretsDown',
);

export default CaretsDownIcon;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiStep from '@mui/material/Step';
import MuiStepLabel from '@mui/material/StepLabel';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        marginTop: 4,
        fontSize: 14,
        color: palette.cncUI.default.primary,
      },
      '& .MuiStepLabel-iconContainer': {
        borderRadius: '50%',
        border: `2px solid ${palette.cncUI.default.primary}`,
        background: palette.cncUI.default.primary,
        width: 14,
        height: 14,
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },
      '& .MuiStepLabel-iconContainer.Mui-completed': {
        borderRadius: '50%',
        border: 'none',
        width: 18,
        height: 18,
        background: palette.cncUI.default['+10'],
        '& .MuiSvgIcon-root': {
          display: 'block',
          width: 18,
          height: 18,
          color: palette.cncUI.default.primary,
        },
      },
      '& .MuiStepLabel-root.Mui-disabled': {
        '& .MuiStepLabel-iconContainer': {
          border: `2px solid ${palette.cncUI.default['+7']}`,
          background: 'transparent',
        },
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          color: palette.cncUI.default['+7'],
          whiteSpace: 'nowrap',
        },
      },
    },
  }),
  { name: 'CncUI-StepperStep' },
);

const Step = props => {
  const classes = useStyles();
  const { children, label, className, ...other } = props;

  const options = {
    key: label,
    className: clsx(classes.root, className),
  };

  return (
    <MuiStep {...options} {...other}>
      <MuiStepLabel>{label}</MuiStepLabel>
    </MuiStep>
  );
};

export default Step;

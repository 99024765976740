import { useState } from 'react';
import Grid from '@mui/material/Grid';
import SearchAddress from 'components/SearchFieldAddress';

const Address = () => {
  const [data, setData] = useState({});

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Search Address Field</h2>
      </Grid>
      <Grid item md={6} xs={12}>
        <SearchAddress
          label="Search and select an address..."
          onSelect={address => setData(address)}
          FieldProps={{ light: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <pre>Address Selected: {JSON.stringify(data, null, 2)}</pre>
      </Grid>
    </Grid>
  );
};

export default Address;

import { gql } from '@apollo/client';

export default gql`
  query getReissuePaystubs {
    reissuePaystubs {
      bankName
      batchNumber
      checkDate
      checkNumber
      checkSequenceNumber
      checkNetAmount
      invoiceNumber
      clientName
      datesWorked
      payrollCompany
      productionCompany
      projectName
      residentAddr1
      residentAddr2
      residentCity
      residentState
      residentZip
      residentCountry
      isLoanOut
      ticketOwner
      source
    }
  }
`;

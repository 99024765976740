import { gql } from '@apollo/client';

export default gql`
  mutation activatePhoneFactor(
    $loginId: String!
    $factorId: String!
    $code: String!
    $activateBoth: Boolean
  ) {
    activatePhoneFactor(
      loginId: $loginId
      factorId: $factorId
      code: $code
      activateBoth: $activateBoth
    ) {
      id
      type
      status
      provider
      vendorName
    }
  }
`;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiCardHeader from '@mui/material/CardHeader';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      padding: '19px 20px 20px',
      backgroundColor: palette.cncUI.default.primary,
      color: palette.cncUI.default['+10'],
      '& .MuiCardHeader-title': {
        color: palette.cncUI.primary.gray['+9'],
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '26px',
      },
      '& .MuiCardHeader-action': {
        margin: 0,
      },
      '& .MuiCardHeader-action svg': {
        color: palette.cncUI.default['+10'],
      },
    },
    leftTitle: {
      '& .MuiCardHeader-title': {
        textAlign: 'left',
      },
    },
    centerTitle: {
      '& .MuiCardHeader-title': {
        textAlign: 'center',
      },
    },
    rightTitle: {
      '& .MuiCardHeader-title': {
        textAlign: 'right',
      },
    },
  }),
  { name: 'CncUI-CardTitle' },
);

const CardTitle = props => {
  const classes = useStyles();
  const { children, className, align = 'left', ...other } = props;
  const options = {
    title: children,
    className: clsx(
      'cncui-card-title',
      classes.root,
      classes[`${align}Title`],
      className,
    ),
  };
  return <MuiCardHeader {...options} {...other} />;
};

export default CardTitle;

import { request, gql } from 'graphql-request';
import getFlagsServer from 'constants/config/flagsServer';

const STAGE = process.env.REACT_APP_ENVIRONMENT;

export default class FeatureFlags {
  get = async (userId = '') => {
    const query = gql`{
      flags(
        context: {
          appName: "MyCnC"
          prefix: "MyCnC"
          stage: "${STAGE}"
          userId: "${userId}"
        }
      ) {
        name
        value
      }
    }`;
    const data = await request(getFlagsServer(), query);
    return data.flags;
  };
}

import { gql } from '@apollo/client';

export default gql`
  query getProfileMapping {
    getProfileMappingOptions {
      contactType {
        label
        value
      }
      ethnicity {
        label
        value
      }
      gender {
        label
        value
      }
      relationship {
        label
        value
      }
      bankAccountTypes {
        label
        value
      }
    }
  }
`;

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from 'components/CncUI/Typography';
import Button from 'components/CncUI/Button';

import useReAuthentication from 'hooks/useReAuthentication';

const ReAuthenticatePage = () => {
  const secure = useReAuthentication();
  const [authenticated, setAuthenticated] = useState('');

  // Creating a secure function that requires re-authentication (wrapping the function with the re-authentication feature)
  const doAction = secure(message => {
    setAuthenticated(message);
    return 'We can also return something';
  });

  // The result from secure function is a promise

  const handleClick = () => {
    setAuthenticated('Authenticating...');
    doAction('Authenticated') // This is the re-authentication action (is converted to a promise)
      .then(response => {
        // the response is the return value from your function wrapped in the secure function
        console.log(response);
      })
      .catch(error => {
        // the error is the error thrown from the secure function or the re-authentication action
        console.log('Error from handleClick', error);
        setAuthenticated('Failed to authenticate');
      });
  };

  // /* creating the handle click as an async function */
  // const handleClick = async () => {
  //   setAuthenticated('Authenticating...');
  //   try {
  //     const response = await doAction('Authenticated');
  //     console.log(response);
  //   } catch (error) {
  //     console.log('Error from handleClick', error);
  //     setAuthenticated('Failed to authenticate');
  //   }
  // };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Re Authenticate feature</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button onClick={handleClick}>Re-Authenticate</Button>
          </Grid>
          <Grid item>
            <Typography>{authenticated}</Typography>
          </Grid>
          {authenticated === 'Authenticated' && (
            <Grid item xs={12}>
              <Typography body2 bold>
                When authenticated we can continue with other functionality that
                can be presented after re-authentication like this message.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReAuthenticatePage;

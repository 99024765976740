import { OktaAuth } from '@okta/okta-auth-js';
import { config } from 'constants/config/okta';

const ENV = process.env.REACT_APP_ENVIRONMENT;

export default (type = 'mycc') => {
  if (!config[type]) {
    throw new Error(`There is no "${type}" okta configuration`);
  }

  const data = config[type][ENV] || config[type]['dev'];
  return new OktaAuth({
    url: data.baseUrl,
    clientId: data.clientId,
    baseUrl: data.baseUrl,
    issuer: `${data.baseUrl}/oauth2/${data.issuerId}`,
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes: ['openid', 'profile', 'email'],
    tokenManager: {
      autoRenew: false,
      storage: 'sessionStorage',
    },
    pkce: false,
  });
};

import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Typography from 'components/CncUI/Typography';
import NoNotificationsImg from './no-notifications.svg';
import Notification from './Notification';
import { Grid, IconButton } from '@mui/material';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  trayContainer: {
    marginTop: 60,
    width: 450,
    overflowY: 'hidden',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  noNotificationsContainer: {
    display: 'flex',
    marginTop: 80,
  },
  trayTitle: {
    padding: spacing(2),
  },
  noNotificationsText: {
    color: palette.cncUI.default.primary,
    marginTop: -24,
  },
  notificationList: {
    maxHeight: '85vh',
    overflowY: 'auto',
  },
}));

const NotificationTray = ({
  id,
  anchorEl,
  handleClose,
  notifications = [],
}) => {
  const classes = useStyles();

  const open = Boolean(anchorEl);
  const list = notifications.map(n => (
    <Notification notification={n} key={n.id} />
  ));

  return (
    <Drawer
      id={id}
      open={open}
      anchor="right"
      onClose={handleClose}
      elevation={5}
      className={classes.drawer}
      ModalProps={{
        BackdropProps: { invisible: true },
      }}
      PaperProps={{ className: classes.trayContainer }}
    >
      <Grid container>
        <Grid item xs={12} container>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.trayTitle}>
              Notifications
            </Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.notificationList}>
          {notifications.length === 0 && (
            <Grid
              item
              xs={12}
              className={classes.noNotificationsContainer}
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              data-test-id="no-notifications-graphic"
            >
              <img src={NoNotificationsImg} alt="No Notifications" />
              <Typography variant="h5" className={classes.noNotificationsText}>
                No notifications
              </Typography>
            </Grid>
          )}
          {list}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default NotificationTray;

import { gql } from '@apollo/client';

export default gql`
  mutation createLoanoutDocusignerDocument(
    $template: String
    $country: String
  ) {
    createLoanoutDocusignerDocument(template: $template, country: $country) {
      id
      pages
      width
      height
      fields {
        id
        fieldGroupId
        name
        fieldType
        textType
        width
        height
        page
        x
        y
        displayName
        externalName
        isRequired
        isMultiline
        isPassword
        value
        options
        format
        min
        max
        defaultValue
        isDisabled
        validateSignature
        radioGroupItems {
          name
          x
          y
          width
          height
          page
        }
      }
      images {
        page
        width
        height
        url
      }
      rules {
        id
        code
        description
        ruleType
        fieldGroups {
          id
          name
          fieldIds
        }
        responseBehavior {
          responseBehaviorType
          value
        }
        triggerFieldId
        triggerAction {
          triggerActionType
          value
        }
        conditionalRuleIds
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation enrollOktaVerifyFactor($loginId: String!) {
    enrollOktaVerifyFactor(loginId: $loginId) {
      id
      type
      status
      provider
      vendorName
      embedded
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getMyNotifications {
  # This will fetch the notifications *for the user currently logged in* by using its token to get their login ID and perform the query
  # Should get all not archived notifications that match loginId or where loginId is null (to get the “global” notifications)
  getCurrentUser {
    settings
    userNotifications(archived: false) {
      id
      applicationId
      referenceId
      title
      content
      archived
      startAt
      expireAt
      settings
    }
  }
}
`;

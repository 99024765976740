import { gql } from '@apollo/client';

export default gql`
  query getMyAffidavitDocument($id: String!) {
    getCurrentUser {
      id
      affidavitDocument(id: $id) {
        file
      }
    }
  }
`;

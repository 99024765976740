import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import TextField from '../TextField';

const useStyles = makeStyles(
  ({ palette }) => ({
    '@global': {
      '.MuiDayCalendar-header': {
        '& .MuiDayCalendar-weekDayLabel': {
          color: palette.cncUI.primary.gray['+6'],
        },
      },
      '.MuiPickersDay-root': {
        borderRadius: 2,
        color: palette.cncUI.default.primary,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&.Mui-selected': {
          color: palette.cncUI.default.primary,
          backgroundColor: `${palette.cncUI.default['+7']} !important`,
          '&:hover': {
            backgroundColor: palette.cncUI.default['+7'],
          },
        },
        '&.MuiPickersDay-today': {
          border: 'none',
          position: 'relative',
          '&:before': {
            content: "' '",
            display: 'block',
            width: 4,
            height: 4,
            borderRadius: 1,
            background: palette.cncUI.default.primary,
            position: 'absolute',
            bottom: 1,
          },
        },
      },
      '.MuiPickersLayout-actionBar': {
        '& .MuiButton-root': {
          color: palette.cncUI.default.primary,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
      '.MuiClock-pin, .MuiClockPointer-root, .MuiClockPointer-thumb': {
        backgroundColor: palette.cncUI.default['+7'],
      },
      '.MuiClockPointer-thumb': {
        borderColor: palette.cncUI.default['+7'],
      },
      '.MuiClockNumber-root.Mui-selected': {
        color: palette.cncUI.default.primary,
      },
    },
  }),
  { name: 'CncUI-DateTimePicker' },
);

const DateTimePicker = props => {
  useStyles();
  const {
    children,
    className,
    light,
    short,
    clearable = false,
    TextFieldProps = {},
    ...other
  } = props;
  const options = {
    className: clsx('cncui-DateTimePicker', className),
  };

  const slotProps = {
    textField: { light, short, ...TextFieldProps },
  };

  if (clearable) {
    slotProps.field = { clearable };
  }

  return (
    <MuiDateTimePicker
      ampm
      format="MM/dd/yyyy hh:mm a"
      slots={{ textField: TextField }}
      slotProps={slotProps}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      {...options}
      {...other}
    />
  );
};

export default DateTimePicker;

import { gql } from '@apollo/client';

export default gql`
  mutation resendPhoneFactor(
    $factorId: String!
    $input: OktaEnrollPhoneInput!
  ) {
    resendPhoneFactor(factorId: $factorId, input: $input)
  }
`;

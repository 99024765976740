import { useRef } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import MuiSwitch from '@mui/material/Switch';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      '& .MuiSwitch-switchBase': {
        color: palette.cncUI.default['+10'],
      },
      '& .MuiSwitch-thumb': {
        boxShadow: palette.cncUI.shadow.interactive.default,
      },
      '& .MuiSwitch-track': {
        background: palette.cncUI.primary.gray['+6'],
        opacity: 1,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: palette.cncUI.default.primary,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        background: palette.cncUI.default['+6'],
        opacity: 1,
      },
      '& .MuiSwitch-switchBase.Mui-disabled': {
        color: palette.cncUI.primary.gray['+9'],
        '& .MuiSwitch-thumb': {
          boxShadow: palette.cncUI.shadow.interactive.disabled,
        },
      },
      '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
        background: palette.cncUI.primary.gray['+7'],
        opacity: 1,
      },
      '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
        color: palette.cncUI.default['+7'],
      },
      '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track': {
        background: palette.cncUI.default['+9'],
        opacity: 1,
      },
    },
    light: {
      '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
        color: palette.cncUI.default['+6'],
      },
      '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track': {
        background: palette.cncUI.default['+8'],
        opacity: 1,
      },
    },
    label: {
      '& .MuiButtonBase-root.Mui-checked + .MuiTypography-root': {
        color: palette.cncUI.default.primary,
      },
      '& .MuiButtonBase-root.Mui-checked + .MuiTypography-root.Mui-disabled': {
        color: palette.cncUI.primary.gray['+7'],
      },
      '& .MuiTypography-root': {
        color: palette.cncUI.primary.gray['+0'],
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 400,
        '&.Mui-disabled': {
          color: palette.cncUI.primary.gray['+7'],
        },
      },
    },
    labelLight: {
      '& .MuiTypography-root': {
        color: palette.cncUI.default['+10'],
      },
    },
    labelChecked: {
      '& .MuiTypography-root': {
        color: palette.cncUI.default.primary,
      },
    },
    short: {
      height: 34,
      '& .MuiSwitch-thumb': {
        width: 16,
        height: 16,
      },
      '& .MuiSwitch-track': {
        height: 12,
        width: 29,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        height: 12,
        width: 29,
      },
      '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
        marginLeft: -2,
      },
    },
    compact: {
      height: 30,
      '& .MuiSwitch-thumb': {
        width: 12,
        height: 12,
        marginTop: 0,
      },
      '& .MuiSwitch-track': {
        height: 9,
        width: 22,
        marginTop: -1,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        height: 9,
        width: 22,
        marginTop: -1,
      },
      '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
        marginLeft: -4,
      },
    },
    shortLabel: {
      '& .MuiTypography-root': {
        fontSize: 14,
        lineHeight: '18px',
        letterSpacing: '0.16px',
      },
      '&.MuiFormControlLabel-labelPlacementTop': {
        '& .MuiIconButton-label': {
          margin: 2,
        },
      },
      '&.MuiFormControlLabel-labelPlacementBottom': {
        '& .MuiIconButton-label': {
          margin: 2,
        },
      },
    },
    compactLabel: {
      '& .MuiTypography-root': {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.32px',
      },
      '&.MuiFormControlLabel-labelPlacementTop': {
        '& .MuiIconButton-label': {
          margin: 0,
        },
      },
      '&.MuiFormControlLabel-labelPlacementBottom': {
        '& .MuiIconButton-label': {
          margin: 0,
        },
      },
    },
    loading: {
      color: palette.cncUI.default.primary,
      padding: 12,
    },
  }),
  { name: 'CncUI-Switch' },
);

const POSITION = {
  top: 'top',
  right: 'end',
  bottom: 'bottom',
  left: 'start',
};

const Switch = props => {
  const classes = useStyles();
  const check = useRef();
  const {
    children,
    className,
    size = 'normal',
    light = false,
    loading = false,
    labelPosition = 'right',
    labelProps = {},
    checked = false,
    ...other
  } = props;

  const options = {
    checked,
    className: clsx(
      'cncui-switch',
      classes.root,
      light && classes.light,
      classes[size],
      `cncui-switch-${size}`,
      className,
    ),
  };

  if (children) {
    return (
      <FormControlLabel
        labelPlacement={POSITION[labelPosition] || 'end'}
        {...labelProps}
        className={clsx(
          'cncui-switch-label',
          classes.label,
          light && classes.labelLight,
          checked ? classes.labelChecked : false,
          classes[`${size}Label`],
          labelProps.className,
        )}
        control={
          loading ? (
            <CircularProgress size="1em" className={classes.loading} />
          ) : (
            <MuiSwitch {...options} {...other} inputRef={check} />
          )
        }
        label={children}
      />
    );
  }

  return <MuiSwitch {...options} {...other} />;
};

export default Switch;

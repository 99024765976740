import { gql } from '@apollo/client';

export default gql`
  query getMappingOptions {
    getProfileMappingOptions {
      ethnicity {
        label
        value
      }
      gender {
        label
        value
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getProjects {
    projects {
      projectId
      worksightId
      name
      status
      region
      dbCode
      createTimecardUrl
      allowableActions
      timecards {
        entryHeaderId
        status
        weekEnding
        workedHours
        occupation
        viewTimecardUrl
      }
    }
  }
`;

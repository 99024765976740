import { useState } from 'react';
import Grid from '@mui/material/Grid';
import AccountIcon from '@mui/icons-material/AccountCircle';
import Checkbox from 'components/CncUI/Checkbox';
import Dropdown from 'components/CncUI/Dropdown';

const Containers = () => {
  const [item, setItem] = useState('');
  const [light, setLight] = useState(true);
  const [short, setShort] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Dropdowns</h2>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={light}
              onChange={e => setLight(e.target.checked)}
            >
              Light Dropdowns
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={short}
              onChange={e => setShort(e.target.checked)}
            >
              Short Dropdowns
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">Regular</h3>
      </Grid>
      <Grid item>
        <Dropdown
          light={light}
          short={short}
          value={item}
          placeholder="Select an option"
          onChange={e => setItem(e.target.value)}
        >
          {[1, 2, 3].map(value => (
            <Dropdown.Item key={value} value={`List Item ${value}`}>
              List Item {value}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Grid>
      <Grid item>
        <Dropdown
          short={short}
          light={light}
          disabled
          value=""
          placeholder="Select an option"
        ></Dropdown>
      </Grid>
      <Grid item>
        <Dropdown
          error
          light={light}
          short={short}
          value={item}
          helperText="Invalid selection"
          placeholder="Select an option"
          onChange={e => setItem(e.target.value)}
        >
          {[1, 2, 3].map(value => (
            <Dropdown.Item key={value} value={`List Item ${value}`}>
              List Item {value}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Grid>
      <Grid item>
        <Dropdown
          warning
          light={light}
          short={short}
          value={item}
          helperText="Invalid selection"
          placeholder="Select an option"
          onChange={e => setItem(e.target.value)}
        >
          {[1, 2, 3].map(value => (
            <Dropdown.Item key={value} value={`List Item ${value}`}>
              List Item {value}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">With icons</h3>
      </Grid>
      <Grid item>
        <Dropdown
          light={light}
          short={short}
          value={item}
          placeholder="Select an option"
          onChange={e => setItem(e.target.value)}
          renderValue={value => (
            <Grid container alignItems="center" spacing={1}>
              <Grid item style={{ display: 'flex' }}>
                <AccountIcon />
              </Grid>
              <Grid item>{value}</Grid>
            </Grid>
          )}
        >
          {[1, 2, 3].map(value => (
            <Dropdown.Item
              key={value}
              value={`List Item ${value}`}
              startIcon={<AccountIcon />}
            >
              {`List Item ${value}`}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Grid>
      <Grid item>
        <Dropdown
          light={light}
          short={short}
          value={item}
          placeholder="Select an option"
          onChange={e => setItem(e.target.value)}
          renderValue={value => (
            <Grid
              container
              alignItems="center"
              spacing={1}
              justifyContent="space-between"
              style={{ marginRight: 8 }}
            >
              <Grid item>{value}</Grid>
              <Grid item style={{ display: 'flex', textAlign: 'right' }}>
                <AccountIcon />
              </Grid>
            </Grid>
          )}
        >
          {[1, 2, 3].map(value => (
            <Dropdown.Item
              key={value}
              value={`List Item ${value}`}
              endIcon={<AccountIcon />}
            >
              List Item {value}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Grid>
      <Grid item>
        <Dropdown
          error
          light={light}
          short={short}
          value={item}
          helperText="Invalid selection"
          placeholder="Select an option"
          onChange={e => setItem(e.target.value)}
          mappingValue={value => (
            <Grid container alignItems="center" spacing={1}>
              <Grid item style={{ display: 'flex' }}>
                <AccountIcon />
              </Grid>
              <Grid item>{value}</Grid>
            </Grid>
          )}
        >
          {[1, 2, 3].map(value => (
            <Dropdown.Item
              key={value}
              value={`List Item ${value}`}
              startIcon={<AccountIcon />}
            >
              {`List Item ${value}`}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Grid>
      <Grid item>
        <Dropdown
          warning
          light={light}
          short={short}
          value={item}
          helperText="Invalid selection"
          placeholder="Select an option"
          onChange={e => setItem(e.target.value)}
          mappingValue={value => (
            <Grid container alignItems="center" spacing={1}>
              <Grid item style={{ display: 'flex' }}>
                <AccountIcon />
              </Grid>
              <Grid item>{value}</Grid>
            </Grid>
          )}
        >
          {[1, 2, 3].map(value => (
            <Dropdown.Item
              key={value}
              value={`List Item ${value}`}
              startIcon={<AccountIcon />}
            >
              {`List Item ${value}`}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Grid>
    </Grid>
  );
};

export default Containers;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import DropdownItem from './DropdownItem';

import renderIcon from './utils/renderIcon';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      minWidth: 160,
      '& .MuiSelect-select': {
        backgroundColor: palette.cncUI.primary.gray['+9'],
        color: palette.cncUI.primary.gray['+0'],
        borderRadius: '2px 2px 0 0',
        padding: '8px 8px 10px',
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
          fontSize: 20,
        },
      },
      '& .MuiSvgIcon-root.cncui-dropdown-error-icon': {
        fontSize: 16,
      },
      '& .MuiFormLabel-root': {
        color: palette.cncUI.primary.gray['+0'],
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${palette.cncUI.primary.gray['+3']}`,
      },
      '&:hover .MuiInput-underline:before': {
        borderBottom: `2px solid ${palette.cncUI.primary.gray['+3']}`,
      },
      '& .MuiInput-underline.Mui-focused:after': {
        borderBottom: `2px solid ${palette.cncUI.default.primary}`,
      },
      '& .MuiInputLabel-formControl.Mui-focused': {
        color: palette.cncUI.default.primary,
      },
    },
    light: {
      '& .MuiSelect-select': {
        backgroundColor: palette.cncUI.default['+10'],
      },
    },
    short: {
      '& .MuiSelect-select': {
        fontSize: 14,
        lineHeight: '18px',
        padding: '2px 8px 5px',
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
    disabled: {
      '& .MuiSelect-select': {
        color: palette.cncUI.primary.gray['+7'],
      },
      '& .MuiFormLabel-root': {
        color: palette.cncUI.primary.gray['+7'],
      },
      '& .MuiInput-underline:before': {
        borderBottom: `2px solid ${palette.cncUI.primary.gray['+7']}`,
      },
      '&:hover .MuiInput-underline:before': {
        borderBottom: `2px solid ${palette.cncUI.primary.gray['+7']}`,
      },
      '& .MuiInput-underline.Mui-focused:after': {
        borderBottom: `2px solid ${palette.cncUI.primary.gray['+7']}`,
      },
      '& .MuiInputLabel-formControl.Mui-focused': {
        color: palette.cncUI.primary.gray['+7'],
      },
    },
    warning: {
      '& .MuiFormLabel-root': {
        color: palette.cncUI.alert.yellow.primary,
      },
      '& .cncui-dropdown-error-icon': {
        color: palette.cncUI.alert.yellow.primary,
      },
      '& .MuiInput-underline:before': {
        borderBottom: `2px solid ${palette.cncUI.alert.yellow.primary}`,
      },
      '&:hover .MuiInput-underline:before': {
        borderBottom: `2px solid ${palette.cncUI.alert.yellow.primary}`,
      },
      '& .MuiInput-underline.Mui-focused:after': {
        borderBottom: `2px solid ${palette.cncUI.alert.yellow.primary}`,
      },
      '& .MuiInputLabel-formControl.Mui-focused': {
        color: palette.cncUI.alert.yellow.primary,
      },
      '& .MuiFormHelperText-root': {
        color: palette.cncUI.alert.yellow['-1'],
      },
    },
    error: {
      '& .MuiFormLabel-root': {
        color: palette.cncUI.alert.red.primary,
      },
      '& .cncui-dropdown-error-icon': {
        color: palette.cncUI.alert.red.primary,
      },
      '& .MuiInput-underline:before': {
        borderBottom: `2px solid ${palette.cncUI.alert.red.primary}`,
      },
      '&:hover .MuiInput-underline:before': {
        borderBottom: `2px solid ${palette.cncUI.alert.red.primary}`,
      },
      '& .MuiInput-underline.Mui-focused:after': {
        borderBottom: `2px solid ${palette.cncUI.alert.red.primary}`,
      },
      '& .MuiInputLabel-formControl.Mui-focused': {
        color: palette.cncUI.alert.red.primary,
      },
      '& .MuiFormHelperText-root': {
        color: palette.cncUI.alert.red.primary,
      },
    },
    list: {
      '& .MuiList-root': {
        backgroundColor: palette.cncUI.primary.gray['+9'],
        boxShadow: '0px 0px 5px rgba(38, 38, 38, 0.7)',
        padding: 0,
      },
    },
    lightList: {
      '& .MuiList-root': {
        backgroundColor: palette.cncUI.default['+10'],
        boxShadow: '0px 0px 5px rgba(38, 38, 38, 0.7)',
        padding: 0,
        '& .MuiListItem-root:hover': {
          backgroundColor: palette.cncUI.primary.gray['+8'],
        },
      },
    },
    fullWidth: {
      width: '100%',
    },
    label: {
      transform: 'scale(1)',
      marginTop: -6,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: '0.16px',
    },
    placeholder: {
      color: palette.cncUI.primary.gray['+6'],
      fontStyle: 'italic',
      opacity: 1,
    },
  }),
  { name: 'CncUI-Dropdown' },
);

const Dropdown = props => {
  const classes = useStyles();
  const {
    children,
    className,
    controlProps = {},
    labelProps = {},
    label = '',
    helperText = '',
    disabled = false,
    error = false,
    warning = false,
    light = false,
    short = false,
    fullWidth = false,
    placeholder = '',
    renderValue = null,
    required = false,
    data = null,
    mappingValue = value => value,
    ...other
  } = props;

  let items = children;

  const options = {
    error,
    disabled,
    className: clsx(
      classes.root,
      short && classes.short,
      warning && classes.warning,
      error && classes.error,
      light && classes.light,
      disabled && classes.disabled,
      fullWidth && classes.fullWidth,
      className,
    ),
  };

  const selectProps = {
    displayEmpty: !!placeholder,
    renderValue: selected => {
      if (placeholder && selected?.length === 0) {
        return <span className={classes.placeholder}>{placeholder}</span>;
      }

      const value = renderValue
        ? renderValue(selected)
        : mappingValue
        ? mappingValue(selected)
        : selected;

      if (error || warning) {
        return renderIcon(value);
      }

      return value;
    },
  };

  if (Array.isArray(data) && data.length) {
    items = data.map(item => {
      const value = typeof item === 'object' ? item.value || '' : `${item}`;
      const label =
        typeof item === 'object'
          ? item.label || item.description || item.value || ''
          : `${item}`;
      return (
        <DropdownItem
          key={value}
          value={value}
          title={item.email || ''}
          disabled={item.disabled || false}
        >
          {label}
        </DropdownItem>
      );
    });
    if (!renderValue) {
      selectProps.renderValue = selected => {
        if (placeholder && selected?.length === 0) {
          return <span className={classes.placeholder}>{placeholder}</span>;
        }
        const item = data.find(i => `${i.value || i}` === `${selected}`) || {
          label: selected,
        };
        return item.label || item.description || item.value || `${item}`;
      };
    }
  }

  return (
    <FormControl {...options} {...controlProps}>
      {label && (
        <InputLabel className={classes.label} shrink {...labelProps}>
          {label}
          {required && ' *'}
        </InputLabel>
      )}
      <Select
        variant="standard"
        MenuProps={{
          className: light ? classes.lightList : classes.list,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        {...selectProps}
        {...other}
      >
        {placeholder && (
          <DropdownItem value="" placeholder>
            {placeholder}
          </DropdownItem>
        )}
        {items}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Dropdown.Item = DropdownItem;

export default Dropdown;

import { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      minHeight: 36,
      color: palette.cncUI.primary.gray['+0'],
      '& .MuiListItemIcon-root': {
        minWidth: 24,
        marginRight: 8,
      },
      '&:hover': {
        backgroundColor: palette.cncUI.primary.gray['+7'],
        color: palette.cncUI.primary.gray['+0'],
      },
      '&:hover svg': {
        color: palette.cncUI.primary.gray['+0'],
      },
      '&.Mui-selected': {
        backgroundColor: palette.cncUI.default['+9'],
        color: palette.cncUI.default.primary,
      },
      '&.Mui-selected:hover': {
        backgroundColor: palette.cncUI.default['+9'],
        color: palette.cncUI.default.primary,
      },
      '&.Mui-selected svg': {
        color: palette.cncUI.default.primary,
      },
    },
    end: {
      marginLeft: 'auto',
      marginRight: '0 !important',
    },
  }),
  { name: 'CncUI-DropdownItem' },
);

const DropdownItem = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    children,
    className,
    startIcon,
    endIcon,
    placeholder = false,
    ...other
  } = props;
  const options = {
    className: clsx(classes.root, className),
    disabled: placeholder,
  };

  return (
    <MenuItem ref={ref} {...options} {...other}>
      {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
      {children}
      {endIcon && (
        <ListItemIcon className={classes.end}>{endIcon}</ListItemIcon>
      )}
    </MenuItem>
  );
});

export default DropdownItem;

import { gql } from '@apollo/client';

export default gql`
  query getMyClientSetup {
    getCurrentUser {
      oktaProfile {
        loginId
        email
        fullName
        title
      }
      clientSetup
    }
  }
`;

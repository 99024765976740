import { useEffect, useState } from 'react';
import MaskedInput, { conformToMask } from 'react-text-mask';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ClearIcon from '@mui/icons-material/HighlightOff';
import TextField from './TextField';

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.cncUI.primary.gray['+0'],
    '& svg': {
      width: 20,
      height: 20,
      marginBottom: 2,
    },
  },
  clear: {
    color: palette.cncUI.primary.gray['+2'],
    cursor: 'pointer',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));

const SIN_MASK = [
  /\d|\*/,
  /\d|\*/,
  /\d|\*/,
  '-',
  /\d|\*/,
  /\d|\*/,
  /\d|\*/,
  '-',
  /\d|\*/,
  /\d|\*/,
  /\d|\*/,
];

// ___-___-___
// 01234567890

const TextFieldSin = props => {
  const classes = useStyles();
  const {
    value,
    toggle,
    onChange = () => {},
    onClear = null,
    InputProps = {},
    showAll = false,
    startIcon = null,
    ...other
  } = props;
  const [sin, setSin] = useState(value);
  const [show, setShow] = useState(showAll);
  const newInputProps = {};

  useEffect(() => {
    if (value === '') {
      setSin('');
    }
  }, [value]);

  const maskValue = raw => {
    const masked = [];
    for (let i = 0; i < raw.length; i++) {
      if ([3, 7].includes(i)) masked.push('-');
      if ([0, 1, 2, 4, 5, 6].includes(i))
        masked.push(raw[i] === '_' ? '_' : '*');
      if ([8, 9, 10].includes(i)) masked.push(raw[i]);
    }
    return masked.join('');
  };

  const update = event => {
    if (show) {
      setSin(event.target.value);
      onChange(event);
      return;
    }

    const raw = event.target.value;
    const digits = raw.replace(/\D/g, '');
    const index = raw.indexOf('_');
    const indexSin = sin.indexOf('_');
    const sub = index > -1 ? raw.slice(0, index) : raw;
    const subSin = indexSin > -1 ? sin.slice(0, indexSin) : sin;
    let newSin = sin;

    if (sub.length < subSin.length) {
      newSin = conformToMask(sin.slice(0, sub.length), SIN_MASK, {
        guide: true,
      });
    } else if (sub.length > subSin.length) {
      newSin = conformToMask(
        `${sin.replace(/\D/g, '')}${digits.slice(
          (sub.length - subSin.length) * -1,
        )}`,
        SIN_MASK,
        { guide: true },
      );
    }

    if (newSin.conformedValue) {
      setSin(newSin.conformedValue);
      onChange({
        ...event,
        target: { ...event.target, value: newSin.conformedValue },
      });
    }
  };

  if (startIcon) {
    newInputProps.startAdornment = (
      <InputAdornment position="start" className={classes.icon}>
        {startIcon}
      </InputAdornment>
    );
  }

  if (toggle || onClear) {
    newInputProps.endAdornment = (
      <InputAdornment position="end" className={classes.icon}>
        {toggle && (
          <IconButton
            size="small"
            aria-label="toggle ssn visibility"
            onClick={() => setShow(!show)}
            onMouseDown={e => e.preventDefault()}
          >
            {!show ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        )}
        {onClear && (
          <IconButton
            size="small"
            aria-label="clear field"
            onClick={onClear}
            onMouseDown={e => e.preventDefault()}
            className={classes.clear}
          >
            <ClearIcon />
          </IconButton>
        )}
      </InputAdornment>
    );
  }

  return (
    <MaskedInput
      keepCharPositions
      mask={SIN_MASK}
      value={show ? sin : maskValue(sin)}
      onChange={update}
      render={(ref, props) => (
        <TextField
          placeholder="___-___-___"
          {...other}
          {...props}
          inputRef={ref}
          InputProps={{
            ...InputProps,
            ...newInputProps,
          }}
        />
      )}
    />
  );
};

export default TextFieldSin;

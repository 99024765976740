import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import AccordionDetails from '@mui/material/AccordionDetails';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      borderLeft: `4px solid ${palette.cncUI.default.primary}`,
      borderBottom: `1px solid ${palette.cncUI.primary.gray['+9']}`,
    },
  }),
  { name: 'CncUI-AccordionContent' },
);

const AccordionContent = props => {
  const classes = useStyles();
  const { children, className, ...other } = props;
  const options = {
    className: clsx('cncui-accordion-content', classes.root, className),
  };
  return (
    <AccordionDetails {...options} {...other}>
      {children}
    </AccordionDetails>
  );
};

export default AccordionContent;

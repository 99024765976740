import { gql } from '@apollo/client';

export default gql`
  query adminTaxDocumentLogs($ssn: String!) {
    getUser(ssn: $ssn) {
      id
      taxDocumentLogEntries {
        time
        taxDocumentId
        action
        actor
        oldAttributes
        newAttributes
      }
    }
  }
`;

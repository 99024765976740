import { gql } from '@apollo/client';

export default gql`
  query getRegistrationStatus(
    $lastFourSsn: String
    $email: String
    $ssn: String
  ) {
    getRegistrationStatus(lastFourSsn: $lastFourSsn, email: $email, ssn: $ssn) {
      loginId
      email
      profileCompleted
      capsProfileCompleted
      verified
      cncProfile
      capsProfile
      canProfile
    }
  }
`;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiSnackbar from '@mui/material/Snackbar';
import { Alert, AlertTitle } from '@mui/material';
import SnackbarAction from './SnackbarAction';

const useStyles = makeStyles(
  ({ palette, breakpoints }) => ({
    root: {
      boxShadow: palette.cncUI.shadow.container.default,
    },
    '& .cncui-snackbar-alert': {
      background: palette.cncUI.primary.gray['+8'],
      borderLeft: `4px solid ${palette.cncUI.primary.gray['+0']}`,
    },
    info: {
      '& .cncui-snackbar-alert': {
        background: palette.cncUI.default['+10'],
        borderLeft: `4px solid ${palette.cncUI.default.primary}`,
        '& .MuiAlert-icon': {
          color: palette.cncUI.default.primary,
        },
      },
    },
    success: {
      '& .cncui-snackbar-alert': {
        background: palette.cncUI.alert.green.powder,
        borderLeft: `4px solid ${palette.cncUI.alert.green.primary}`,
        '& .MuiAlert-icon': {
          color: palette.cncUI.alert.green.primary,
        },
      },
    },
    warning: {
      '& .cncui-snackbar-alert': {
        background: palette.cncUI.alert.yellow.powder,
        borderLeft: `4px solid ${palette.cncUI.alert.yellow.primary}`,
        '& .MuiAlert-icon': {
          color: palette.cncUI.alert.yellow.primary,
        },
      },
    },
    error: {
      '& .cncui-snackbar-alert': {
        background: palette.cncUI.alert.red.powder,
        borderLeft: `4px solid ${palette.cncUI.alert.red.primary}`,
        '& .MuiAlert-icon': {
          color: palette.cncUI.alert.red.primary,
        },
      },
    },
    plain: {
      '& .cncui-snackbar-alert': {
        background: palette.dashboard.background,
        borderLeft: 0,
        borderBottom: `1px solid ${palette.dashboard.gray300}`,
        boxShadow:
          '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      },
      [breakpoints.up('lg')]: {
        width: 900,
      },
      [breakpoints.only('md')]: {
        width: 860,
      },
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    noBorder: {
      borderLeft: 'none',
    },
    inline: {
      '& .MuiAlert-message': {
        display: 'flex',
        alignItems: 'flex-start',
        '& .MuiAlertTitle-root': {
          marginRight: 8,
          marginBottom: 0,
        },
      },
    },
  }),
  { name: 'CncUI-Snackbar' },
);

const Snackbar = props => {
  const classes = useStyles();
  const {
    children,
    title,
    action,
    position = 'top-center',
    variant,
    hideDuration,
    onClose,
    className,
    inline = false,
    noBorder = false,
    AlertProps = {},
    ...other
  } = props;

  const [vertical, horizontal] = position.split('-');
  const options = {
    onClose,
    className: clsx(
      'cncui-snackbar',
      classes.root,
      classes[variant],
      noBorder && classes.noBorder,
      inline && classes.inline,
      className,
    ),
    autoHideDuration: hideDuration || null,
    anchorOrigin: { vertical, horizontal },
  };

  const alertOptions = {
    className: 'cncui-snackbar-alert',
    severity: variant,
    sx: { width: '100%' },
  };

  if (onClose) {
    alertOptions.onClose = onClose;
  }

  if (action) {
    alertOptions.action = action;
  }

  return (
    <MuiSnackbar {...options} {...other}>
      <Alert {...alertOptions} {...AlertProps}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </Alert>
    </MuiSnackbar>
  );
};

Snackbar.Action = SnackbarAction;

export default Snackbar;

import { gql } from '@apollo/client';

export default gql`
  query getLoanoutHistory($country: String) {
    getLoanoutHistory(country: $country) {
      id
      name
      country
      template
      createdAt
      documentId
    }
  }
`;

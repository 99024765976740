import Loader from 'components/Loader';
import Logger from 'utilities/logger';
import useAuthentication from 'hooks/useAuthentication';
const logger = Logger('implicit_callback');
const newrelic = window.newrelic;

const ImplicitCallback = () => {
  const { storage } = useAuthentication();
  newrelic?.addPageAction('userLoggedIn');
  logger.debug('Successfully logged in');

  const redirecting = storage.getItem('redirecting');
  storage.removeItem('redirecting');

  if (!redirecting) {
    logger.debug('Not redirecting, redirecting to origin', { redirecting });
    window.location.replace(window.location.origin);
  }

  return <Loader fullScreen withLogo />;
};

export default ImplicitCallback;

import { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import TextField from './TextField';
import emailMask from 'text-mask-addons/dist/emailMask';

const TextFieldEmail = props => {
  const {
    value: emailAddress = null,
    toggle,
    onChange = () => {},
    error,
    onBlur = () => {},
    ...other
  } = props;

  const [email, setEmail] = useState(emailAddress || '');

  useEffect(() => {
    if (emailAddress !== email) {
      setEmail(emailAddress || '');
    }
  }, [emailAddress]);

  const change = e => {
    setEmail(e.target.value);
    if (typeof onChange === 'function') onChange(e);
  };

  const blur = () => {
    if (typeof onBlur === 'function') onBlur();
  };

  return (
    <MaskedInput
      mask={emailMask}
      value={email}
      onChange={change}
      error={error}
      onBlur={blur}
      render={(ref, props) => (
        <TextField inputRef={ref} {...other} {...props} />
      )}
    />
  );
};

export default TextFieldEmail;

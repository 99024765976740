import { useEffect } from 'react';
import sysend from 'sysend';
import Tokens from 'providers/Tokens';
import Logger from 'utilities/logger';

const useSysend = () => {
  const logger = Logger('Sysend');
  useEffect(() => {
    if (sysend) {
      logger.debug('Sysend loaded');
      sysend.on('logout', function(data) {
        logger.debug('User logout', data);
        Tokens.clear();
        window.location.replace('/');
      });
    } else {
      logger.debug('Sysend not loaded');
    }
  }, []);
};

export default useSysend;

const COLORS = {
  debug: '#9E9E9E',
  info: '#03A9F4',
  warning: '#D0BF30',
  error: '#F20404',
};

const STYLES = ['color: inherit', 'color: gray; font-weight: lighter'];

const allowDebug = ['qa', 'uat', 'local'].includes(
  process.env.REACT_APP_ENVIRONMENT,
);

const repeat = (str, times) => new Array(times + 1).join(str);

const pad = (num, maxLength) =>
  repeat('0', maxLength - num.toString().length) + num;

const formatTime = time =>
  `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(
    time.getSeconds(),
    2,
  )}.${pad(time.getMilliseconds(), 3)}`;

const isDir = value => {
  if (Array.isArray(value)) {
    return value.length > 4;
  }
  if (value && typeof value === 'object') {
    const keys = Object.keys(value);
    return keys.length > 4;
  }
  return false;
};

export default module => {
  const { log, dir, group, groupCollapsed, groupEnd } = console;
  const method = allowDebug ? group : groupCollapsed;
  const title = `%c${module.toUpperCase()}`;
  const end = () => groupEnd();
  const start = action => {
    const time = formatTime(new Date());
    const color = `color: ${COLORS[action]}; font-weight: lighter`;
    method(`%c${action} ${title} %c@ ${time}`, color, ...STYLES);
  };
  const print = (type, ...args) => {
    start(type);
    args.forEach(arg => {
      if (isDir(arg)) {
        dir(arg);
      } else {
        log(arg);
      }
    });
    end();
  };

  return {
    debug: (...args) => {
      if (allowDebug) {
        print('debug', ...args);
      }
    },
    info: (...args) => print('info', ...args),
    warn: (...args) => print('warning', ...args),
    error: (...args) => {
      print('error', ...args);
    },
  };
};

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from 'components/CncUI/Tooltip';
import Button from 'components/CncUI/Button';
import Dropdown from 'components/CncUI/Dropdown';

const Tooltips = () => {
  const [position, setPosition] = useState('top-left');
  const [size, setSize] = useState('small');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Tooltips</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Dropdown
              light
              label="Position"
              value={position}
              onChange={e => setPosition(e.target.value)}
            >
              <Dropdown.Item value="top-left">Top Left</Dropdown.Item>
              <Dropdown.Item value="top-center">Top Center</Dropdown.Item>
              <Dropdown.Item value="top-right">Top Right</Dropdown.Item>
              <Dropdown.Item value="right-top">Right Top</Dropdown.Item>
              <Dropdown.Item value="right-center">Right Center</Dropdown.Item>
              <Dropdown.Item value="right-bottom">Right Bottom</Dropdown.Item>
              <Dropdown.Item value="bottom-left">Bottom Left</Dropdown.Item>
              <Dropdown.Item value="bottom-center">Bottom Center</Dropdown.Item>
              <Dropdown.Item value="bottom-right">Bottom Right</Dropdown.Item>
              <Dropdown.Item value="left-top">Left Top</Dropdown.Item>
              <Dropdown.Item value="left-center">Left Center</Dropdown.Item>
              <Dropdown.Item value="left-bottom">Left Bottom</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <Dropdown
              light
              label="Size"
              value={size}
              onChange={e => setSize(e.target.value)}
            >
              <Dropdown.Item value="small">Small</Dropdown.Item>
              <Dropdown.Item value="medium">Medium</Dropdown.Item>
              <Dropdown.Item value="large">Large</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <Tooltip
              size={size}
              position={position}
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
            >
              <span>
                <Button size="large">Test Tooltip</Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tooltips;

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from 'components/CncUI/Button';
import ContentBox from 'components/CncUI/ContentBox';
import Card from 'components/CncUI/Card';

const Cards = () => {
  const action = (
    <IconButton size="small">
      <EditIcon />
    </IconButton>
  );

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <h2 className="header">Cards</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card white>
              <Card.Title>Title</Card.Title>
              <Card.Content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat
              </Card.Content>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card white>
              <Card.Title action={action} align="center">
                Title
              </Card.Title>
              <Card.Content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat
              </Card.Content>
              <Card.Actions>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button secondary>Secondary</Button>
                  </Grid>
                  <Grid item>
                    <Button>Primary</Button>
                  </Grid>
                </Grid>
              </Card.Actions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <ContentBox>
          <Card>
            <Card.Title align="right">Title</Card.Title>
            <Card.Content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat
            </Card.Content>
            <Card.Actions>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button tertiary>Tertiary</Button>
                </Grid>
                <Grid item>
                  <Button>Primary</Button>
                </Grid>
              </Grid>
            </Card.Actions>
          </Card>
        </ContentBox>
      </Grid>
    </Grid>
  );
};

export default Cards;

import { gql } from '@apollo/client';

export default gql`
  query adminTaxDocuments($ssn: String!) {
    getUser(ssn: $ssn) {
      id
      taxDocuments {
        id
        fileUrl
        name
        year
        employer
        fileCreatedAt
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation addAccountItem($input: AccountItemInput!) {
    addAccountItem(input: $input) {
      id
      name
      status
      currency
      accounts {
        subtype
        account
        routing
        ba
        rn
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getTruliooLogs($lastFourSsn: String, $email: String) {
    getTruliooLogs(lastFourSsn: $lastFourSsn, email: $email) {
      id
      verify
      loginId
      input
      data
      createdAt
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation attachProjectFile($input: FileUploadInput) {
    attachProjectFile(input: $input) {
      id
      output
    }
  }
`;

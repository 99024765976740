import { gql } from '@apollo/client';

export default gql`
  query getAdminActivityLogs {
    getAdminActivityLogs {
      id
      loginId
      data
      createdAt
    }
  }
`;

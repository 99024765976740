import { gql } from '@apollo/client';

export default gql`
  query getHealthChecks($page: Int) {
    getStatusChecks(page: $page) {
      id
      createdAt
      log {
        status
        checks {
          fmsApi {
            status
            time
            data
          }
          oktaApi {
            status
            time
            data
          }
          plaidApi {
            status
            time
            data
          }
          loqateApi {
            status
            time
            data
          }
          cognitoApi {
            status
            time
            data
          }
          environment {
            status
            time
            data
          }
          redisServer {
            status
            time
            data
          }
          flagsService {
            status
            time
            data
          }
          payrollNewApi {
            status
            time
            data
          }
          postgresDatabase {
            status
            time
            data
          }
          truliooDocumentApi {
            status
            time
            data
          }
          truliooIdentityApi {
            status
            time
            data
          }
        }
      }
    }
  }
`;

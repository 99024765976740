import { gql } from '@apollo/client';

export default gql`
  query getEmployeeInformation($input: SSNInput!) {
    getProfileMappingOptions {
      contactType {
        label
        value
      }
      ethnicity {
        label
        value
      }
      gender {
        label
        value
      }
      relationship {
        label
        value
      }
      residentStatus {
        label
        value
      }
      maritalStatus {
        label
        value
      }
    }
    getEmployeeInformation(input: $input) {
      profiles {
        dataSource
        basicInfo {
          idType
          title
          firstName
          middleInitial
          lastName
          employeeId
          socialSecurityMask
          taxId
          birthDate
          gender
          ethnicity
          maritalStatus
          residentStatus
          countryofResidence
          nationality
          dbCode
          gstNumber
          validEmployee
        }
        addresses {
          typeofContact
          firstName
          lastName
          middleInitial
          company
          addressLine1
          addressLine2
          addressLine3
          city
          county
          taxCounty
          state
          zipCode
          country
        }
        emailAddresses {
          typeofContact
          emailAddress
        }
        phoneNumbers {
          typeofContact
          cell
          extension
          countryCode
        }
        directDeposits {
          currencyType
          routingNumber
          bankAccountNumber
          isDirectDeposit
          flatAmount
          percentRate
          splitPercentage
          forCorps
          sequence
        }
        unions {
          unionId
          memberType
          isLockedOut
        }
        alternativeContacts {
          typeofContact
          relationship
          basicInfo {
            name
          }
          addresses {
            typeofContact
            addressLine1
            addressLine2
            addressLine3
            city
            state
            zipCode
          }
          phoneNumbers {
            typeofContact
            cell
            extension
            countryCode
          }
        }
        loanout {
          taxIdNumber
          name
          corpType
          addressLine1
          city
          state
          zipCode
          w9ReceivedDate
          loiReceivedDate
          corpStatus
        }
      }
    }
  }
`;

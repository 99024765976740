import { gql } from '@apollo/client';

export default gql`
  query getUserDashboard {
    getCurrentUser {
      settings
    }
    dashboard {
      user {
        w4Documents {
          country
          stateId
          createdAt
        }
        hasDirectDeposits
        loanout {
          corpStatus
        }
      }
      lastUpdated {
        event
        date
      }
    }
  }
`;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import CalendarIcon from './icons/Calendar';
import TextField from '../TextField';

const useStyles = makeStyles(
  ({ palette }) => ({
    '@global': {
      '.MuiDayCalendar-header': {
        '& .MuiDayCalendar-weekDayLabel': {
          color: palette.cncUI.primary.gray['+6'],
        },
      },
      '.MuiPickersDay-root': {
        borderRadius: 2,
        color: palette.cncUI.default.primary,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&.Mui-selected': {
          color: palette.cncUI.default.primary,
          backgroundColor: `${palette.cncUI.default['+7']} !important`,
          '&:hover': {
            backgroundColor: palette.cncUI.default['+7'],
          },
        },
        '&.MuiPickersDay-today': {
          border: 'none',
          position: 'relative',
          '&:before': {
            content: "' '",
            display: 'block',
            width: 4,
            height: 4,
            borderRadius: 1,
            background: palette.cncUI.default.primary,
            position: 'absolute',
            bottom: 1,
          },
        },
      },
      '.MuiPickersLayout-actionBar': {
        '& .MuiButton-root': {
          color: palette.cncUI.default.primary,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
      '& .MuiFormHelperText-root': {
        color: palette.cncUI.alert.red.primary,
      },
    },
  }),
  { name: 'CncUI-DatePicker' },
);

const DatePicker = props => {
  useStyles();
  const {
    children,
    className,
    light,
    short,
    fullWidth = false,
    clearable = false,
    TextFieldProps = {},
    helperText = '',
    ...other
  } = props;

  const options = {
    className: clsx('cncui-datepicker', className),
  };

  const slotProps = {
    textField: { light, short, fullWidth, ...TextFieldProps },
  };

  if (clearable) {
    slotProps.field = { clearable };
  }

  return (
    <>
      <MuiDatePicker
        format="MM / dd / yyyy"
        slots={{ textField: TextField, openPickerIcon: CalendarIcon }}
        slotProps={slotProps}
        {...options}
        {...other}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
};

export default DatePicker;

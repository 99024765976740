import { gql } from '@apollo/client';

export default gql`
  query getNewCollaborators($email: String!, $projectId: String) {
    getNewCollaborators(email: $email, projectId: $projectId) {
      firstName
      lastName
      title
      oktaStatus
      loginId
      email
      isEditor
      clientSetup
    }
  }
`;

import { createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Observable, from } from '@apollo/client';

// Constants
import dashboardApiUrl from 'constants/config/dashboardApi';

// Utilities
import Tokens from 'providers/Tokens';
import logout from 'utilities/logout';
import requestToken from 'utilities/requestAccessToken';

const dashboardHttpLink = createHttpLink({
  uri: `${dashboardApiUrl}/graphql`,
});

const errorLink = onError(error => {
  const { graphQLErrors = [], networkError = {}, operation = {}, forward } =
    error || {};
  const { getContext } = operation || {};
  const { scope, headers = {} } = getContext() || {};
  const { message: networkErrorMessage = '' } = networkError || {};

  const isLoggedOut = ({ message } = {}) => message === 'login';
  const isForbidden = ({ extensions: { code } = {} } = {}) =>
    ['FORBIDDEN', 'UNAUTHENTICATED'].includes(code);
  const networkFailed = message =>
    typeof message === 'string' &&
    message.startsWith('NetworkError when attempting to fetch resource');

  if (graphQLErrors.some(isLoggedOut)) {
    Tokens.clear();
    logout();
    return;
  }
  if (graphQLErrors.some(isForbidden) && scope === undefined) return;
  if (graphQLErrors.some(isForbidden)) {
    return new Observable(async observer => {
      try {
        const retryRequest = accessToken => {
          operation.setContext({
            headers: {
              ...headers,
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          return forward(operation).subscribe(subscriber);
        };

        try {
          const token = await requestToken(scope);
          return retryRequest(token);
        } catch (e) {
          return forward(operation);
        }
      } catch (e) {
        observer.error(e);
      }
    });
  }
  if (networkFailed(networkErrorMessage)) return forward(operation);
});

const link = from([errorLink, dashboardHttpLink]);

export default link;

import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import MaskedInput, { conformToMask } from 'react-text-mask';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ClearIcon from '@mui/icons-material/HighlightOff';
import TextField from './TextField';

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.cncUI.primary.gray['+0'],
    '& svg': {
      width: 20,
      height: 20,
      marginBottom: 2,
    },
  },
  clear: {
    color: palette.cncUI.primary.gray['+2'],
    cursor: 'pointer',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));

const SSN_MASK = [
  /\d|\*/,
  /\d|\*/,
  /\d|\*/,
  '-',
  /\d|\*/,
  /\d|\*/,
  '-',
  /\d|\*/,
  /\d|\*/,
  /\d|\*/,
  /\d|\*/,
];

const TextFieldSsn = props => {
  const classes = useStyles();
  const {
    value,
    toggle,
    startIcon,
    onChange = () => {},
    onClear = null,
    InputProps = {},
    showAll = false,
    ...other
  } = props;
  const [ssn, setSsn] = useState(value);
  const [show, setShow] = useState(showAll);
  const newInputProps = {};

  useEffect(() => {
    if (value === '') {
      setSsn('');
    }
  }, [value]);

  const maskValue = raw => {
    const masked = [];
    for (let i = 0; i < raw.length; i++) {
      if ([3, 6].includes(i)) masked.push('-');
      if ([0, 1, 2, 4, 5].includes(i)) masked.push(raw[i] === '_' ? '_' : '*');
      if ([7, 8, 9, 10].includes(i)) masked.push(raw[i]);
    }
    return masked.join('');
  };

  const update = event => {
    if (show) {
      setSsn(event.target.value);
      onChange(event);
      return;
    }

    const raw = event.target.value;
    const digits = raw.replace(/\D/g, '');
    const index = raw.indexOf('_');
    const indexSsn = ssn.indexOf('_');
    const sub = index > -1 ? raw.slice(0, index) : raw;
    const subSsn = indexSsn > -1 ? ssn.slice(0, indexSsn) : ssn;
    let newSsn = ssn;

    if (sub.length < subSsn.length) {
      newSsn = conformToMask(ssn.slice(0, sub.length), SSN_MASK, {
        guide: true,
      });
    } else if (sub.length > subSsn.length) {
      newSsn = conformToMask(
        `${ssn.replace(/\D/g, '')}${digits.slice(
          (sub.length - subSsn.length) * -1,
        )}`,
        SSN_MASK,
        { guide: true },
      );
    }

    if (newSsn.conformedValue) {
      setSsn(newSsn.conformedValue);
      onChange({
        ...event,
        target: { ...event.target, value: newSsn.conformedValue },
      });
    }
  };

  if (startIcon) {
    newInputProps.startAdornment = (
      <InputAdornment position="start" className={classes.icon}>
        {startIcon}
      </InputAdornment>
    );
  }

  if (toggle || onClear) {
    newInputProps.endAdornment = (
      <InputAdornment position="end" className={classes.icon}>
        {toggle && (
          <IconButton
            size="small"
            aria-label="toggle ssn visibility"
            onClick={() => setShow(!show)}
            onMouseDown={e => e.preventDefault()}
          >
            {!show ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        )}
        {onClear && (
          <IconButton
            size="small"
            aria-label="clear field"
            onClick={onClear}
            onMouseDown={e => e.preventDefault()}
            className={classes.clear}
          >
            <ClearIcon />
          </IconButton>
        )}
      </InputAdornment>
    );
  }

  return (
    <MaskedInput
      keepCharPositions
      mask={SSN_MASK}
      value={show ? ssn : maskValue(ssn)}
      onChange={update}
      render={(ref, props) => (
        <TextField
          placeholder="___-__-____"
          {...other}
          {...props}
          inputRef={ref}
          InputProps={{
            ...InputProps,
            ...newInputProps,
          }}
        />
      )}
    />
  );
};

export default TextFieldSsn;

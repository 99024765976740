import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import DialogContent from '@mui/material/DialogContent';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '8px 16px',
    },
    noPadding: {
      padding: 0,
    },
  }),
  { name: 'CncUI-SheetContent' },
);

const ModalContent = props => {
  const classes = useStyles();
  const { children, className, noPadding = false, ...other } = props;
  const options = {
    className: clsx(
      'cncui-sheet-content',
      { [classes.noPadding]: noPadding },
      classes.root,
      className,
    ),
  };

  return (
    <DialogContent {...options} {...other}>
      {children}
    </DialogContent>
  );
};

export default ModalContent;

import { gql } from '@apollo/client';

export default gql`
  query getMajorClients {
    getMajorClients {
      clientId
      majorName
      dbCode
      displayCode
    }
  }
`;

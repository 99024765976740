import { gql } from '@apollo/client';

export default gql`
  query getYearToDate($byMonth: Boolean, $byProject: Boolean, $year: String) {
    yearToDate(byMonth: $byMonth, byProject: $byProject, year: $year) {
      employeeName
      grossEarnings
      netEarnings
      employeeTaxes
      hoursWorked
      currency
      year
      month
      monthNum
      projectNumber
      projectName
      source
    }
  }
`;

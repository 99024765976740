import { gql } from '@apollo/client';

export default gql`
  query getMyMinors {
    getCurrentUser {
      id
      minors {
        id
        data
        createdAt
        documentId
        lastFourSsn
      }
    }
  }
`;

import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import MuiTab from '@mui/material/Tab';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      color: palette.cncUI.primary.gray['+0'],
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
      textTransform: 'none',
      opacity: 1,
      borderBottom: '3px solid transparent',
      marginBottom: -2,
      borderTop: '3px solid transparent',
      minHeight: 50,
      '&.Mui-selected': {
        color: palette.cncUI.default.primary,
      },
      '&:hover': {
        borderBottomColor: palette.cncUI.default.primary,
      },
    },
  }),
  'CncUI-Tab',
);

const Tab = props => {
  const classes = useStyles();
  const { children, className, ...other } = props;
  const options = {
    className: clsx('cncui-tab', classes.root, className),
  };

  return <MuiTab {...options} {...other} label={children} />;
};

export default Tab;

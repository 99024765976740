import { gql } from '@apollo/client';

export default gql`
  query getLoanoutAttempts {
    getLoanoutAttempts {
      taxId
      valid
      input
      error
      createdAt
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getOktaGroups {
    getOktaGroups {
      name
      description
      id
    }
  }
`;

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import OffIcon from '@mui/icons-material/HighlightOff';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from 'components/CncUI/Checkbox';
import TextField from 'components/CncUI/TextField';

const TextFields = () => {
  const [light, setLight] = useState(true);
  const [short, setShort] = useState(false);
  const [value, setValue] = useState('Text to clear');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Text Fields</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={light}
              onChange={e => setLight(e.target.checked)}
            >
              Light TextFields
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={short}
              onChange={e => setShort(e.target.checked)}
            >
              Short TextFields
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Regular</h3>
      </Grid>
      <Grid item>
        <TextField placeholder="Placeholder text" light={light} short={short} />
      </Grid>
      <Grid item>
        <TextField value="Actual inputted text" light={light} short={short} />
      </Grid>
      <Grid item>
        <TextField
          disabled
          placeholder="Placeholder text"
          light={light}
          short={short}
        />
      </Grid>
      <Grid item>
        <TextField
          disabled
          value="Actual inputted text"
          light={light}
          short={short}
        />
      </Grid>
      <Grid item>
        <TextField
          warning
          light={light}
          short={short}
          placeholder="Placeholder text"
          helperText="A valid value is required"
        />
      </Grid>
      <Grid item>
        <TextField
          warning
          light={light}
          short={short}
          value="Actual inputted text"
          helperText="A valid value is required"
        />
      </Grid>
      <Grid item>
        <TextField
          error
          light={light}
          short={short}
          placeholder="Placeholder text"
          helperText="A valid value is required"
        />
      </Grid>
      <Grid item>
        <TextField
          error
          light={light}
          short={short}
          value="Actual inputted text"
          helperText="A valid value is required"
        />
      </Grid>
      <Grid item>
        <TextField
          light={light}
          short={short}
          value={value}
          onChange={e => setValue(e.target.value)}
          onClear={() => setValue('')}
        />
      </Grid>
      <Grid item>
        <TextField
          placeholder="Loading text field"
          light={light}
          short={short}
          loading
        />
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">With Labels</h3>
      </Grid>
      <Grid item>
        <TextField
          light={light}
          short={short}
          label="TextField Label"
          placeholder="Placeholder text"
          startIcon={<AccountCircleIcon />}
        />
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">With Icons</h3>
      </Grid>
      <Grid item>
        <TextField
          light={light}
          short={short}
          placeholder="Placeholder text"
          startIcon={<AccountCircleIcon />}
        />
      </Grid>
      <Grid item>
        <TextField
          light={light}
          short={short}
          value="Actual inputted text"
          startIcon={<AccountCircleIcon />}
        />
      </Grid>
      <Grid item>
        <TextField
          error
          light={light}
          short={short}
          placeholder="Placeholder text"
          startIcon={<SearchIcon />}
        />
      </Grid>
      <Grid item>
        <TextField
          error
          light={light}
          short={short}
          value="Actual inputted text"
          startIcon={<SearchIcon />}
        />
      </Grid>
      <Grid item>
        <TextField
          light={light}
          short={short}
          placeholder="Placeholder text"
          endIcon={<OffIcon />}
        />
      </Grid>
      <Grid item>
        <TextField
          light={light}
          short={short}
          value="Actual inputted text"
          endIcon={<OffIcon />}
        />
      </Grid>
      <Grid item>
        <TextField
          light={light}
          short={short}
          placeholder="Placeholder text"
          startIcon={<AccountCircleIcon />}
          endIcon={<CheckIcon style={{ color: '#1A55FD' }} />}
        />
      </Grid>
      <Grid item>
        <TextField
          light={light}
          short={short}
          value="Actual inputted text"
          startIcon={<AccountCircleIcon />}
          endIcon={<CheckIcon style={{ color: '#1A55FD' }} />}
        />
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">Multiline TextFields</h3>
      </Grid>
      <Grid item>
        <TextField
          multiline
          minRows={4}
          placeholder="Placeholder text"
          light={light}
          short={short}
        />
      </Grid>
      <Grid item>
        <TextField
          multiline
          minRows={4}
          value="Actual inputted text"
          light={light}
          short={short}
        />
      </Grid>
      <Grid item>
        <TextField
          disabled
          multiline
          minRows={4}
          placeholder="Placeholder text"
          light={light}
          short={short}
        />
      </Grid>
      <Grid item>
        <TextField
          disabled
          multiline
          minRows={4}
          value="Actual inputted text"
          light={light}
          short={short}
        />
      </Grid>
      <Grid item>
        <TextField
          warning
          multiline
          minRows={4}
          light={light}
          short={short}
          placeholder="Placeholder text"
          helperText="A valid value is required"
        />
      </Grid>
      <Grid item>
        <TextField
          warning
          multiline
          minRows={4}
          light={light}
          short={short}
          value="Actual inputted text"
          helperText="A valid value is required"
        />
      </Grid>
      <Grid item>
        <TextField
          error
          multiline
          minRows={4}
          light={light}
          short={short}
          placeholder="Placeholder text"
          helperText="A valid value is required"
        />
      </Grid>
      <Grid item>
        <TextField
          error
          multiline
          minRows={4}
          light={light}
          short={short}
          value="Actual inputted text"
          helperText="A valid value is required"
        />
      </Grid>
    </Grid>
  );
};

export default TextFields;

import { gql } from '@apollo/client';

export default gql`
  query getUserTaxDocuments($ssn: String, $email: String) {
    getUser(ssn: $ssn, email: $email) {
      id
      taxDocuments {
        id
        name
        year
        employer
        fileCreatedAt
      }
      taxDocumentLogEntries {
        time
        taxDocumentId
        action
        actor
        oldAttributes
        newAttributes
      }
    }
    w4Documents: getUserW4Documents(ssn: $ssn, email: $email) {
      id
      documentId
      name
      stateId
      status
      createdAt
      lastLevelHistoryProcessed
    }
    td1Documents: getUserW4Documents(ssn: $ssn, email: $email, country: "CAN") {
      id
      documentId
      name
      stateId
      status
      createdAt
      lastLevelHistoryProcessed
    }
  }
`;

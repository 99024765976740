export default (rows, columns) => {
  const data = [];
  rows.forEach(row => {
    const rowData = { __data: row };
    columns.forEach(column => {
      let value = row[column.field];
      let sortValue = row[column.field];
      if (typeof column.value === 'function') {
        value = column.value(row);
      }
      if (typeof column.sortValue === 'function') {
        sortValue = column.sortValue(row);
      }
      rowData[column.field] = {
        value,
        column,
        sortValue,
      };
    });
    data.push(rowData);
  });
  return data;
};

import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { Queries, dashboardClient } from 'apollo';
import useAuthContext from './useAuthContext';

const useQuery = (query, props = {}) => {
  const {
    scopes = 'openid',
    request = () => ({}),
    response = res => res,
    isDashboard = false,
    ...other
  } = props;
  const context = useAuthContext(scopes);
  const client = !isDashboard ? useApolloClient() : dashboardClient;
  const getQuery = useCallback(
    (...args) =>
      client
        .query({
          ...other,
          query: Queries[query],
          variables: request(...args),
          context,
        })
        .then(response),
    [client, context],
  );
  return getQuery;
};

export default useQuery;

import { gql } from '@apollo/client';

export default gql`
  query getProjectAttachmentUrl(
    $projectId: String!
    $id: String!
    $name: String
  ) {
    getProjectAttachmentUrl(projectId: $projectId, id: $id, name: $name)
  }
`;

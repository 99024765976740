import clsx from 'clsx';
import Button from '../Button';

const SnackbarAction = props => {
  const { className, children, ...other } = props;
  const options = {
    className: clsx('cncui-snackbar-action', className),
  };
  return (
    <Button short tertiary {...options} {...other}>
      {children}
    </Button>
  );
};

export default SnackbarAction;

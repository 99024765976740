import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Dropdown from 'components/CncUI/Dropdown';
import Checkbox from 'components/CncUI/Checkbox';

const Checkboxes = () => {
  const [light, setLight] = useState(false);
  const [size, setSize] = useState('normal');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Checkboxes</h2>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Dropdown
              light
              label="Size"
              value={size}
              onChange={e => setSize(e.target.value)}
            >
              <Dropdown.Item value="normal">Normal</Dropdown.Item>
              <Dropdown.Item value="short">Short</Dropdown.Item>
              <Dropdown.Item value="compact">Compact</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <Checkbox
              checked={light}
              onChange={e => setLight(e.target.checked)}
            >
              Light checkboxes
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">Without label</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox light={light} size={size} />
          </Grid>
          <Grid item>
            <Checkbox defaultChecked light={light} size={size} />
          </Grid>
          <Grid item>
            <Checkbox disabled light={light} size={size} />
          </Grid>
          <Grid item>
            <Checkbox disabled checked light={light} size={size} />
          </Grid>
          <Grid item>
            <Checkbox defaultChecked indeterminate light={light} size={size} />
          </Grid>
          <Grid item>
            <Checkbox
              checked
              disabled
              indeterminate
              light={light}
              size={size}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">With label</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox light={light} size={size}>
              Checkbox label
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox defaultChecked light={light} size={size}>
              Default checked
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox disabled light={light} size={size}>
              Disable
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox disabled checked light={light} size={size}>
              Disable checked
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox defaultChecked indeterminate light={light} size={size}>
              Indeterminate
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox checked disabled indeterminate light={light} size={size}>
              Disable indeterminate
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">Label position</h3>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <Checkbox labelPosition="top" light={light} size={size}>
            Top position
          </Checkbox>
        </Grid>
        <Grid item>
          <Checkbox labelPosition="right" light={light} size={size}>
            Right position
          </Checkbox>
        </Grid>
        <Grid item>
          <Checkbox labelPosition="bottom" light={light} size={size}>
            Bottom position
          </Checkbox>
        </Grid>
        <Grid item>
          <Checkbox labelPosition="left" light={light} size={size}>
            Left position
          </Checkbox>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Checkboxes;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiLink from '@mui/material/Link';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      color: palette.cncUI.misc.link,
      cursor: 'pointer',
    },
    alternative: {
      color: palette.cncUI.default.primary,
    },
  }),
  { name: 'CncUI-Link' },
);

const Link = props => {
  const classes = useStyles();
  const { className, children, alternative = false, ...other } = props;
  const options = {
    underline: 'none',
    className: clsx(
      'cncui-link',
      classes.root,
      alternative && classes.alternative,
      className,
    ),
  };
  return (
    <MuiLink {...options} {...other}>
      {children}
    </MuiLink>
  );
};

export default Link;

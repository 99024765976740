import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import SortLabel from './SortLabel';
import Cell from './Cell';

const useStyles = makeStyles(
  () => ({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
  { name: 'CncUI-TableHeadCell' },
);

const HeadCell = props => {
  const classes = useStyles();
  const {
    label = '',
    className,
    column = '',
    sort = '',
    order = 'asc',
    onClick = () => {},
    SortLabelComponent = SortLabel,
    ...other
  } = props;
  const active = (column && sort && column === sort) || false;
  const direction = ['asc', 'desc'].includes(order) ? order : 'asc';
  const options = {
    className: clsx('cncui-table-head-cell', classes.root, className),
    sortDirection: active ? direction : false,
  };
  return (
    <Cell {...options} {...other}>
      <SortLabelComponent
        active={active}
        direction={direction}
        onClick={() => onClick(column)}
      >
        {label}
        {active ? (
          <span className={classes.visuallyHidden}>
            {direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </SortLabelComponent>
    </Cell>
  );
};

export default HeadCell;

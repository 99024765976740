import MaskedInput from 'react-text-mask';
import TextField from './TextField';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const TextFieldCurrency = props => {
  const {
    startIcon,
    endIcon,
    onChange = () => {},
    onBlur = () => {},
    InputProps = {},
    maskOptions,
    newInputProps,
    ...other
  } = props;
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <MaskedInput
      onChange={onChange}
      onBlur={onBlur}
      mask={currencyMask}
      render={(ref, props) => (
        <TextField
          startIcon={startIcon}
          endIcon={endIcon}
          placeholder="$ .00"
          {...other}
          {...props}
          inputRef={ref}
          InputProps={{
            ...InputProps,
            ...newInputProps,
          }}
        />
      )}
    />
  );
};

export default TextFieldCurrency;

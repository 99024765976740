import { gql } from '@apollo/client';

export default gql`
  query getProjectHistory($projectId: String!) {
    getProjectHistoryAdmin(projectId: $projectId) {
      id
      projectId
      loginId
      user
      update
      previousValue
      createdAt
    }
  }
`;

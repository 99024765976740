import { gql } from '@apollo/client';

export default gql`
  query searchAccount($input: SearchAccountInput!) {
    searchAccount(input: $input) {
      flow
      addresses
    }
  }
`;

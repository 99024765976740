import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '../Typography';
import Chip from 'components/CncUI/Chip';
import { FormHelperText } from '@mui/material';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

const useStyles = makeStyles(
  ({ palette, spacing }) => ({
    group: {
      display: 'flex',
      flexDirection: 'column',
    },
    horizontal: {
      flexDirection: 'row',
    },
    leftLabel: {
      textAlign: 'left',
    },
    centerLabel: {
      textAlign: 'center',
    },
    rightLabel: {
      textAlign: 'right',
    },
    error: {
      '& .MuiFormHelperText-root, p': {
        marginTop: spacing(1),
        color: palette.cncUI.alert.red.primary,
      },
    },
    fullWidth: {
      '& div': {
        width: '100%',
      },
    },
    box: {
      overflow: 'auto',
      background: palette.cncUI.primary.gray['+9'],
      padding: '8px 16px',
    },
  }),
  { name: 'CncUI-ChipGroup' },
);

const ChipGroup = props => {
  const classes = useStyles();
  const {
    children,
    className,
    style = {},
    label = '',
    labelAlign = 'left',
    labelProps = {},
    value = null,
    setValue = () => {},
    error = true,
    helperText = '',
    fullWidth = false,
    direction = 'vertical',
    type = 'single',
    data = [],
    iconRight = null,
    icon = null,
    readOnly = false,
    deselect = false,
    disableSelectedValue = false,
    ...other
  } = props;
  const BLANK_CHOICES = [...data].map(c => ({
    value: c,
    selected: false,
  }));
  const CURRENT_CHOICES =
    value &&
    [...data].map(c => ({
      value: c,
      selected: Array.isArray(value) ? value.includes(c) : value === c,
    }));
  const STARTING_CHOICES = value ? [...CURRENT_CHOICES] : [...BLANK_CHOICES];
  const [singleSelected, setSingleSelected] = useState([...STARTING_CHOICES]);
  const [multiSelected, setMultiSelected] = useState([...STARTING_CHOICES]);
  const isSingle = type === 'single';
  const dataSelected = isSingle ? singleSelected : multiSelected;
  const useIcon = icon ? icon : isSingle ? <CheckIcon /> : <RemoveIcon />;

  const handleSingleSelect = index => {
    const latest = [...singleSelected].map(c => ({
      value: c.value,
      selected: false,
    }));
    latest[index].selected = true;
    setSingleSelected(latest);
    setValue(latest.find(l => l.selected === true)?.value);
  };
  const handleMultiSelect = index => {
    const latest = [...multiSelected];
    latest[index].selected = !latest[index].selected;
    setMultiSelected(latest);
    setValue(latest.filter(l => l.selected).map(m => m.value));
  };

  const handleSelect = index => {
    if (type === 'single') {
      handleSingleSelect(index);
    } else {
      handleMultiSelect(index);
    }
  };

  const options = {
    className: clsx(
      classes.group,
      classes.root,
      direction === 'horizontal' && classes.horizontal,
      fullWidth && classes.fullWidth,
      className,
    ),
  };
  const selectHandling = index => {
    if (deselect) {
      dataSelected.map(a => (a.selected = false));
    } else {
      return dataSelected?.[index]?.selected;
    }
  };
  const control = (
    <div {...options} {...other}>
      {data.map((val, index) => (
        <Chip
          label={val}
          key={index}
          value={val}
          type={type}
          selected={selectHandling(index)}
          startIcon={!iconRight && useIcon}
          endIcon={(iconRight && useIcon) || null}
          onClick={() => !readOnly && handleSelect(index)}
          disableSelectedValue={disableSelectedValue}
          disabled={(readOnly && value !== val) || disableSelectedValue}
        />
      ))}
    </div>
  );

  if (label) {
    return (
      <FormControl
        variant="standard"
        component="fieldset"
        className={clsx(error && classes.error, fullWidth && classes.fullWidth)}
      >
        <FormLabel
          component="legend"
          className={clsx(
            'cncui-chip-group-label',
            classes[`${labelAlign}Label`],
          )}
        >
          <Typography variant="body2" short {...labelProps}>
            {label}
          </Typography>
        </FormLabel>
        {control}
        {error ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }

  return (
    <div
      className={clsx(error && classes.error, fullWidth && classes.fullWidth)}
    >
      {control}
      {error ? <FormHelperText>{helperText}</FormHelperText> : null}
    </div>
  );
};

export default ChipGroup;

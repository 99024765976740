import { gql } from '@apollo/client';

export default gql`
  query getUserIdentities($lastFourSsn: String, $email: String) {
    getUserIdentities(lastFourSsn: $lastFourSsn, email: $email) {
      createdAt
      cognitoId
      cognitoProfileId
      loginId
      status
      score
      input
      data
      threshold
      address
      assessments
      source
      error
      errorLogs {
        time
        reason
        path
      }
    }
  }
`;

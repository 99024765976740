import { gql } from '@apollo/client';

export default gql`
  query getMinorW2OptIn($id: String!) {
    getCurrentUser {
      minor(id: $id) {
        w2OptIn
      }
    }
  }
`;

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Tag from 'components/CncUI/Tag';
import Checkbox from 'components/CncUI/Checkbox';

const Tags = () => {
  const [short, setShort] = useState(false);
  const [onClose, setOnClose] = useState(false);

  const close = onClose ? () => {} : null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Tags</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={short}
              onChange={e => setShort(e.target.checked)}
            >
              Short
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={onClose}
              onChange={e => setOnClose(e.target.checked)}
            >
              Close icon
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Tag color="blue" short={short} onClose={close}>
              Blue Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="brightBlue" short={short} onClose={close}>
              Bright Blue Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="red" short={short} onClose={close}>
              Red Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="orange" short={short} onClose={close}>
              Orange Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="teal" short={short} onClose={close}>
              Teal Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="seafoam" short={short} onClose={close}>
              Seafoam Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="lavender" short={short} onClose={close}>
              Lavender Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="rose" short={short} onClose={close}>
              Rose Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="green" short={short} onClose={close}>
              Green Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="yellow" short={short} onClose={close}>
              Yellow Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag color="gray" short={short} onClose={close}>
              Gray Tag
            </Tag>
          </Grid>
          <Grid item>
            <Tag disabled short={short} onClose={close}>
              Disabled Tag
            </Tag>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tags;

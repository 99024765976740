import { gql } from '@apollo/client';

export default gql`
  query getMyW4Locks {
    getCurrentUser {
      w4Locks {
        payroll
        caps
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation verifyEmployee(
    $ssn: String!
    $firstName: String!
    $lastName: String!
    $dob: String!
    $email: String!
    $address: String!
    $recaptchaToken: String!
    $fingerprint: String!
    $countryFlag: String
    $idType: String
    $isMinor: Boolean
    $affidavit: String
    $guardianFirstName: String
    $guardianLastName: String
  ) {
    verifyEmployee(
      ssn: $ssn
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      email: $email
      address: $address
      recaptchaToken: $recaptchaToken
      fingerprint: $fingerprint
      countryFlag: $countryFlag
      idType: $idType
      isMinor: $isMinor
      affidavit: $affidavit
      guardianFirstName: $guardianFirstName
      guardianLastName: $guardianLastName
    )
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getPlatformCodes($dbCode: String!) {
    getPlatformCodes(dbCode: $dbCode) {
      value
      description
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getQRCode($token: String) {
    getQRCode(token: $token) {
      id
      front
      back
      live
      type
      visited
      createdAt
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation verifyIdentity($input: VerifyIdentityInput!, $verify: Boolean) {
    verifyIdentity(input: $input, verify: $verify) {
      match
      attempts
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getProfile {
    getCurrentUser {
      employeeProfiles {
        basicInfo {
          idType
          firstName
          lastName
          middleInitial
          socialSecurityMask
          birthDate
          ethnicity
          gender
          taxId
          dbCode
        }
        emailAddresses {
          emailAddress
        }
        addresses {
          city
          state
          addressLine1
          addressLine2
          addressLine3
          typeofContact
          zipCode
          country
        }
        phoneNumbers {
          id
          typeofContact
          cell
        }
      }
    }
  }
`;

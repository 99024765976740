import { gql } from '@apollo/client';

export default gql`
  query getUnifiedDashboard(
    $byMonth: Boolean
    $byProject: Boolean
    $year: String
  ) {
    paystubs(year: $year) {
      employeeName
      checkSequence
      source
      projectNames
      jobDescriptions
      weekStartDate
      weekStartingDate
      weekEndingDate
      weekEndDate
      payPeriodStart
      payPeriodEnd
      checkPayPeriodOverride
      grossEarnings
      netEarnings
      hoursWorked
      currency
      dbCode
      prSystem
      year
    }
    yearToDate(byMonth: $byMonth, byProject: $byProject, year: $year) {
      employeeName
      grossEarnings
      netEarnings
      employeeTaxes
      hoursWorked
      currency
      year
      month
      monthNum
      projectNumber
      projectName
      source
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getPaystubImage($country: String, $checkSequence: String) {
    getCurrentUser {
      paystubs(country: $country, checkSequence: $checkSequence) {
        checkImage
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getFactors {
    getCurrentUser {
      factors {
        id
        type
        provider
        embedded
      }
    }
  }
`;

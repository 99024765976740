import { Children, cloneElement, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import useHover from 'hooks/useHover';
import Typography from '../Typography';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      '& .MuiChip-root': {
        marginBottom: 0,
      },
    },
    error: {
      color: palette.cncUI.alert.red.primary,
    },
  }),
  { name: 'CncUI-HoverChipGroup' },
);

/**
 * HoverGroup component: A group of Chips that display a message when hovered, can have a default one for when no Chip
 * is hovered, Override props for the Chip container Grid and the Typography caption.
 * To use it, wrap the Chips you want to display in the group with this component.
 * And configure the list of messages in the same order as the Chips.
 * @param {Array[String]} config Array of messages to display on hover, same order as chips declared as children
 * @param {String} defaultCaption Default message to display when no Chip is hovered
 * @param {Object} containerProps Props to pass to the Chip container Grid component
 * @param {Object} captionProps Props to pass to the Typography caption component
 * @param {Boolean} fillContainer If true, Chips will fill the container width
 * @param {Boolean} error If true, caption will be styled as error (color theme: red)
 * @returns Node
 */
const HoverGroup = ({
  children,
  config,
  defaultCaption,
  containerProps = {},
  captionProps = {},
  fillContainer = false,
  selected,
  error = false,
  errorMessage,
  style,
}) => {
  const classes = useStyles();
  const chipList = Children.toArray(children);
  const refList = chipList.map(() => useRef());
  const hovered = refList.map(ref => useHover(ref));
  const messageIdx = hovered.findIndex(hover => Boolean(hover));
  const idx = messageIdx === -1 ? selected : messageIdx;
  const message = config?.[idx] || defaultCaption || '\u00A0';

  const { className: captionClass, ...captionRest } = captionProps;

  const captionClasses = clsx(
    {
      'CncUI-HoverChipGroup-caption': true,
      [classes.error]: error,
    },
    captionClass,
  );

  return (
    <Grid container className={classes.root} style={style}>
      <Grid item xs={12}>
        <Grid container {...containerProps}>
          {Children.map(chipList, (child, index) => (
            <Grid item flexGrow={fillContainer ? 1 : 0}>
              {/* Utilized cloneElement to pass newly created 
              ref to child element, requires the children to be 
              Chip component as those are wrapped in a forwardRef */}
              {cloneElement(child, {
                ref: refList?.[index] || null,
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {(errorMessage || message) && (
          <Typography
            variant="caption"
            className={captionClasses}
            {...captionRest}
          >
            {errorMessage || message}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default HoverGroup;

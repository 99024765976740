import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      padding: '4px 8px',
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.32px',
      borderRadius: 8,
      fontWeight: 400,
      '& .cncui-tag-close': {
        cursor: 'pointer',
        verticalAlign: 'middle',
        backgroundColor: 'transparent',
        borderRadius: 8,
        padding: 3,
        fontSize: 14,
        marginTop: -3,
        marginLeft: 4,
        marginRight: -6,
      },
    },
    short: {
      padding: '2px 6px',
      fontSize: 10,
      lineHeight: '14px',
      letterSpacing: '0.42px',
      borderRadius: 6,
      '& .cncui-tag-close': {
        borderRadius: 6,
        padding: 2,
        fontSize: 10,
        marginTop: -2,
        marginLeft: 4,
        marginRight: -4,
      },
    },
    grayColor: {
      color: palette.cncUI.primary.gray['+2'],
      backgroundColor: palette.cncUI.primary.gray['+8'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.primary.gray['+7'],
      },
    },
    redColor: {
      color: palette.cncUI.alert.red['-1'],
      backgroundColor: palette.cncUI.alert.red['+1'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.alert.red['+0'],
      },
    },
    orangeColor: {
      color: palette.cncUI.accent.orange['-2'],
      backgroundColor: palette.cncUI.accent.orange['+2'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.accent.orange['+1'],
      },
    },
    tealColor: {
      color: palette.cncUI.primary.green.primary,
      backgroundColor: palette.cncUI.primary.green['+8'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.primary.green['+7'],
      },
    },
    blueColor: {
      color: palette.cncUI.primary.royalBlue.primary,
      backgroundColor: palette.cncUI.primary.royalBlue['+8'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.primary.royalBlue['+7'],
      },
    },
    royalBlueColor: {
      color: palette.cncUI.white,
      backgroundColor: palette.cncUI.primary.royalBlue.primary,
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.primary.royalBlue['+3'],
      },
    },
    lightBlueColor: {
      color: palette.cncUI.white,
      backgroundColor: palette.cncUI.primary.royalBlue['+6'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.primary.royalBlue['+3'],
      },
    },
    fadedBlueColor: {
      color: palette.cncUI.white,
      backgroundColor: palette.cncUI.primary.royalBlue['+7'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.primary.royalBlue['+6'],
      },
    },
    brightBlueColor: {
      color: palette.cncUI.white,
      backgroundColor: palette.cncUI.primary.royalBlue.primary,
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.primary.royalBlue['-3'],
      },
    },
    seafoamColor: {
      color: palette.cncUI.primary.green['+10'],
      backgroundColor: palette.cncUI.primary.green.primary,
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.primary.green['+6'],
      },
    },
    lavenderColor: {
      color: palette.cncUI.accent.lavender['+0'],
      backgroundColor: palette.cncUI.accent.lavender['+3'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.accent.lavender['+2'],
      },
    },
    roseColor: {
      color: palette.cncUI.accent.rose['+0'],
      backgroundColor: palette.cncUI.accent.rose['+3'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.accent.rose['+2'],
      },
    },
    greenColor: {
      color: palette.cncUI.alert.green['-1'],
      backgroundColor: palette.cncUI.alert.green['+2'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.alert.green['+1'],
      },
    },
    yellowColor: {
      color: palette.cncUI.alert.yellow['-1'],
      backgroundColor: palette.cncUI.alert.yellow['+3'],
      '& .cncui-tag-close:hover': {
        backgroundColor: palette.cncUI.alert.yellow['+2'],
      },
    },
    disabled: {
      color: palette.cncUI.primary.gray['+7'],
      backgroundColor: palette.cncUI.primary.gray['+9'],
      '& .cncui-tag-close:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
  { name: 'CncUI-Tag' },
);

const Tag = props => {
  const classes = useStyles();

  const {
    children,
    className,
    color = 'gray',
    short = false,
    disabled = false,
    onClose = null,
    ...other
  } = props;

  const options = {
    className: clsx(
      'cncui-tag',
      classes.root,
      short && classes.short,
      color && classes[`${color}Color`],
      disabled && classes.disabled,
      className,
    ),
    label: children,
  };

  const close =
    typeof onClose === 'function' ? (
      <CloseIcon onClick={onClose} className="cncui-tag-close" />
    ) : null;

  return (
    <span {...options} {...other}>
      {children} {close}
    </span>
  );
};

export default Tag;

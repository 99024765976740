import { gql } from '@apollo/client';

export default gql`
  query getGarnishments($country: String) {
    getGarnishments(country: $country) {
      garnishmentType
      effectiveDate
      dataSource
      dbCode
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getProjects {
    getProjects {
      id
      name
      loginId
      status
      values
      file
      createdAt
      updatedAt
    }
  }
`;

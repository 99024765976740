import InputMask from 'react-text-mask';
import TextField from 'components/CncUI/TextField';
import { zipMask, zipExtendedMask } from 'constants/masks';

const TextFieldZip = props => {
  const { onChange, value, ...other } = props;
  const mask = value.length > 5 ? zipExtendedMask : zipMask;

  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      render={(ref, props) => (
        <TextField inputRef={ref} {...other} {...props} />
      )}
      guide={false}
    />
  );
};

export default TextFieldZip;

import { gql } from '@apollo/client';

export default gql`
  mutation uploadStatementOfTrustee($input: FileUploadInput!) {
    uploadStatementOfTrustee(input: $input) {
      id
      output
    }
  }
`;

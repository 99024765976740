import { useState } from 'react';
import clsx from 'clsx';
import SnackbarContext from './Context';
import Snackbar from 'components/CncUI/Snackbar';
import DashboardSnackbar from 'components/Dashboard/Snackbar';
import Button from 'components/CncUI/Button';
import Link from 'components/CncUI/Link';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiLink-underlineHover:hover': {
      textDecoration: 'none',
    },
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const SnackbarProvider = props => {
  const classes = useStyles();
  const { children } = props;
  const [alert, setAlert] = useState({
    show: false,
    inline: true,
    position: 'top-center',
    duration: 4500,
    type: 'info',
    title: '',
    message: '',
    className: '',
    style: {},
    help: false,
    dashboard: false,
  });
  const notify = data => {
    setAlert({ ...alert, show: false });
    setTimeout(
      setAlert({
        show: true,
        inline: true,
        position: 'top-center',
        duration: 4500,
        type: 'info',
        title: '',
        message: '',
        className: '',
        style: {},
        ...data,
      }),
      50,
    );
  };

  const Component = alert.dashboard ? DashboardSnackbar : Snackbar;

  return (
    <>
      <Component
        title={alert.title}
        open={alert.show}
        onClose={() => {
          if (alert.onClose) {
            alert.onClose();
          }
          setAlert({ ...alert, show: false });
        }}
        position={alert.position}
        hideDuration={alert.duration}
        inline={alert.inline}
        variant={alert.type}
        style={alert.style}
        data-test-id={`Snackbar-message-${alert.type.toUpperCase()}`}
        className={clsx(classes.root, alert.className)}
      >
        {alert.message}
        {alert.help && (
          <Link
            href={alert.help}
            target="_blank"
            rel="noopener noreferrer"
            justifyContent="flex-end"
            className={classes.link}
          >
            <Button secondary>Get Help</Button>
          </Link>
        )}
      </Component>
      <SnackbarContext.Provider value={notify}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
};

export default SnackbarProvider;

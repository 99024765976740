import { useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Chip from 'components/CncUI/Chip';
import Checkbox from 'components/CncUI/Checkbox';
import CheckIcon from '@mui/icons-material/Check';

import useHover from 'hooks/useHover';

const CHOICE_CHIPS = [
  'This is my top choice 1',
  'No this choice 2 is better',
  'Choice 3 is best',
  'Choice 4 can not be argued with',
];
const Chips = () => {
  const [iconRight, setIconRight] = useState(false);
  const [selected, setSelected] = useState(false);
  const [multiSelect, setMulti] = useState(false);
  const [horizontal, setHorizontal] = useState(false);
  const [fullWidth, setFullWidth] = useState(false);
  const [value, setValue] = useState(null);

  const elementRef = useRef();
  const hovered = useHover(elementRef);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Chips</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={iconRight}
              onChange={e => setIconRight(e.target.checked)}
            >
              Icon on right
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={horizontal}
              onChange={e => setHorizontal(e.target.checked)}
            >
              Horizontal
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={fullWidth}
              onChange={e => setFullWidth(e.target.checked)}
            >
              Full Width
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={multiSelect}
              onChange={e => {
                setMulti(e.target.checked);
                setTimeout(() => setValue(value), 1000);
              }}
            >
              Multi-select
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Chip
              label="Primary"
              onClick={() => setSelected(!selected)}
              selected={selected}
              startIcon={!iconRight && <CheckIcon />}
              endIcon={(iconRight && <CheckIcon />) || null}
            />
          </Grid>
          <Grid item>
            <Chip label="Disabled" disabled />
          </Grid>
          <Grid item>
            <Chip.Group
              label={`${multiSelect ? 'Multi-' : 'Single '}selection chips`}
              type={multiSelect ? 'multi' : 'single'}
              direction={horizontal ? 'horizontal' : 'vertical'}
              setValue={setValue}
              fullWidth={fullWidth}
              data={CHOICE_CHIPS}
              iconRight={iconRight}
            />
          </Grid>
          <Grid item>{Array.isArray(value) ? value.join() : value}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Hover Chips</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item>
                <Chip label="Hover" ref={elementRef} />
              </Grid>
              <Grid item>
                Can inject a ref and use it to track
                <br /> if the chip is Hovered or not: <br />
                <b>{hovered ? 'Hovered' : 'Not Hovered'}</b>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Chip.HoverGroup
                  config={['message 1', 'message 2', 'message 3']}
                  defaultCaption="Hover over a chip to see a message"
                  containerProps={{
                    spacing: 1,
                    sx: { display: 'flex', flexDirection: 'row' },
                  }}
                  // captionProps={{ style: { color: 'red' } }}
                >
                  <Chip label="Chip 1" />
                  <Chip label="Chip 2" />
                  <Chip label="Chip 3" />
                </Chip.HoverGroup>
              </Grid>
              <Grid item xs={6}>
                HoverGroup can render a message depending on which chip is
                hovered
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Chips;

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Dropdown from 'components/CncUI/Dropdown';
import Checkbox from 'components/CncUI/Checkbox';
import Radio from 'components/CncUI/Radio';

const RadioButtons = () => {
  const [light, setLight] = useState(false);
  const [size, setSize] = useState('normal');
  const [value, setValue] = useState('');
  const [position, setPosition] = useState('top');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">RadioButtons</h2>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Dropdown
              light
              label="Size"
              value={size}
              onChange={e => setSize(e.target.value)}
            >
              <Dropdown.Item value="normal">Normal</Dropdown.Item>
              <Dropdown.Item value="short">Short</Dropdown.Item>
              <Dropdown.Item value="compact">Compact</Dropdown.Item>
              <Dropdown.Item value="tall">Tall</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <Checkbox
              checked={light}
              onChange={e => setLight(e.target.checked)}
            >
              Light RadioButtons
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">Without label</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Radio checked={false} light={light} size={size} />
          </Grid>
          <Grid item>
            <Radio checked light={light} size={size} />
          </Grid>
          <Grid item>
            <Radio disabled light={light} size={size} />
          </Grid>
          <Grid item>
            <Radio disabled checked light={light} size={size} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">With label</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Radio checked={false} light={light} size={size}>
              Radio label
            </Radio>
          </Grid>
          <Grid item>
            <Radio checked light={light} size={size}>
              Checked
            </Radio>
          </Grid>
          <Grid item>
            <Radio disabled light={light} size={size}>
              Disable
            </Radio>
          </Grid>
          <Grid item>
            <Radio disabled checked light={light} size={size}>
              Disable checked
            </Radio>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3 className="header">Label position</h3>
      </Grid>
      <Radio.Group value={position} onChange={e => setPosition(e.target.value)}>
        <Grid container spacing={2}>
          <Grid item>
            <Radio labelPosition="top" value="top" light={light} size={size}>
              Top position
            </Radio>
          </Grid>
          <Grid item>
            <Radio
              labelPosition="right"
              value="right"
              light={light}
              size={size}
            >
              Right position
            </Radio>
          </Grid>
          <Grid item>
            <Radio
              labelPosition="bottom"
              value="bottom"
              light={light}
              size={size}
            >
              Bottom position
            </Radio>
          </Grid>
          <Grid item>
            <Radio labelPosition="left" value="left" light={light} size={size}>
              Left position
            </Radio>
          </Grid>
        </Grid>
      </Radio.Group>

      <Grid item xs={12}>
        <h3 className="header">Radio group</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Radio.Group value={value} onChange={e => setValue(e.target.value)}>
              {[1, 2, 3].map(key => (
                <Radio
                  key={key}
                  value={`option${key}`}
                  light={light}
                  size={size}
                >
                  Option {key}
                </Radio>
              ))}
            </Radio.Group>
          </Grid>
          <Grid item>
            <Radio.Group
              row
              value={value}
              onChange={e => setValue(e.target.value)}
            >
              {[1, 2, 3].map(key => (
                <Radio
                  key={key}
                  value={`option${key}`}
                  light={light}
                  size={size}
                >
                  Option {key}
                </Radio>
              ))}
            </Radio.Group>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RadioButtons;

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Icon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from 'components/CncUI/Checkbox';
import Button from 'components/CncUI/Button';

const Buttons = () => {
  const [short, setShort] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Buttons</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={short}
              onChange={e => setShort(e.target.checked)}
            >
              Short button size
            </Checkbox>
          </Grid>
          <Grid item>
            <Checkbox
              checked={loading}
              onChange={e => setLoading(e.target.checked)}
            >
              Loading buttons
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Regular Buttons</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button short={short} loading={loading}>
              Primary
            </Button>
          </Grid>
          <Grid item>
            <Button disabled short={short} loading={loading}>
              Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button secondary short={short} loading={loading}>
              Secondary
            </Button>
          </Grid>
          <Grid item>
            <Button secondary disabled short={short} loading={loading}>
              Secondary Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button tertiary short={short} loading={loading}>
              Tertiary
            </Button>
          </Grid>
          <Grid item>
            <Button tertiary disabled short={short} loading={loading}>
              Tertiary Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button danger short={short} loading={loading}>
              Danger
            </Button>
          </Grid>
          <Grid item>
            <Button danger disabled short={short} loading={loading}>
              Danger Disabled
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Buttons with Icons</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button startIcon={<Icon />} short={short}>
              Primary
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<Icon />} disabled short={short}>
              Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<Icon />} secondary short={short}>
              Secondary
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<Icon />} secondary disabled short={short}>
              Secondary Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<Icon />} tertiary short={short}>
              Tertiary
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<Icon />} tertiary disabled short={short}>
              Tertiary Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<Icon />} danger short={short}>
              Danger
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<Icon />} danger disabled short={short}>
              Danger Disabled
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button endIcon={<Icon />} short={short}>
              Primary
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<Icon />} disabled short={short}>
              Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<Icon />} secondary short={short}>
              Secondary
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<Icon />} secondary disabled short={short}>
              Secondary Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<Icon />} tertiary short={short}>
              Tertiary
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<Icon />} tertiary disabled short={short}>
              Tertiary Disabled
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<Icon />} danger short={short}>
              Danger
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<Icon />} danger disabled short={short}>
              Danger Disabled
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Icon Buttons</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button icon short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button icon disabled short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button circle short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button circle disabled short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button circle short={short}>
              ##
            </Button>
          </Grid>
          <Grid item>
            <Button circle disabled short={short}>
              ##
            </Button>
          </Grid>
          <Grid item>
            <Button icon secondary short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button icon secondary disabled short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button secondary circle short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button secondary circle disabled short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button secondary circle short={short}>
              ##
            </Button>
          </Grid>
          <Grid item>
            <Button secondary circle disabled short={short}>
              ##
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button icon tertiary short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button icon tertiary disabled short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button tertiary circle short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button tertiary circle disabled short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button tertiary circle short={short}>
              ##
            </Button>
          </Grid>
          <Grid item>
            <Button tertiary circle disabled short={short}>
              ##
            </Button>
          </Grid>
          <Grid item>
            <Button icon danger short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button icon danger disabled short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button danger circle short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button danger circle disabled short={short}>
              <EditIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button danger circle short={short}>
              ##
            </Button>
          </Grid>
          <Grid item>
            <Button danger circle disabled short={short}>
              ##
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Icon Buttons with Notification</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button circle notify short={short}>
              <Icon />
            </Button>
          </Grid>
          <Grid item>
            <Button circle notify disabled short={short}>
              <Icon />
            </Button>
          </Grid>
          <Grid item>
            <Button secondary circle notify short={short}>
              <Icon />
            </Button>
          </Grid>
          <Grid item>
            <Button secondary circle notify disabled short={short}>
              <Icon />
            </Button>
          </Grid>
          <Grid item>
            <Button danger circle notify short={short}>
              <Icon />
            </Button>
          </Grid>
          <Grid item>
            <Button danger circle notify disabled short={short}>
              <Icon />
            </Button>
          </Grid>
          <Grid item>
            <Button tertiary circle notify short={short}>
              <Icon />
            </Button>
          </Grid>
          <Grid item>
            <Button tertiary circle notify disabled short={short}>
              <Icon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Buttons;

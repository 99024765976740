import { gql } from '@apollo/client';

export default gql`
  mutation uploadMyFile(
    $name: String!
    $category: String!
    $contentType: String!
    $file: String!
  ) {
    uploadMyFile(
      name: $name
      category: $category
      contentType: $contentType
      file: $file
    ) {
      id
      createdAt
      category
      active
      name
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  query getProductionCompanies(
    $producerId: Int!
    $name: String!
    $dbCode: String!
  ) {
    getProductionCompanies(
      producerId: $producerId
      name: $name
      dbCode: $dbCode
    ) {
      productionCompanyId
      productionCompanyName
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation enrollPhoneFactor($input: OktaEnrollPhoneInput!) {
    enrollPhoneFactor(input: $input) {
      id
      type
      status
      provider
      vendorName
      embedded
    }
  }
`;

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from 'components/CncUI/TextField';
import Dropdown from 'components/CncUI/Dropdown';
import Checkbox from 'components/CncUI/Checkbox';
import Alert from 'components/CncUI/Alert';

const Alerts = () => {
  const [inline, setInline] = useState(false);
  const [severity, setSeverity] = useState('info');
  const [message, setMessage] = useState('Message goes here');
  const [title, setTitle] = useState('This is the title');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Alerts</h2>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Regular Alerts</h3>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert info>Message goes here</Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert success>Message goes here</Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert warning>Message goes here</Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert error>Message goes here</Alert>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Alerts with titles</h3>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert info title="Info">
          Message goes here
        </Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert success title="Success">
          Message goes here
        </Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert warning title="Warning">
          Message goes here
        </Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert error title="Error">
          Message goes here
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Inline Alerts</h3>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert info inline title="Info">
          Message goes here
        </Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert success inline title="Success">
          Message goes here
        </Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert warning inline title="Warning">
          Message goes here
        </Alert>
      </Grid>
      <Grid item xs={6} md={3}>
        <Alert error inline title="Error">
          Message goes here
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <h3 className="header">Build your own</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item>
            <Dropdown
              light
              label="Severity"
              value={severity}
              onChange={e => setSeverity(e.target.value)}
            >
              <Dropdown.Item value="info">Info</Dropdown.Item>
              <Dropdown.Item value="success">Success</Dropdown.Item>
              <Dropdown.Item value="warning">Warning</Dropdown.Item>
              <Dropdown.Item value="error">Error</Dropdown.Item>
              <Dropdown.Item value="toast">Toast</Dropdown.Item>
              <Dropdown.Item value="plain">Plain</Dropdown.Item>
            </Dropdown>
          </Grid>
          <Grid item>
            <TextField
              light
              label="Alert Message"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              light
              label="Alert Title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Checkbox
              checked={inline}
              onChange={e => setInline(e.target.checked)}
            >
              Inline Alert
            </Checkbox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Alert severity={severity} inline={inline} title={title}>
          {message}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default Alerts;

export default {
  //SETUP
  HI_RIGHT_DASHBOARD: 'Hi <<var1>>, let’s find the right dashboard for you!',
  WE_ASK_QUESTIONS:
    'We will ask you a few questions to help us better understand your role and your workflow.',
  PRIMARY_ROLE: 'Primary role',
  SELECT_BEST_TITLE: 'Select the title that best describes your primary role',
  SELECT_ROLE: 'Select a role',
  DASHBOARD_VIEW: 'Dashboard view',
  SELECT_PREFERRED_DEFAULT: 'Select your preferred default dashboard',
  SELECT_VIEW: 'Select a view',
  PAYROLL_DASHBOARD: 'Payroll Dashboard',
  VIEW_PAYROLL_WORKFLOW: 'This view shows the payroll workflow',
  ACCOUNTING_DASHBOARD: 'Accounting Dashboard',
  VIEW_ACCOUNTING_WORKFLOW: 'This view shows the AP accounting workflow ',
  PERSONAL_DASHBOARD: 'Personal Dashboard',
  VIEW_PERSONAL_DATA:
    'This view shows your personal employee pay and offer data',
  SELECT_PROJECT: 'Select a project',
  SELECT_PROJECT_ACCESS: 'Select the project you would like to access',
  TAKE_TO_DASHBOARD: 'Take me to my dashboard!',
  //HERO
  HI: 'Hi ',
  HI_WELCOME: 'Hi <<var1>>, welcome',
  YOUR_DASHBOARD: 'to your dashboard.',
  TODAY_IS: 'Today is <<var1>>,',
  LETS_EXPLORE: "Let's explore what's available for you today.",
  LAST_UPDATED: 'Last updated <<var1>>',
  THIS_IS: 'this is what you have going on.',
  HERE_ARE_SOME: 'Here are some resources you might find useful.',
  HERE_CAN_FIND:
    'Here you can find useful resources to help you manage your tasks.',
  NOTHING: 'Nothing',
  GOT_NOTHING: 'You got nothing going on today.',
  LOAN_OUT_RENEWAL: 'Loan-out Renewal',
  LOAN_OUT_EXPIRED: 'Your loan-out is expired, ...',
  ADDRESS_CONFIRMATION: 'Address confirmation',
  INFORMATION_CORRECT: 'Is your information still correct',
  TD1_RENEWAL: 'TD-1 Renewal',
  TD1_EXPIRED: "Your TD-1 is expired, let's renew",
  PAY_AMOUNTS_TOGGLE: 'Pay Amounts Toggle Switch',
  FOR_YOUR_PRIVACY:
    'For your privacy, pay amounts are initially hidden. You can reveal them anytime using the toggle switch.',
  CONTINUE: 'Continue',
  DONT_SHOW_AGAIN: "Don't show again",
  //OFFER
  OFFERS: 'Offers',
  RECENT_OFFERS: 'Recent Offers',
  SHOWING: 'Showing',
  HIDING: 'Hiding',
  AMOUNTS: 'amounts',
  SHOW_AMOUNTS: 'Show amounts',
  START_ON: 'start on or about',
  HOURLY: 'hourly',
  CURRENCY: 'USD',
  VIEW_OFFERS: 'View all offers',
  RATE: 'Rate',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  NO_OFFERS: 'No digital offers yet',
  DIGITAL_OFFERS: 'Start+ offers',
  NO_DIGITAL_OFFERS:
    "All your Start+ offers will appear here once they're available",
  ONLY_DIGITAL_OFFERS: 'Only Start+ offers will appear here.',
  START_DATE: 'Start Date',
  //PAYSTUB
  RECENT_PAYSTUBS: 'Recent Paystubs',
  PAYSTUBS: 'Paystubs',
  GROSS_PAY: 'Gross Pay',
  TAXES: 'Taxes',
  HOURS: 'Hours',
  CHECK_NO: 'Check No.',
  VIEW_ALL_PAYSTUBS: 'View all paystubs',
  PAY_PERIOD: 'Pay Period',
  WORK_PERIOD: 'Work Period',
  OVERRIDE_PERIOD: 'Override Period',
  PAID: 'Paid',
  NET_PAY: 'Net Pay',
  NO_RECENT_PAYSTUBS: 'No recent paystubs yet',
  NO_PAYSTUBS: 'No pay stubs to show',
  ONCE_RECEIVE_PAYSTUB:
    'Paystubs from Cast & Crew will appear here once they’re available.',
  ONCE_AVAILABLE_CAPS:
    'Paystubs from Cast & Crew and CAPS will appear here once they’re available.',
  PAYSTUBS_SHOWN_HERE:
    "Paystubs shown here reflect only the previous day's activity and will only include paystubs from Cast & Crew and CAPS. To see all your Cast & Crew, CAPS, and Media Services paystubs as of today, check the Pay Stubs page.",
  PAYSTUB_PREVIEW: 'Paystub preview',
  PAYROLL_SERVICE: 'Payroll Service',
  EFFECTIVE_DATE: 'Effective Date',
  NO_GARNISHMENTS: 'No garnishments to show',
  DOWNLOAD_PDF: 'Download PDF',
  ZOOM_IN: 'ZOOM IN',
  ZOOM_OUT: 'ZOOM OUT',
  //TIMECARDS
  TIMECARDS: 'Recent Timecards',
  CREATE: 'Create',
  CREATE_NEW: 'Create New',
  CREATE_TIMECARD: 'Create timecard',
  SELECT_TIMECARD_CREATE: 'Select a project to create a timecard.',
  SELECT_TIMECARD_VIEW: 'Select a project to see all timecards.',
  VIEW_TIMECARDS: 'View all timecards',
  WORK_WEEK: 'Work Week',
  HOURS_WORKED: 'hours worked',
  REVISE: 'Revise',
  SUBMIT: 'Submit',
  REVIEW: 'Review',
  VIEW: 'View',
  DIGITAL_TIMECARDS: 'Hours+ timecards',
  NO_DIGITAL_TIMECARDS: 'No digital timecards yet',
  ONLY_DIGITAL_TIMECARDS:
    "Only Hours+ timecards you've created or that have been created for you will appear here",
  ALL_TIMECARDS_CREATED:
    'All Hours+ timecards, whether created by you or prepared for you, will appear here.',
  SEE_MORE_PROJECTS: 'See more projects',
  SIGN: 'Sign',
  //YTD
  YTD_PAY: 'YTD Pay',
  YEAR_TD_PAY: 'Year-to-Date Pay',
  GROSS_EARNINGS: 'gross earnings',
  TOTAL_LAST_YEAR: 'total last year',
  SO_FAR_THIS_YEAR: 'so far this year',
  YOUR_YTD_DETAILS: 'Your year-to-date pay details',
  YOUR_YTD_SELECTED:
    'Your YTD pay details for the selected year will be displayed here if available.',
  YOUR_YTD_FIRST:
    'Your YTD pay will be displayed here as soon as you receive your first paycheck from Cast & Crew.',
  YOUR_YTD_CAPS:
    'Your YTD pay will be displayed here as soon as you receive your first paycheck from Cast & Crew or CAPS.',
  YTD_PAY_SHOWN:
    'The Year-to-Date (YTD) pay shown here reflects the previous day’s data. Updates from today will appear tomorrow.',
  TOTAL_HOURS: 'Total Hours',
  //My Tasks
  MY_TASKS: 'My Tasks',
  LOAN_OUT_CHECK_IN: 'Loan-out Check-in',
  IS_YOUR_LOANOUT: 'Is your loan-out information still correct?',
  ADD_LOANOUT: 'Add Loan-out',
  OFFER_REQUIRE_SETUP: 'An offer may require this setup',
  ADD_W4: 'Add W-4',
  OFFER_REQUIRE_DOCUMENT: 'An offer may require this document',
  INFORMATION_CHECK_IN: 'Information Check-in',
  BEEN_AWHILE_CORRECT: "It's been a while, is your information correct?",
  DIRECT_DEPOSIT: 'Direct Deposit',
  ELIGIBLE_DIRECT_DEPOSIT: "You're eligible for direct deposit.",
  SETUP: 'Set Up',
  CONFIRM: 'Confirm',
  ADD_FORM: 'Add Form',
  RENEW: 'Renew',
  LOAN_OUT: 'Loan-out',
  W4: 'W-4',
  INFORMATION: 'Information',
  NO_TASKS: 'No tasks to do',
  ALL_CAUGHT_UP: "You're all caught up",
  ANY_NEW_TASKS: 'Any new tasks will appear here for you to complete.',
  W4_RENEWAL: 'W-4 Renewal',
  W4_EXPIRED: 'Your W-4 has expired, please renew your form.',
  TD1: 'TD1',
  ADD_TD1: 'Add TD1',
  // FAQ
  FAQ_TITLE: 'Frequently Asked Questions',
  SEE_MORE: 'See more',
  SEE_LESS: 'See less',
  READ_MORE: 'Read more',
  STILL_HAVE_QUESTIONS: 'Still have questions?',
  STILL_HAVE_QUESTIONS_DESC: `Can't find the answer you're looking for? Tap
    into our self-service help center or reach out for Support.`,
  GO_SELF_SERVICE: 'Go to Self Service',
  GET_IN_TOUCH: 'Get in touch',
  // Validate Account
  VALIDATE_ACCOUNT_TITLE:
    'Need to access your paystubs and tax document forms?',
  VALIDATE_ACCOUNT_DESC: `Click the button below to validate your production
    employee account to get started.`,
  VALIDATE_ACCOUNT: 'Validate my account',
  // Learning Resources
  LR_TITLE: 'Learning Resources',
  TAKE_ME_THERE: 'Take me there',
  LR_STILL_HAVE_QUESTIONS_DESC: `Can't find the answer you're looking for?
    Tap below to reach out for Support.`,
  //Generic
  OOPS: 'Oops!',
  OUR_DATA_ELVES:
    'Our data elves took a coffee break. Try refreshing the page or check back soon.',
  SEARCH_FOR_PROJECT: 'Search for project',
  NO_PROJECTS_FOUND: 'No projects found.',
};

import { gql } from '@apollo/client';

export default gql`
  query getBanksErrors($email: String!) {
    getUserBanks(email: $email) {
      name
      status
    }
    getPlaidErrors(email: $email) {
      createdAt
      linkSessionId
      requestId
      status
      data
    }
  }
`;

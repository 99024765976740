import { gql } from '@apollo/client';

export default gql`
  mutation createTaxDocument(
    $ssn: String!
    $name: String!
    $year: Int!
    $employerName: String!
    $firstName: String!
    $lastName: String!
    $birthDate: String!
    $file: String!
    $sendNotification: Boolean!
    $idType: String
  ) {
    createTaxDocument(
      ssn: $ssn
      name: $name
      year: $year
      employerName: $employerName
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      file: $file
      sendNotification: $sendNotification
      idType: $idType
    )
  }
`;

import clsx from 'clsx';
import TableRow from '@mui/material/TableRow';

const Row = props => {
  const { children, className, ...other } = props;
  const options = {
    className: clsx('cncui-table-row', className),
  };
  return (
    <TableRow {...options} {...other}>
      {children}
    </TableRow>
  );
};

export default Row;

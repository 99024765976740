import { useState } from 'react';
import Grid from '@mui/material/Grid';
import WarningIcon from '@mui/icons-material/Warning';
import Button from 'components/CncUI/Button';
import Modal from 'components/CncUI/Modal';

const Modals = () => {
  const [show, setShow] = useState(false);
  const [iconShow, setIconShow] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Modals</h2>
      </Grid>
      <Grid item>
        <Button onClick={() => setShow(true)}>Open Modal</Button>
        <Modal open={show} onClose={() => setShow(false)}>
          <Modal.Title onClose={() => setShow(false)}>Modal title</Modal.Title>
          <Modal.Content>
            <Modal.ContentText>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Modal.ContentText>
          </Modal.Content>
          <Modal.Actions>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button secondary>Secondary Action</Button>
              </Grid>
              <Grid item>
                <Button>Primary Action</Button>
              </Grid>
            </Grid>
          </Modal.Actions>
        </Modal>
      </Grid>
      <Grid item>
        <Button onClick={() => setIconShow(true)}>Open Modal with Icon</Button>
        <Modal open={iconShow} onClose={() => setIconShow(false)}>
          <Modal.Title warning onClose={() => setIconShow(false)}>
            <WarningIcon /> Modal title
          </Modal.Title>
          <Modal.Content>
            <Modal.ContentText>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Modal.ContentText>
          </Modal.Content>
          <Modal.Actions>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button secondary>Secondary Action</Button>
              </Grid>
              <Grid item>
                <Button>Primary Action</Button>
              </Grid>
            </Grid>
          </Modal.Actions>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default Modals;

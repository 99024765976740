import { useCallback, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { Mutations } from 'apollo';
import useAuthContext from './useAuthContext';

const CHUNK_SIZE = 1024 * 1024 * 4;

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );

const useFileUpload = (mutation, props = {}) => {
  const { scopes = 'openid', response = res => res, ...other } = props;
  const context = useAuthContext(scopes);
  const client = useApolloClient();
  const getMutation = useCallback(
    ({ id, input, body, done }) =>
      client.mutate({
        ...other,
        mutation: Mutations[mutation],
        variables: { input: { id, input, body, done } },
        context,
      }),
    [client, context],
  );

  const trigger = async (id, input, chunks, part) => {
    const body = chunks[part];
    const done = chunks.length - 1 === part;
    const response = await getMutation({ id, input, body, done });
    if (!response.data[mutation]) {
      throw new Error('Upload File Mutation failed');
    }
    const data = response.data[mutation];
    if (!done) {
      return trigger(data.id, input, chunks, part + 1);
    }
    return { data: { [mutation]: JSON.parse(data.output) } };
  };

  return async params => {
    const { file, ...other } = params;
    const chunks = chunk(file.body, CHUNK_SIZE);
    other.file = { name: file.name, type: file.type, id: file.id };
    const input = JSON.stringify(other);
    const res = await trigger(null, input, chunks, 0);
    return response(res);
  };
};

export default useFileUpload;

import Okta from 'providers/Okta';
import Logger from 'utilities/logger';

const useReAuthentication = () => {
  const auth = Okta();
  const logger = new Logger('useReAuthentication');

  const attachReAuthenticateAction = callback => {
    const reAuthenticateAction = async (...args) => {
      logger.info('Re-authenticating...');
      try {
        const response = await auth.client.token.getWithPopup({
          prompt: 'login',
          responseType: 'id_token',
          scopes: ['openid', 'email'],
        });
        if (response.state) {
          logger.info('Re-authenticated successfully');
          return callback(...args);
        } else {
          logger.debug('Re-authentication failed', response);
          throw 'Re-authentication failed';
        }
      } catch (error) {
        logger.error('Re-authentication failed');
        logger.debug(error);
        throw 'Re-authentication failed';
      }
    };

    return reAuthenticateAction;
  };

  return attachReAuthenticateAction;
};

export default useReAuthentication;

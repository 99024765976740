import { gql } from '@apollo/client';

export default gql`
  query getAppLinks {
    getAppLinks {
      name
      uri
      asset
    }
  }
`;

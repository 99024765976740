import { gql } from '@apollo/client';

export default gql`
  query getDocumentVerification($id: String!, $idn: String!) {
    getDocumentVerification(id: $id, idn: $idn) {
      match
      status
      attempts
      message
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation submitMinorW4Document(
    $id: String!
    $input: DocusignerDocumentInput!
  ) {
    submitMinorW4Document(id: $id, input: $input)
  }
`;

import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      background: palette.cncUI.default['+7'],
      '& .MuiLinearProgress-bar': {
        background: palette.cncUI.default.primary,
      },
    },
    light: {
      '& .MuiLinearProgress-bar': {
        background: palette.cncUI.default['+2'],
      },
    },
  }),
  { name: 'CncUI-Progress' },
);

const Progress = props => {
  const classes = useStyles();
  const { className, light = false, ...other } = props;
  const options = {
    className: clsx(
      'cncui-progress',
      classes.root,
      light && classes.light,
      className,
    ),
  };
  return <LinearProgress {...options} {...other} variant="determinate" />;
};

export default Progress;

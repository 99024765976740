import useAuthentication from 'hooks/useAuthentication';
import Tokens from 'providers/Tokens';
import Logger from 'utilities/logger';

const useAuthContext = (scope = 'openid') => {
  const logger = Logger('auth context');
  const { requestToken } = useAuthentication();
  let token = null;
  let required = null;

  if (scope === null) {
    return { headers: {} };
  }

  if (Array.isArray(scope)) {
    for (const item of scope) {
      required = item;
      token = Tokens.get(item);
      if (token) {
        break;
      }
    }
  } else {
    required = scope;
    token = Tokens.get(scope);
  }

  if (!token) {
    logger.debug(`Requesting token: ${required}`);
    requestToken(required);
  }

  return { scope: required, headers: { Authorization: `Bearer ${token}` } };
};

export default useAuthContext;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiCardContent from '@mui/material/CardContent';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: 16,
      backgroundColor: 'transparent',
    },
  }),
  { name: 'CncUI-CardContent' },
);

const CardContent = props => {
  const classes = useStyles();
  const { children, className, white, ...other } = props;
  const options = {
    className: clsx(classes.root, white && classes.white, className),
  };
  return (
    <MuiCardContent {...options} {...other}>
      {children}
    </MuiCardContent>
  );
};

export default CardContent;

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import { FormHelperText } from '@mui/material';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      padding: 8,
      background: palette.cncUI.default['+10'],
      color: palette.cncUI.primary.gray['+2'],
      width: 20,
      height: 20,
      margin: 4,
      '& .MuiSvgIcon-root': {
        fontSize: 28,
      },
      '&.Mui-disabled': {
        color: palette.cncUI.primary.gray['+7'],
      },
      '&.Mui-checked': {
        color: palette.cncUI.default.primary,
      },
      '&.Mui-disabled.Mui-checked': {
        color: palette.cncUI.primary.gray['+7'],
        background: palette.cncUI.default['+10'],
      },
    },
    light: {
      '&.Mui-disabled': {
        color: palette.cncUI.primary.gray['+6'],
        background: 'transparent',
      },
      '&.Mui-checked': {
        background: palette.cncUI.default.primary,
        color: palette.cncUI.default['+10'],
      },
      '&.Mui-disabled.Mui-checked': {
        color: palette.cncUI.primary.gray['+6'],
        background: palette.cncUI.primary.gray['+2'],
      },
      '& .MuiCheckbox-root': {
        background: 'transparent',
        color: palette.cncUI.default['+10'],
      },
    },
    label: {
      marginLeft: 0,
      marginTop: 4,
      marginBottom: 4,
      '& .MuiButtonBase-root.Mui-checked + .MuiTypography-root': {
        color: palette.cncUI.default.primary,
      },
      '& .MuiButtonBase-root.Mui-checked + .MuiTypography-root.Mui-disabled': {
        color: palette.cncUI.primary.gray['+7'],
      },
      '& .MuiTypography-root': {
        color: palette.cncUI.primary.gray['+0'],
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 400,
        '&.Mui-disabled': {
          color: palette.cncUI.primary.gray['+7'],
        },
      },
    },
    lightLabel: {
      '& .MuiTypography-root': {
        color: palette.cncUI.default['+10'],
      },
    },
    short: {
      width: 16,
      height: 16,
      margin: '4px 2px',
      '& .MuiSvgIcon-root': {
        fontSize: 22,
      },
    },
    compact: {
      width: 12,
      height: 12,
      margin: '4px 0',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
    shortLabel: {
      '& .MuiTypography-root': {
        fontSize: 14,
        lineHeight: '18px',
        letterSpacing: '0.16px',
      },
      '&.MuiFormControlLabel-labelPlacementTop': {
        '& .MuiCheckbox-root': {
          margin: 2,
        },
      },
      '&.MuiFormControlLabel-labelPlacementBottom': {
        '& .MuiCheckbox-root': {
          margin: 2,
        },
      },
    },
    compactLabel: {
      '& .MuiTypography-root': {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.32px',
      },
      '&.MuiFormControlLabel-labelPlacementTop': {
        '& .MuiCheckbox-root': {
          margin: 0,
        },
      },
      '&.MuiFormControlLabel-labelPlacementBottom': {
        '& .MuiCheckbox-root': {
          margin: 0,
        },
      },
    },
    block: {
      display: 'block',
    },
    dense: {
      padding: '2px 8px',
    },
    error: {
      color: palette.cncUI.alert.red.primary,
    },
  }),
  { name: 'CncUI-Checkbox' },
);

const POSITION = {
  top: 'top',
  right: 'end',
  bottom: 'bottom',
  left: 'start',
};

const Checkbox = props => {
  const classes = useStyles();
  const {
    children,
    className,
    size = 'normal',
    light = false,
    inline = true,
    fullWidth = false,
    short = false,
    compact = false,
    labelPosition = 'right',
    labelProps = {},
    dense = false,
    error = false,
    helperText = '',
    ...other
  } = props;

  const componentSize = short ? 'short' : compact ? 'compact' : size;
  const componentInline = fullWidth ? false : inline;

  const options = {
    variant: 'standard',
    className: clsx(
      'cncui-checkbox',
      classes.root,
      light && classes.light,
      classes[componentSize],
      `cncui-checkbox-${componentSize}`,
      dense && classes.dense,
      className,
    ),
  };

  if (children) {
    return (
      <>
        <FormControlLabel
          labelPlacement={POSITION[labelPosition] || 'end'}
          {...labelProps}
          className={clsx(
            'cncui-checkbox-label',
            classes.label,
            classes[`${componentSize}Label`],
            light && classes.lightLabel,
            !componentInline && classes.block,
            labelProps.className,
          )}
          control={<MuiCheckbox {...options} {...other} />}
          label={children}
        />
        {error ? (
          <FormHelperText className={classes.error}>
            {helperText}
          </FormHelperText>
        ) : null}
      </>
    );
  }

  return <MuiCheckbox {...options} {...other} />;
};

export default Checkbox;

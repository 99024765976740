import { useEffect, useState } from 'react';
import FlagsProvider from 'providers/FeatureFlags';
import Context from './Context';
import Loader from 'components/Loader';
import Logger from 'utilities/logger';
import FastCache from 'utilities/fastCache';

const Provider = ({ children }) => {
  const cache = FastCache('FeatureFlags');
  const cached = cache.get();
  const [loading, setLoading] = useState(!cached);
  const [flags, setFlags] = useState(cached || []);
  const logger = Logger('FEATURE FLAGS');
  const getFlags = (userId = '') => {
    new FlagsProvider()
      .get(userId)
      .then(flags => {
        logger.debug({ userId, flags });
        setFlags(flags);
        cache.set(flags);
        setLoading(false);
      })
      .catch(err => {
        logger.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    logger.debug('Cached Data', { cached });
    getFlags();
  }, []);

  if (loading) {
    return <Loader fullScreen withLogo />;
  }

  const value = {
    isEnabled: name =>
      (flags.find(f => f.name === name) || { value: 'false' }).value === 'true',
    refresh: getFlags,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
